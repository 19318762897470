<template>
	<td :id="id" @mouseover="$emit('hover', $event)" @mouseleave="$emit('leave', $event)" @click="$emit('click', $event)" @touchstart="$emit('touchstart', $event)">
		<div v-for="( app, appIndex ) in appointments" v-if="( workerid <= 0 && groupid <= 0 ) || app.workerid == workerid || $store.getters.getWorker(app.workerid).groupid == groupid" :id="id + 'i' + ( appIndex + 1 )" :class="'appointment' + ( app.affected ? ' affected' : '' )" :data-back="app.color" :data-id="app.id" :data-range="app.range" :style="{ width: workerid > 0 ? app.widthForWorker : app.width, left: workerid > 0 ? app.leftForWorker : app.left, 'min-height': app.height }">
			<div class="appointment-back">
				<div v-for="view in app.views" :class="view.class" :style="view.style"></div>
			</div>
			<div class="appointment-header" :data-target="id + 'i' + ( appIndex + 1 )" :style="'background: ' + app.color">
				<div class="appointment-header-text">
					<span v-if="debug">{{ app.hourIdx }}</span>
					{{ hour }} <i :class="{ desktop: !$store.getters.salonHasUniqueAccount() }" v-if="parseFloat(app.width) >= 100 && parseFloat(app.height) < 2">{{ app.title }}</i>
					<span v-if="debug">{{ app.id }}</span>
				</div>
			</div>
			<div class="appointment-content small" :class="{ desktop: !$store.getters.salonHasUniqueAccount() }" style="line-height: 1.3rem" v-if="parseFloat(app.width) >= 100 && parseFloat(app.height) >= 2">{{ app.title }}</div>
			<div class="appointment-dots"></div>
		</div>
	</td>
</template>



<script>
	export default {
		props: [
			"id",
			"hour",
			"workerid",
			"groupid"
		],
		data() {
			return {
				appointments: [],
				perfCounter: 0,
				debug: false
			}
		},
		methods: {
			setWorkerId( workerid ) {
				this.workerid = workerid
			},
			clearAppointments() {
				if ( this.appointments.length > 0 ) {
					this.appointments = []
				}
			},
			addAppointment( app, perfCounter ) {
				for ( var i = 0; i < this.appointments.length; i++ ) {
					if ( this.appointments[i].id == app.id ) {
					//	console.log( "Appointment already exists ( " + app.id + " ), updating ", app )
						Object.assign( this.appointments[i], app )
						this.perfCounter = perfCounter
						this.$forceUpdate()
						return
					}
				}
				this.appointments.push( app )
				this.perfCounter = perfCounter
			},
			removeAppointment( app ) {
				let idx = this.appointments.findIndex( a => a.id == app.id )
				if ( idx >= 0 ) {
					this.appointments.splice( idx, 1 )
					this.$forceUpdate()
				}
			},
			setAppointments( apps, perfCounter ) {
				this.appointments = apps
				this.perfCounter = perfCounter
			}
		}
	}
</script>
