<template>
	<Modal ref="modal" type="responsive">
		<template v-slot:header>
			<h1 class="title-search">{{ $t("titles.summary") }}</h1>
		</template>
		<template v-slot:content>
			<div class= "block">
			<div class="dateblock">
				<span class="title">{{ $t("common.date") }} :</span>
				<router-link :to="'/appointments?id=' + data.id">{{ $d( new Date(data.date), "long" ) }}</router-link>
			</div>
			<div class="servicesblock">
				<span class="title">{{ $t("common.services") }} :</span>
				<span>{{ data.title }}</span>
			</div>
			<!--div class="workerblock">
				<span class="title">{{ $t("common.worker").substr( 0, 1 ).toUpperCase() + $t("common.worker").substr( 1 ) }} :</span>
				<span>{{ $store.getters.getWorker( data.workers[0] ).name }}</span>
			</div-->
			<div class="userblock">
				<span class="title">{{ $t("common.client").substr( 0, 1 ).toUpperCase() + $t("common.client").substr( 1 ) }} :</span>
				<span v-if="user" class="user">{{ user.fname + " " + user.lname }}</span>
				<span v-else class="anonymous">{{ $tc("common.anonymous") }}</span>
			</div>
			<div class="typeblock" v-if="$store.getters.salonPrestationsHasLength()">
				<span class="title">{{ $t("common.type") }} :</span>
				<span>{{ type }}</span>
			</div>
			<!--div class="typeblock" v-else>
				<span class="title">{{ $t("common.gender") }} :</span>
				<span>{{ gender }}</span>
			</div-->
			<div class="durationblock">
				<span class="title">{{ $t("common.durate") }} :</span>
				<span>{{ duration }}</span>
			</div>
			<div v-if="data.price" class="priceblock">
				<span class="title">{{ $t("common.price") }} :</span>
				<span>{{ data.price }}€</span>
			</div>
			<div v-if="data.message && data.message.length > 0" class="messageblock">
				<span class="title">{{ $t("common.message") }} :</span>
				<span>{{ data.message }}</span>
			</div>
			</div>
		</template>
		<template v-slot:buttons>
			<button id="close" class="btn btn-gray" v-on:click="$emit('close')" type="button">{{$t("common.close")}}</button>
		</template>
	</Modal>
</template>

<script>
	import dayjs from 'dayjs'
	import Modal from '../components/Modal.vue'

	export default {
		components: {
			Modal
		},
		props: [
			"data",
		],
		data() {
			return {
				dayjs,
				user: null,
				clickedHandler: null
			}
		},
		computed: {
			gender() {
				return this.$t('data.genders.' + (this.data.gender || this.data.gender_hair.split('_')[0]))
			},
			type() {
				const gender_hair = (this.data.gender || this.data.gender_hair)
				if ( gender_hair.indexOf( "_" ) > 0 ) {
					const splitted = gender_hair.split( "_" )
					return this.$t( "data.genders." + splitted[0] ) + " - " + this.$t( "data.hairlength." + splitted[1] )
				}
				return this.$t( "data.genders." + gender_hair )
			},
			duration() {
				const minutes = this.data.timeline.reduce( ( a, b ) => a + b, 0 )
				const hours = Math.floor( minutes / 60 )
				if ( hours == 0 ) {
					return minutes + " " + this.$tc( "time.minutes", minutes )
				}
				return hours + " " + this.$tc( "time.hours", hours ) + " " + ( minutes - hours * 60 ) + " " + this.$tc( "time.minutes", minutes )
			}
		},
		methods: {
			clicked( e ) {
				const rect = this.$refs.modal.$refs.modal.getBoundingClientRect()
				if ( e.clientX < rect.left || e.clientX > rect.right || e.clientY < rect.top || e.clientY > rect.bottom ) {
					this.$emit( "close" )
				}
			}
		},
		mounted() {
			this.clickedHandler = () => this.clicked
			window.addEventListener( "click", this.clickedHandler )
			this.$store.getters.getUser( this.data.userid ).then( response => {
				this.user = response.data[0]
			})
		},
		destoryed() {
			window.removeEventListener('click', this.clickedHandler)
		}
	}
</script>

<style scoped lang="scss" src="../css/modals/newappointmentsummary.scss"/>

