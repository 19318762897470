import { render, staticRenderFns } from "./NewAppointment.vue?vue&type=template&id=2e292584&scoped=true"
import script from "./NewAppointment.vue?vue&type=script&lang=js"
export * from "./NewAppointment.vue?vue&type=script&lang=js"
import style0 from "../css/modals/newappointment.scss?vue&type=style&index=0&id=2e292584&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e292584",
  null
  
)

export default component.exports