import { render, staticRenderFns } from "./Questions.vue?vue&type=template&id=19b1faf0&scoped=true"
import script from "./Questions.vue?vue&type=script&lang=js"
export * from "./Questions.vue?vue&type=script&lang=js"
import style0 from "../css/modals/questions.scss?vue&type=style&index=0&id=19b1faf0&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19b1faf0",
  null
  
)

export default component.exports