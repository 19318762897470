<template>
	<nav
		v-if="$store.state.worker"
		id="navbar"
		class="navbar navbar-expand-md fixed-top navbar-align-left"
		:class="{ shadow: shadow && showShadow }"
	>
		<div class="navbar-container">
			<div class="navbar-collapse">
				<ul class="navbar-nav">
					<NavbarItem icon="fa fa-chart-pie" to="/dashboard">{{ $t('titles.dashboard') }}</NavbarItem>
					<NavbarItem icon="fa fa-calendar-check" to="/appointments">{{$t("titles.appoint")}}</NavbarItem>
					<NavbarItem icon="fa fa-cubes" v-if="$store.getters.salonHasStocks()" :disabled="!!isDemo" to="/stocks">{{$t("titles.stocks")}}</NavbarItem>
					<NavbarItem icon="fa fa-calendar-alt" v-else to="/schedules">{{$t("titles.schedule")}}</NavbarItem>
				</ul>
			</div>
		</div>
		<div id="time">
			<span class="t-hours">{{time.hours}}</span> : <span class="t-minutes">{{time.minutes}}</span> : <span class="t-seconds">{{time.seconds}}</span>
		</div>
		<!-- <div id="nav-info">
			<div class="btn-nav-info" onclick="if ( this.getAttribute('class').length > 1 ) { $('#nav-info-box').toggleClass( 'd-block' ); }">
				<i class="btn fas"></i>
			</div>
			<div id="nav-info-box">hello</div>
		</div> -->
		<div id="notif">
			<Popper
				class="dropdown d-flex d-align-items-center"
				trigger="clickToToggle"
				tagName="div"
				:options="{ placement: 'bottom' }"
				:visible-arrow="false"
				@show="$emit('dropdown-open'); shadow=false; setAllNotifsSeen()"
				@hide="$emit('dropdown-close'); shadow=true; patchNotifsSeen(); $refs.notifs.$el.scrollTop = 0"
				ref="notificationDrop"
			>
				<div id="notif-list" class="dropdown-menu">
					<NotificationsList @close="$refs.notificationDrop.doClose()" @updated="updateNotificationsCount" ref="notifs" :infinite="false" :max="25"/>
					<div @click="$refs.notificationDrop.doClose()">
						<router-link class="see-all see" to="/notifications">
							{{$t("common.seeall")}}
						</router-link>
					</div>
				</div>
				<div id="btn-notif" slot="reference">
					<i class="btn btn-secondary fas fa-bell btn-notif"></i>
					<div v-show="notificationsCount > 0" class="count">{{ notificationsCount }}</div>
				</div>
			</Popper>
		</div>
		<!-- <div id="nav-info2">
			<div class="btn-nav-info" onclick="if ( this.getAttribute('class').length > 1 ) { $('#nav-info-box').toggleClass( 'd-block' ) }">
				<i class="btn fas"></i>
			</div>
			<div id="nav-info-box"></div>
		</div> -->
		<router-link tag="h1" class="hairdresser" to="/dashboard"><span class="brand"><img src="../news-img/logo_white.png" class="logo-letter-navbar" alt="image de la lettre m"/></span><span class="letters-brand">ENTOR</span></router-link>
		<UserSearchBar id="card-list" ref="userSearchBar" :clearOnClick="true" v-on:change="$router.push( $event == 'all' ? '/userslist' : ( '/user?id=' + $event.id ) )"/>
		<Popper
			id="user-menu"
			class="dropdown"
			trigger="clickToToggle"
			tagName="div"
			:options="{ placement: 'bottom' }"
			:visible-arrow="false"
			@show="$emit('dropdown-open'); shadow=false"
			@hide="$emit('dropdown-close'); shadow=true"
			ref="navDrop"
		>
			<div class="dropdown-menu" @click="$refs.navDrop.doClose()">
				<div id = "navbarmask"></div>
				<div class="dropdown-choices">
					<NavbarItem class="dropdown-item v-mobile" :side="true" icon2="fa fa-chart-pie" to="/dashboard">{{ $t('titles.dashboard') }}...</NavbarItem>
					<NavbarItem class="dropdown-item v-mobile" :side="true" icon2="fa fa-calendar-check" to="/appointments">{{$t("titles.appoint")}}...</NavbarItem>
					<NavbarItem class="dropdown-item v-mobile" v-if="$store.getters.salonHasStocks()" :side="true" icon2="fas fa-cubes" :disabled="!!isDemo" to="/stocks">{{$t("titles.stocks")}}...</NavbarItem>
					<NavbarItem class="dropdown-item" v-if="$store.state.worker.admin" :side="true" icon2="fas fa-lock" to="/administration" id="navbar-administration-btn">{{$t("titles.admin")}}...</NavbarItem>
					<NavbarItem class="dropdown-item" v-if="$store.state.worker.admin && ['multi'].includes($store.state.salon.subscription)" :side="true" icon2="fa fa-cut" to="/salons">{{$t("titles.hairdressers")}}...</NavbarItem>
					<NavbarItem class="dropdown-item" :class="{ 'v-mobile': !$store.getters.salonHasStocks() }" :side="true" icon2="fas fa-calendar-alt" to="/schedules">{{$t("titles.schedule")}}...</NavbarItem>
					<NavbarItem class="dropdown-item" v-if="$store.state.worker.admin && ['premium','multi'].includes($store.state.salon.subscription)" :side="true" icon2="far fa-chart-bar" :disabled="!!isDemo" to="/stats">{{$t("titles.statistics")}}...</NavbarItem>
					<NavbarItem class="dropdown-item" :side="true" icon2="fas fa-user-friends" :disabled="!!isDemo" to="/userslist">{{$t("titles.customers")}}...</NavbarItem>
					<NavbarItem class="dropdown-item" :side="true" icon2="fas fa-bell" to="/notifications">{{$t("titles.notifs")}}... </NavbarItem>
					<NavbarItem class="dropdown-item" v-if="$store.state.worker.admin/* && ['premium','multi'].includes($store.state.salon.subscription)*/" :side="true" icon2="fas fa-pen-fancy" :disabled="!!isDemo" to="/reviews">{{$t("titles.reviews")}}...</NavbarItem>
					<NavbarItem class="dropdown-item" :side="true" icon2="fas fa-user-cog" to="/settings">{{$t("titles.settings")}}...</NavbarItem>
					<div class="dropdown-separator"></div>
					<NavbarItem class="dropdown-item" :side="true" id="navbar-btn-disconnect" icon2="fas fa-power-off" @click="$emit('dropdown-close'); shadow=false; /*$router.push('/login');*/ $store.dispatch( 'disconnect' )">{{$t("titles.disco")}}</NavbarItem>
				</div>
			</div>
			<button slot="reference"
				id="navbar-user"
				aria-expanded="false"
				aria-haspopup="true"
				class="btn btn-secondary dropdown-toggle"
				data-flip="false"
				data-toggle="dropdown"
				type="button"
			>{{ isDemo ? $t('titles.menu') : ( worker.username === 'general' ? $t('common.generalacc') : worker.fname ) }}</button>
		</Popper>
	</nav>
</template>


<script>
	import dayjs from "dayjs"
	import jwt_decode from "jwt-decode"
	import NotificationsList from "../components/NotificationsList.vue"
	import UserSearchBar from "../components/UserSearchBar.vue"
	import NavbarItem from './NavbarItem.vue'
	import Popper from 'vue-popperjs'

	export default {
		components: {
			Popper,
			NavbarItem,
			NotificationsList,
			UserSearchBar
		},
		data() {
			return {
				shadow: true,
				menuVisible: false,
				time: {
					hours: 0,
					minutes: 0,
					seconds: 0
				},
				notificationsCount: 0
			}
		},
		computed: {
			isDemo() {
				return process.env.VUE_APP_DEMO
			},
			showShadow() {
				return !this.$route.path.startsWith('/appointments') && !this.$route.path.startsWith('/stats')
			},
			worker() {
				return this.$store.state.worker
			}
		},
		methods: {
			timer() {
				var today = new Date()
				this.time.hours = today.getHours()
				this.time.minutes = today.getMinutes()
				this.time.seconds = today.getSeconds()
				if ( this.time.hours < 10 ) {
					this.time.hours = "0" + this.time.hours
				}
				if ( this.time.minutes < 10 ) {
					this.time.minutes = "0" + this.time.minutes
				}
				if ( this.time.seconds < 10 ) {
					this.time.seconds = "0" + this.time.seconds
				}
			},
			/*
			notifWatch( event ) {
				if ( event.method === "POST" ) {
					this.notificationsCount++
				}
			},
			*/
			updateNotificationsCount( notifications ) {
				this.notificationsCount = notifications.filter(n => n.workerid !== this.$store.state.worker.id)
					.filter( n => !n.alreadySeen )
					.length
			},
			initNotifs() {
				/*
				this.$api.notifications.count( { "{or}" : { seen: null, "!seen[any]": this.$store.state.worker.id } } ).then( response => {
					this.notificationsCount = response.data[0].count
				}).catch( error => {
					console.log( error )
				})
				this.$api.notifications.watch( this.notifWatch )
				*/
			},
			setAllNotifsSeen() {
				this.notificationsCount = null
			},
			patchNotifsSeen() {
				console.log( "PATCH" )
				this.$api.notifications.patch( { "seen[||]": [ this.$store.state.worker.id ] }, { "{or}": { "seen": null, "!seen[any]": this.$store.state.worker.id } } ).then( response => {
					this.notificationsCount = null
				}).catch( error => {
					console.log( error )
				})
			}
		},
		mounted() {
			if ( process.env.VUE_APP_DEMO ) {
				let interval = setInterval( () => {
					const endTime = dayjs.unix( jwt_decode( this.$api.headers["X-Access-Token"] ).exp )
					const dif = endTime.diff( dayjs(), "second" )
					this.time.hours = Math.floor( dif / 60 / 60 )
					this.time.minutes = Math.floor( ( dif / 60 ) % 60 )
					this.time.seconds = dif % 60
					if ( this.time.hours <= 0 ) {
						this.time.hours = "00"
					} else if ( this.time.hours < 10 ) {
						this.time.hours = "0" + parseInt(this.time.hours).toString()
					}
					if ( this.time.minutes <= 0 ) {
						this.time.minutes = "00"
					} else if ( this.time.minutes < 10 ) {
						this.time.minutes = "0" + parseInt(this.time.minutes).toString()
					}
					if ( this.time.seconds <= 0 ) {
						this.time.seconds = "00"
					} else if ( this.time.seconds < 10 ) {
						this.time.seconds = "0" + parseInt(this.time.seconds).toString()
					}
					if ( dif <= 0 ) {
					//	clearTimeout( interval )
					}
				}, 1000 )
			} else {
				setInterval( () => this.timer(), 1000 )
			}

			if ( this.$store.state.worker && this.$store.state.worker.id ) {
				this.initNotifs()
			} else {
				this.$store.subscribe( ( mutation, state ) => {
					if ( mutation.type === "setCurrentWorker" ) {
						if ( this.$store.state.worker && this.$store.state.worker.id ) {
							this.initNotifs()
						}
					}
				})
			}
		}
	}
</script>
