<template>
	<div id="notifications-list">
	<!-- TODO : add here a card when update is available ( with $t('data.notifications.update') ) -->
		<template v-for="notification in notifications">
			<div :key="'notif-' + notification.id" @click="$emit('close')" :class="`notifs${notification.alreadySeen ? '' : ' new'}`" v-if="( $t(`data.notifications['${notification.messageid}']`).admin && $store.state.worker.admin ) || !$t(`data.notifications['${notification.messageid}']`).admin">
				<span class="date-notif">{{ $d( new Date(notification.date), "numeric" ) }}</span>
				<i18n :path="`data.notifications['${notification.messageid}'].message`">
					<template v-slot:date>
					</template>
					<template v-if="notification.worker" v-slot:worker>
						<component
							:is="isForeignSalon ? 'span' : 'router-link'"
							v-if="notification.worker.id"
							class="notif-link"
							:to="'/appointments?workerid=' + notification.workerid"
						>
							{{ notification.worker.username == 'general' ? $t('common.generalacc') : notification.worker.name }}
						</component>
						<span v-else><i> [{{ notification.worker.name }}]</i></span>
					</template>
					<template v-slot:user>
						<span v-if="notification.user && !!notification.user.unregistration">[{{ $t('common.accdel') }}]</span>
						<component
							:is="isForeignSalon ? 'span' : 'router-link'"
							v-else-if="notification.user"
							class="notif-link"
							:to="'/user?id=' + notification.userid"
						>
							{{ notification.user.lname + " " + notification.user.fname }}
						</component>
						<span v-else><i>{{ $tc('common.anonymous') }}</i></span>
					</template>
					<template v-slot:action>
						<b>{{ $t(`data.notifications['${notification.messageid}'].action`) }}</b>
					</template>
					<template v-slot:data>
						<component
							:is="isForeignSalon ? 'span' : 'router-link'"
							v-if="notification.endpoint == 'worker'"
							class="notif-link"
							:to="'/appointments?workerid=' + notification.data.id"
						>
							{{ notification.data.fname + " " + ( notification.data.lname || "" ).substr( 0, 1 ).toUpperCase() }}
						</component>
						<template v-else>
							{{ data }}
						</template>
					</template>
					<template v-if="notification.data" v-for="( value, name ) in notification.data" v-slot:[dataName(name)]>
						<template v-if="name == 'date'">
							{{ $d( new Date( value ), "longlong" ) }}
						</template>
						<template v-else-if="name == 'user'">
							<span v-if="value && !!value.unregistration"><i>[{{ $t('common.accdel') }}]</i></span>
							<component
								:is="isForeignSalon ? 'span' : 'router-link'"
								v-else-if="value"
								class="notif-link"
								:to="'/user?id=' + value.id"
							>
								{{ value.lname + " " + value.fname }}
							</component>
							<span v-else><i>{{ $tc('common.anonymous') }}</i></span>
						</template>
						<template v-else-if="notification.endpoint == 'worker' && name == 'name'">
							<span v-if="notification.data.deleted"><i>[{{ $t('common.accdel') }}]</i></span>
							<component
								:is="isForeignSalon ? 'span' : 'router-link'"
								v-else
								class="notif-link"
								:to="'/appointments?workerid=' + notification.entryid"
							>
								{{ notification.data.username == 'general' ? $t('common.generalacc') : value }}
							</component>
						</template>
						<template v-else>
							{{ value }}
						</template>
					</template>
				</i18n>
			</div>
		</template>
		<template v-if="infinite === false && !notifications.length">
			<div class="no-notification">{{ $t('notification.none') }}</div>
		</template>
		<infinite-loading v-if="infinite === undefined || infinite === true" ref="loader" @infinite="infiniteHandler" :identifier="resetId">
			<template v-slot:no-results>{{ $t('infiniteloader.noResult') }}</template>
			<template v-slot:no-more><span /></template>
			<template v-slot:error>{{ $t('infiniteloader.error') }}</template>
		</infinite-loading>
	</div>
</template>

<script>
	import dayjs from "dayjs"
	import InfiniteLoading from "vue-infinite-loading"

	export default {
		components: {
			InfiniteLoading,
		},
		props: {
			full: {
				validator: prop => typeof prop === 'boolean' || prop === undefined
			},
			max: {
				validator: prop => typeof prop === 'number' || prop === undefined
			},
			infinite: {
				validator: prop => typeof prop === 'boolean' || prop === undefined
			},
			typeFilter: { type: Array, default: null },
			workerid: {
				validator: prop => typeof prop === 'number' || prop === undefined || prop === null
			},
			mindate: {
				validator: prop => typeof prop === 'string' || prop === undefined
			},
			maxdate: {
				validator: prop => typeof prop === 'string' || prop === undefined
			},
			salonid: {
				type: Number,
				default: -1,
			}
		},
		data() {
			return {
				currentWorkerId: null,
				notifications: [],
				resetId: 42,
			}
		},
		watch: {
			typeFilter() {
				this.reload()
			},
			workerid() {
				this.reload()
			},
			mindate() {
				this.reload()
			},
			maxdate() {
				this.reload()
			},
			// disabledNotifications() {
			// 	this.reload()
			// },
			salonid() { this.reload() }
		},
		computed: {
			disabledNotifications() {
				return this.$store.state.worker.disabled_notifications
			},
			isForeignSalon() {
				return this.salonid >= 0 && this.salonid != this.$store.state.salon.id
			}
		},
		methods: {
			reload() {
			//	console.log( "NotificationsList reload" )
				this.notifications = []
				if ( this.infinite === false ) {
					this.update()
				} else {
					this.$nextTick( () => {
						this.$refs.loader.stateChanger.reset()
					//	document.dispatchEvent(new CustomEvent('scroll'))
					//	window.scrollTo( window.scrollX, window.scrollY - 1 )
					//	window.scrollTo( window.scrollX, window.scrollY + 1 )
					/*
						this.resetId = Math.random()
						this.$refs.loader.attemptLoad()
					*/
					})
				}
			},
			dataName( name ) {
				return "data." + name
			},
			infiniteHandler( infiniteState ) {
				this.update().then( newEntries => {
					if ( !newEntries.length ) {
						infiniteState.complete()
					} else {
						infiniteState.loaded()
					}
				})
			},
			async loadNotification( notif ) {
				notif.worker = this.$store.getters.getWorker( notif.workerid )
				if ( notif.userid !== null && notif.userid !== undefined ) {
				//	await this.$store.getters.getOnlineUser( notif.userid ).then( resp => {
					await this.$store.getters.getUser( notif.userid ).then( resp => {
						notif.user = resp.data[0]
					}).catch( error => console.log( error ) )
				}
			//	notif.alreadySeen = ( notif.seen && notif.seen.indexOf(this.currentWorkerId || this.$store.state.worker.id) >= 0 )
				notif.alreadySeen = ( notif.seen && notif.seen.find( s => s == (this.currentWorkerId || this.$store.state.worker.id) ) )
				if ( notif.endpoint && notif.entryid ) {
					if ( notif.endpoint.slice( -1 ) === "s" ) {
						notif.endpoint = notif.endpoint.match( /(.+)s/ )[1]
					}
					if ( notif.endpoint === "worker" ) {
						notif.data = this.$store.getters.getWorker( notif.entryid )
					} else if ( notif.endpoint === "user" ) {
						notif.data = this.$store.getters.getUser( notif.entryid ) // TBD : getOnlineUser ?
					} else {
						console.log( "notif.endpoint", notif.endpoint )
						if ( this.$api[notif.endpoint] ) {
							await this.$api[notif.endpoint].get( notif.entryid ).then( resp => {
								notif.data = resp.data[0]
							}).catch( error => console.log( error ) )
						}
					}
					if ( notif.data ) {
						if ( notif.data.userid || notif.endpoint == "salonsuser" ) {
							const uid = ( notif.endpoint == "salonsuser" ) ? notif.data.id : notif.data.userid
						//	const getter = ( notif.endpoint == "review" ? this.$store.getters.getOnlineUser : this.$store.getters.getUser )
						//	await getter( uid ).then( resp => {
							await this.$store.getters.getUser( uid ).then( resp => {
								notif.data.user = resp.data[0]
							}).catch( error => console.log( error ) )
						}
						const forcedField = [ "user", "date" ]
						forcedField.forEach( field => {
							notif.data[field] = notif.data[field] || null
						})
						if ( notif.data.lname || notif.data.fname ) {
							notif.data.name = ( notif.data.lname || "" ) + " " + ( notif.data.fname || "" )
						}
					}
					notif.data = notif.data || {}
					if ( !notif.data.user ) {
						notif.data.user = notif.user
					}
				}
			},
			async update() {
				if ( Object.keys(this.$store.state.worker).length == 0 ) {
					return
				}
				var params = new URLSearchParams()
				if ( !this.mindate || !this.maxdate ) params.append( "{max}", this.max || 20 )
				params.append( "{sort}", "desc:id" )
				if (this.salonid >= 0) params.append('salonid', this.salonid)
				if ( this.notifications.length > 0 ) params.append( "id[lt]", this.notifications[this.notifications.length - 1].id )
				if ( !isNaN(this.workerid) && this.workerid > 0 ) params.append( "workerid", this.workerid )
				if ( this.mindate && this.mindate.length > 0 ) params.append( "date[gte]", this.mindate )
				if ( this.maxdate && this.maxdate.length > 0 ) params.append( "date[lte]", this.maxdate )
				let disabled = ( this.full ? [] : ( this.disabledNotifications || [] ) )
				if ( !this.$store.state.worker.admin ) {
					disabled.push( ...Object.entries(this.$t("data.notifications")).filter( e => e[1].admin ).map( e => e[0] ) )
				}
				params.append( "!messageid[in]", JSON.stringify(disabled) )
				if (this.typeFilter)
					params.append( "messageid[in]", JSON.stringify(this.typeFilter) )
				const response = await this.$api.notifications.get( params ).catch(e => console.error(e))

				let notifications = response ? response.data : []
				await Promise.all( notifications.map( this.loadNotification ) )
	
				notifications = notifications.filter( notif => {
					let not = this.$t(`data.notifications['${notif.messageid}']`)
					if ( not.self && notif.data[not.selfid] !== this.$store.state.worker.id ) {
						return false
					}
					return true
				})

				this.notifications = this.notifications.filter(n => !notifications.find(nn => nn.id == n.id)).concat(notifications)
				this.$emit( "updated", this.notifications )

				return notifications
			},
			async notifWatch( event ) {
				if ( event.method === "POST" ) {
					let notifs = event.data.slice()
					// TODO : filter-out own-worker notifications (e.g. don't show worker's notifications)
				//	console.log( "NotificationsList notifWatch POST", this.$store.state.worker.id, this.currentWorkerId )
					await Promise.all( notifs.map( this.loadNotification ) )
					notifs = notifs.filter( notif => {
						let not = this.$t(`data.notifications['${notif.messageid}']`)
						if ( not.self && notif.data[not.selfid] !== this.$store.state.worker.id ) {
							return false
						}
						return true
					})
					this.notifications.unshift( ...notifs )
					if ( this.max && this.notifications.length > this.max ) {
						this.notifications.splice( this.max - 1, this.notifications.length - this.max )
					}
					this.$emit( "updated", this.notifications )
					this.$forceUpdate()
				} else if ( event.method === "PATCH" && typeof event.data === "object" && event.data && event.data[0] && event.data[0]["seen"] ) {
					// Only 'seen' field can be patched
					this.notifications.forEach( notif => {
						notif.seen = notif.seen || []
					//	notif.seen.push( ...event.data["seen[||]"].map( id => parseInt(id) ) )
						notif.seen.push( ...event.data[0]["seen"].map( id => parseInt(id) ) )
						notif.seen = notif.seen.filter( (id, i) => notif.seen.indexOf(id) == i )
					//	notif.alreadySeen = ( notif.seen && notif.seen.indexOf(this.currentWorkerId || this.$store.state.worker.id) >= 0 )
						notif.alreadySeen = ( notif.seen && notif.seen.find( s => s == (this.currentWorkerId || this.$store.state.worker.id) ) )
					})
					this.$emit( "updated", this.notifications )
					this.$forceUpdate()
				}// TBD : else ? There should not be any reason to DELETE a notification
			}
		},
		mounted() {
			if ( this.infinite === false ) {
				this.update()
			} else {
				this.$nextTick( () => {
				//	this.$refs.loader.stateChanger.reset()
					window.scrollTo( window.scrollX, window.scrollY - 1 )
					window.scrollTo( window.scrollX, window.scrollY + 1 )
				})
			}
			this.$api.notifications.watch( this.notifWatch )
			this.$store.subscribe( ( mutation, state ) => {
				if ( ( mutation.type == "setCurrentWorker"/* || mutation.type == "updateWorker"*/ ) && !isNaN(parseInt(mutation.payload.id)) && mutation.payload.id != this.currentWorkerId /*&& mutation.payload.id == this.$store.state.worker.id */) {
				//	console.log( "NotificationsList worker changed", mutation.type, mutation.payload )
					this.currentWorkerId = mutation.payload.id
					this.reload()
				}
			})

			this.$api.workers.watch(event => {
				if (event.method !== 'PATCH' || event.data[0].id != this.$store.state.worker.id) return
				console.log('licorne')
				this.reload()
			})
		}
	}
</script>
