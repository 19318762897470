let FirebasePlugin = null
let currentToken = null


function checkNotificationPermission() {
	return new Promise( ( resolve, failed ) => {
		FirebasePlugin.hasPermission( hasPermission => {
			if ( hasPermission ) {
				resolve()
			} else {
				FirebasePlugin.grantPermission( function( hasPermission ) {
					console.log( "Permission was " + ( hasPermission ? "granted" : "denied" ) )
					if ( hasPermission == "granted" ) {
						resolve()
					} else {
						failed()
					}
				})
			}
		})
	})
}


function getToken() {
	return new Promise( ( resolve, failed ) => {
		FirebasePlugin.getToken( function( tok ) {
			if ( tok ) {
				resolve( tok )
			} else {
				failed()
			}
		})
	})
}


function sendTokenToAPI( api, type, userid, token ) {
	console.log( "firebase sendTokenToAPI", api, type, userid, token )
	api[type].patch( userid, { "fcm[||]": [ token ] } )
}


export default {
	async init( api, userid, type ) {
		type = type || "user"
		if ( type != "user" && type != "worker" ) {
			throw "invalid client type"
		}
		try {
			console.log( "firebase init" )
			FirebasePlugin = window.FirebasePlugin
			if ( !FirebasePlugin ) {
				return
			}
			console.log( "firebase FirebasePlugin", FirebasePlugin )
			await checkNotificationPermission()
			currentToken = await getToken()
			console.log( "firebase currentToken", currentToken )
			if ( currentToken ) {
				sendTokenToAPI( api, type, userid, currentToken )
			}
			FirebasePlugin.onTokenRefresh( fcmToken => {
				currentToken = fcmToken
				if ( currentToken ) {
					sendTokenToAPI( api, type, userid, currentToken )
				}
			}, function( error ) {
				throw( error )
			})
		} catch ( error ) {
			console.error( "Firebase error", error )
			setTokenSentToServer(false)
		}
	},
	async loginWithGoogle() {
		FirebasePlugin = window.FirebasePlugin
		if ( !FirebasePlugin ) {
			return
		}
		if ( !currentToken ) {
			currentToken = await getToken()
		}
		return new Promise( ( resolve, failed ) => {
			FirebasePlugin.authenticateUserWithGoogle( "187740152265-nuirtfhfqdfhaj5pies3hhqfk3gbtlbh.apps.googleusercontent.com", credential => {
				FirebasePlugin.signInWithCredential( credential, data => {
					FirebasePlugin.reloadCurrentUser( user => {
						resolve( user )
					}, error => {
						failed( error )
					})
				}, error => {
					failed( error )
				})
			}, error => {
				failed( error )
			})
		})
	},
	async loginWithApple() {
		FirebasePlugin = window.FirebasePlugin
		if ( !FirebasePlugin ) {
			return
		}
		if ( !currentToken ) {
			currentToken = await getToken()
		}
		return new Promise( ( resolve, failed ) => {
			FirebasePlugin.authenticateUserWithApple(function(credential) {
				FirebasePlugin.signInWithCredential( credential, data => {
					FirebasePlugin.reloadCurrentUser( user => {
						alert( "currentUser : " + JSON.stringify(user) )
						resolve( user )
						/*
						FirebasePlugin.getCurrentUser( user => {
							alert( "currentUser : " + JSON.stringify(user) )
							resolve( user )
						}, error => {
							failed( error )
						})
						*/
					}, error => {
						failed( error )
					})
				}, error => {
					failed( error )
				})
			}, function(error) {
				console.error("Failed to authenticate with Apple: " + error);
				failed( error )
			}, 'fr_FR')
		})
	}
}
