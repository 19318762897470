import Vue from "vue"
import VueRouter from "vue-router"
import Vuex from "vuex"
import VueI18n from "vue-i18n"
import VueScrollTo from "vue-scrollto"
import VueCookies from "vue-cookies"
import Vueditor from 'vueditor'
import 'vueditor/dist/style/vueditor.min.css'

import dayjsLocaleFR from "dayjs/locale/fr"
// import "dayjs/locale/en"
import dayjs from "dayjs"
import duration from "dayjs/plugin/duration"
import relativeTime from "dayjs/plugin/relativeTime"
dayjs.extend(duration)
dayjs.extend(relativeTime)

import App from "./App.vue"
import Calendar from './components/Calendar.vue'
import ValidationInput from './components/ValidationInput.vue'

Vue.use( VueI18n )
Vue.use( Vuex )
Vue.use( VueRouter )
Vue.use( VueScrollTo )
Vue.use( VueCookies )
Vue.use( Vueditor, {
	toolbar: [
		'removeFormat', 'undo', 'redo', '|', 'fontSize', 'foreColor', 'backColor', '|', 'bold', 'italic', 'underline', 'strikeThrough', '|', 'justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull', '|', 'indent', 'outdent',
    'insertOrderedList', 'insertUnorderedList', '|', 'link'
	],
	lang: {
		app: {},
		removeFormat: {title: 'Supprimer le formatage'},
		bold: {title: 'Gras'},
		italic: {title: 'Italique'},
		underline: {title: 'Souligné'},
		strikeThrough: {title: 'Barré'},
		indent: {title: 'Augmenter le retrait'},
		outdent: {title: 'Diminuer le retrait'},
		justifyLeft: {title: 'Aligner à gauche'},
		justifyCenter: {title: 'Aligner au centre'},
		justifyRight: {title: 'Aligner à droite'},
		justifyFull: {title: 'Justifier'},
		insertOrderedList: {title: 'Insérer liste ordonnée'},
		insertUnorderedList: {title: 'Insérer liste'},
		foreColor: {
			title: 'Couleur de texte',
			ok: 'Ok',
			colorCode: 'Code couleur',
			invalidColorCodeMsg: "Merci d'entrer un code couleur valide",
		},
		backColor: {
			title: 'Couleur de fond',
			ok: 'Ok',
			colorCode: 'Code couleur',
			invalidColorCodeMsg: "Merci d'entrer un code couleur valide",
		},
		fontName: {},
		fontSize: {},
		code: {},
		element: {},
		design: {
			ieMsg: 'You must select a text to use this feature in IE browser'
		},
		link: {
			title: 'Ajouter un lien',
			ok: 'Ok'
		},
		unLink: {
			title: 'Supprimer le lien'
		},
		undo: {title: 'Annuler'},
		redo: {title: 'Refaire'}
	}
} )
Vue.component( "Calendar", Calendar )
Vue.component( "ValidationInput", ValidationInput )

require( "./utils" )

var app = null

function apiErrorHandler( error ) {
	var msg = ""
	if ( error.response ) {
		if ( typeof error.response.data == "object" ) {
			msg = error.response.data.error || error.response.data
		} else {
			msg = error.response.data
		}
		if ( error.response.status == 401 || error.response.status == 498 ) {
		//	app.$router.push( "/login" )
			app.$nextTick( () => {
				app.$store.dispatch( 'disconnect' )
			})
		}
	} else if ( error.request ) {
		if ( error.code == "ECONNABORTED" ) {
			console.log( "setting response" )
			error.response = {
				data: {
					error: "errors.timeout"
				}
			}
		} else {
			msg = app.showError( app.$t( "common.networkerror" ) )
		}
	} else {
		msg = error.message
	}
	console.log( msg )
}

import apiClient from "./apiClient"
import firebase from './firebase'
import storeBuilder from "./store"

console.log( process.env )
const api = apiClient( process.env.VUE_APP_API_ROOT, apiErrorHandler, firebase )
Vue.prototype.$api = api


const store = storeBuilder( Vue.prototype.$api )
Vue.use( store )
Vue.prototype.$firebase = firebase


import translations from "./i18n"
const dateTimeFormats = {
	en: {
		year: {
			year: 'numeric'
		},
		monthyear: {
			year: 'numeric', month: 'long'
		},
		numeric: {
			year: 'numeric', month: 'numeric', day: 'numeric',
			hour: 'numeric', minute: 'numeric'
		},
		numericonlydate: {
			year: 'numeric', month: 'numeric', day: 'numeric'
		},
		short: {
			year: 'numeric', month: 'numeric', day: 'numeric',
			weekday: 'short', hour: 'numeric', minute: 'numeric'
		},
		long: {
			year: 'numeric', month: 'short', day: 'numeric',
			weekday: 'short', hour: 'numeric', minute: 'numeric'
		},
		longonlydate: {
			year: 'numeric', month: 'long', day: 'numeric',
			weekday: 'long'
		},
		longlong: {
			year: 'numeric', month: 'long', day: 'numeric',
			weekday: 'long', hour: 'numeric', minute: 'numeric'
		},
	}
}

// let locale = ( navigator.language || navigator.userLanguage || "en" ).toLowerCase().substr( 0, 2 )
let locale = 'fr'
dayjs.locale(dayjsLocaleFR) // TODO : change this

const i18n = new VueI18n( {
	locale: locale,
	fallbackLocale: "fr",
	messages: translations(),
	dateTimeFormats: {
		...dateTimeFormats,
		fr: dateTimeFormats.en
	},
	silentTranslationWarn: true
} )

i18n.translations = translations



const Login = () => import(/* webpackChunkName: "login" */ "./pages/Login.vue")
const Index = () => import(/* webpackChunkName: "index" */ "./pages/Index.vue")
const Appointments = () => import(/* webpackChunkName: "appointments" */ "./pages/Appointments.vue")
const Administration = () => import(/* webpackChunkName: "administration" */ "./pages/Administration.vue")
const AdminServices = () => import(/* webpackChunkName: "adminServices" */ "./pages/admin/Services.vue")
const AdminSorting = () => import(/* webpackChunkName: "adminSorting" */ "./pages/admin/Sorting.vue")
const AdminFormulas = () => import(/* webpackChunkName: "adminFormulas" */ "./pages/admin/Pack.vue")
const AdminTechnic = () => import(/* webpackChunkName: "adminTechnic" */ "./pages/admin/Technic.vue")
const AdminStaff = () => import(/* webpackChunkName: "adminStaff" */ "./pages/admin/Staff.vue")
const AdminVacations = () => import(/* webpackChunkName: "adminVacations" */ "./pages/admin/Vacations.vue")
const AdminMessages = () => import(/* webpackChunkName: "adminMessages" */ "./pages/admin/Messages.vue")
const AdminBills = () => import(/* webpackChunkName: "adminWebsite" */ "./pages/admin/Bills.vue")
const AdminWebsite = () => import(/* webpackChunkName: "adminWebsite" */ "./pages/admin/Website.vue")
const Stats = () => import(/* webpackChunkName: "stats" */ "./pages/Stats.vue")
const User = () => import(/* webpackChunkName: "user" */ "./pages/User.vue")
const Userslist = () => import(/* webpackChunkName: "userslist" */ "./pages/Userslist.vue")
const Notifications = () => import(/* webpackChunkName: "notifications" */ "./pages/Notifications.vue")
const Reviews = () => import(/* webpackChunkName: "reviews" */ "./pages/Reviews.vue")
const Settings = () => import(/* webpackChunkName: "settings" */ "./pages/Settings.vue")
const Salons = () => import(/* webpackChunkName: "salons" */ "./pages/Salons.vue")
const Schedules = () => import(/* webpackChunkName: "schedules" */ "./pages/Schedules.vue")
const Stocks = () => import(/* webpackChunkName: "stocks" */ "./pages/Stocks.vue")
const Bill = () => import(/* webpackChunkName: "stocks" */ "./pages/Bill.vue")
const NotFound = () => import(/* webpackChunkName: "NotFound" */ "./pages/404.vue")


const router = new VueRouter( {
	routes : [
		{ path: "/login", meta: { title: "login" }, component: Login },
		{ path: "/dashboard", meta: { title: "dashboard" }, component: Index },
		{ path: "/appointments", meta: { title: "appoint" }, component: Appointments },
		{ path: "/administration", meta: { title: "admin", admin: true }, component: Administration, children: [
			{ path: "/administration/services", name: "services", meta: { title: "admin", admin: true }, component: AdminServices },
			{ path: "/administration/sorting", name: "sorting", meta: { title: "admin", admin: true }, component: AdminSorting },
			{ path: "/administration/formulas", name: "formulas", meta: { title: "admin", admin: true }, component: AdminFormulas },
			{ path: "/administration/technic", name: "technic", meta: { title: "admin", admin: true }, component: AdminTechnic },
			{ path: "/administration/staff", name: "staff", meta: { title: "admin", admin: true }, component: AdminStaff },
			{ path: "/administration/vacations", name: "vacations", meta: { title: "admin", admin: true }, component: AdminVacations },
			{ path: "/administration/messages", name: "messages", meta: { title: "admin", admin: true }, component: AdminMessages },
			{ path: "/administration/bills", name: "bills", meta: { title: "admin", admin: true }, component: AdminBills },
			{ path: "/administration/website", name: "website", meta: { title: "admin", admin: true }, component: AdminWebsite },
		]},
		{ path: "/stats", meta: { title: "statistics", admin: true, subscriptions: [ "premium", "multi" ] }, component: Stats },
		{ path: "/user", meta: { title: "customer" }, component: User },
		{ path: "/userslist", meta: { title: "customers" }, component: Userslist },
		{ path: "/notifications", meta: { title: "notifs" }, component: Notifications },
		{ path: "/reviews", meta: { title: "reviews", admin: true/*, subscriptions: [ "premium", "multi" ]*/ }, component: Reviews },
		{ path: "/settings", meta: { title: "settings" }, component: Settings },
		{ path: "/salons", meta: { title: "hairdressers", admin: true, subscriptions: [ "multi" ] }, component: Salons },
		{ path: "/schedules", meta: { title: "schedule" }, component: Schedules },
		{ path: "/stocks", meta: { title: "stocks", subscriptions: [ "premium", "multi" ] }, component: Stocks },
		{ path: "/bill", name: "bill", meta: { title: "bill", printable: true }, component: Bill },
		{ path: "*", meta: { title: "notfound" }, component: NotFound }
	]
} )


let deviceReady = false

router.beforeEach( ( to, from, next ) => {
	let localNext = function () {
		if ( app && ( !to.matched[0] || !to.matched[0].instances.default ) ) {
			app.progressBarColor( "var( --color-quaternary )" )
			app.progressBarStart()
		}
		if ( to.path === "/" ) {
			router.replace( "/dashboard" )
		}

		if ( ( !store.store.state.worker || Object.keys(store.store.state.worker).length == 0 ) && !window.localStorage.getItem( "token" ) ) {
			store.store.state.initialized = false
			console.log( "No worker, going to /login" )
			if ( to.path == "/login" ) {
				next()
			} else {
				next( "/login" )
			}
		} else if ( !store.store.state.initialized ) {
			store.store.commit( "setInitialized", true )
			store.store.dispatch( "reload" ).then( () => {
				if ( ( to.meta.admin && !store.store.state.worker.admin ) || ( to.meta.subscriptions && !to.meta.subscriptions.includes( store.store.state.salon.subscription ) ) ) {
					next( "*" )
				} else {
					next()
				}
			})
		} else {
			if ( to.path.indexOf("/worker") == 0 ) {
				next( "/appointments?workerid=" + ( to.query.id || parseInt( to.path.substr( 8 ) ) ) )
			}
			if ( ( to.meta.admin && !store.store.state.worker.admin ) || ( to.meta.subscriptions && !to.meta.subscriptions.includes( store.store.state.salon.subscription ) ) ) {
				next( "*" )
			} else {
				next()
			}
		}
	}

	if (deviceReady || typeof cordova === 'undefined') {
		localNext()
	} else {
		let onDeviceReady = function() {
			deviceReady = true
			localNext()
		}
		document.addEventListener('deviceready', onDeviceReady, false)
	}
} )


router.afterEach( ( to, from ) => {
	document.querySelector( "title" ).textContent = app.$t( "titles.appname" ) + " - " + app.$t( "titles." + to.meta.title )

	if ( to.path == "/login" ) {
		document.body.className = "login " + store.store.state.domain
		Vue.nextTick( () => {
			app.progressBarDone()
		})
	} else {
		document.body.className = ""
	}
	if ( to.path == "/administration" || to.path == "/administration/" ) {
		Vue.nextTick( () => {
			router.replace( "/administration/services" )
		} )
	}
	if ( to.hash && to.hash.length > 0 ) {
		app.$nextTick( () => {
			app.$scrollTo( document.getElementById( to.hash.substr( 1 ) ), 250 )
		})
	} else {
		app.$scrollTo( document, 250 )
	}
} )

let lastDomain = window.localStorage.getItem( "domain" )
let lastSalonId = window.localStorage.getItem( "salonid" )
let lastWorkerId = window.localStorage.getItem( "workerid" )
let lastToken = window.localStorage.getItem( "token" )

const tok = ( new URLSearchParams( window.location.search ) ).get( "token" )
const dom = ( new URLSearchParams( window.location.search ) ).get( "domain" )
const sid = ( new URLSearchParams( window.location.search ) ).get( "salonid" )
const wid = ( new URLSearchParams( window.location.search ) ).get( "workerid" )
if ( tok && dom && sid && wid ) {
	lastToken = tok
	lastDomain = dom
	lastSalonId = sid
	lastWorkerId = wid
	window.history.pushState( null, null, window.location.pathname )
}

if ( lastSalonId && lastDomain && lastWorkerId && lastToken && lastToken !== "null" && lastToken !== "undefined" ) {
	Vue.prototype.$api.setCredentials( lastWorkerId, lastToken, lastDomain, lastSalonId )
	store.store.domain = lastDomain
} else if ( process.env.VUE_APP_MODE === "development" ) {
	console.log( "set Dev creds" )
// 	Vue.prototype.$api.setCredentials( API_WORKERID, API_TOKEN, API_SALONID )
	Vue.prototype.$api.setCredentials( null, null, null, process.env.VUE_APP_API_SALONID )
}

console.log( "STOre", store )


const Class = Vue.extend( App )
app = new Class( { el: "#app", i18n, router } )
Vue.prototype.$_i18n = i18n
Vue.prototype.$app = app
Vue.prototype.$dayjs = dayjs
store.store.$app = app

let scrollStack = 0

Vue.prototype.$preventScrolling = function() {
	if ( scrollStack === 0 ) {
		app.setPreventScrolling( true )
		document.documentElement.style.top = -( document.documentElement.scrollTop ) + "px"
		document.documentElement.classList.add( "noscroll" )
	}
	scrollStack++
}

Vue.prototype.$allowScrolling = function() {
	if ( scrollStack > 0 ) {
		scrollStack--
		if ( scrollStack === 0 ) {
			var scrollTop = parseInt( document.documentElement.style.top )
			document.documentElement.classList.remove( "noscroll" )
			app.setPreventScrolling( false )
			document.documentElement.scrollTop = -scrollTop
			document.getElementsByTagName( "body" )[0].scrollTop = -scrollTop
		}
	}
}
