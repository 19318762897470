<template>
	<Modal name="confirm" type="small">
		<template v-slot:header>
			<h1 class="title-search">{{ title || $t("modals.confirm.title") }}</h1>
		</template>
		<template v-slot:content>
			<div>
				<slot/>
			</div>
		</template>
		<template v-slot:buttons>
			<button ref="yes" id="yes" class="btn btn-primary" v-on:click="$allowScrolling(); $emit('confirm')" type="button">{{$t("common.yes")}}</button>
			<button ref="cancel" id="cancel" class="btn btn-gray" v-on:click="$allowScrolling(); $emit('close')" type="button">{{$t("common.cancel")}}</button>
		</template>
	</Modal>
</template>

<script>
	import Modal from '../components/Modal.vue'

	export default {
		components: {
			Modal
		},
		props: [
			"title"
		],
		data() {
			return {
				pack: {}
			}
		}
	}
</script>

<style scoped lang="scss" src="../css/modals/confirm.scss"/>
