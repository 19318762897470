<template>
	<Modal class="modal-quest" name="confirm">
		<template v-slot:header>
			<h1 class="title-search">{{ $t("modals.questions.title") }}</h1>
		</template>
		<template v-slot:content>
			<div class="row list-questions">
				<div class="col-md-9 questions"> 
					<div class="question-general">{{$t("common.general")}}</div>
					<div
						v-for="question in shownQ"
						:key="question.id"
						class="question-block"
						:class="{ active: activeQ[question.question] }"
					>
						<div @click="activeQ[question.question] = !activeQ[question.question]">
							<vue-markdown
								:html="false"
								class="question"
							>{{ question.question }}</vue-markdown>
						</div>
						<div class="response">
							<vue-markdown :html="false">{{ question.response }}</vue-markdown>
							<ol class="tips" v-if="$store.state.worker.admin && question.tips">
								<li class="list-tips" v-for="tip in question.tips">
									<vue-markdown class="line" :html="false">{{ tip }}</vue-markdown>
								</li>
							</ol>
						</div>
					</div>
					<div v-if="!shownQ.length" class="no-result">
						{{ $t('common.noresult') }}
					</div>
					<template v-if="$store.state.worker.admin">
						<div class="question-general admin">{{$t("titles.admin")}}</div>
						<div
							v-for="question in shownQAdmin"
							:key="question.id + '-admin'"
							class="question-block"
							:class="{ active: activeQ[question.question] }"
						>
							<div @click="activeQ[question.question] = !activeQ[question.question]">
								<vue-markdown
									:html="false"
									class="question"
								>{{ question.question }}</vue-markdown>
							</div>
							<div class="response">
								<vue-markdown :html="false" class="response">{{ question.response }}</vue-markdown>
								<ol class="tips" v-if="question.tips">
									<li class="list-tips" v-for="tip in question.tips">
										<vue-markdown class="line" :html="false">{{ tip }}</vue-markdown>
									</li>
								</ol>
								<vue-markdown :html="false" class="response">{{ question.response2 }}</vue-markdown>
								<ol class="tips" v-if="question.tips">
									<li class="list-tips" v-for="tip in question.tips2">
										<vue-markdown class="line" :html="false">{{ tip }}</vue-markdown>
									</li>
								</ol>
							</div>
						</div>
						<div v-if="!shownQAdmin.length" class="no-result">
							{{ $t('common.noresult') }}
						</div>
					</template>
				</div>
				<div class="col-md-3 legend">
					<!--<div class="title-leg">Actions</div>-->
					<div class="block-legend">
						<div class="question-general title-leg">{{ $t("common.legend") }}</div>
						<div class="legend-list legend-list-one">
							<span class="leg1 leg2 fas fa-plus">
								<span class="text-italic"> <i>Créer / Ajouter</i></span>
							</span>
							<span class="leg1 leg2 fas fa-edit"> 
								<span class="text-italic"> <i>Éditer / Modifier</i></span>
							</span>
							<span class="leg1 leg2 fas fa-trash">
								<span class="text-italic"> <i>Supprimer</i></span>
							</span>
							<span class="leg1 leg2 fas fa-times">
								<span class="text-italic"> <i>Retirer</i></span>
							</span>
							<span class="leg1 leg2 fas fa-toggle-on">
								<span class="text-italic"> <i>Toggle</i></span>
							</span>
							<span class="leg1 leg2 fas fa-sliders-h">
								<span class="text-italic"> <i>Slider</i></span>
							</span>
							<span class="leg1 leg2 fas fa-folder">
								<span class="text-italic"> <i>Ficher</i></span>
							</span>
							<span class="leg1 leg2 fas fa-print">
								<span class="text-italic"> <i>Impression</i></span>
							</span>
													
							<!-- <div class="question-general page-section">Pages</div>
							<span class="leg fas fa-chart-pie"> 
								<span class="text-legend"> Dashboard</span>
							</span>
							<span class="leg fas fa-calendar-check"> 
								<span class="text-legend"> Rendez-vous</span>
							</span>
							<span class="leg fas fa-calendar-alt"> 
								<span class="text-legend"> Schedules</span>
							</span>
							<span class="leg fas fa-cubes"> 
								<span class="text-legend"> Stocks</span>
							</span>
							<span class="leg fas fa-user-friends"> 
								<span class="text-legend"> Clientèle</span>
							</span>
							<span class="leg fas fa-bell"> 
								<span class="text-legend"> Notifications</span>
							</span>
							<span class="leg fas fa-user-cog"> 
								<span class="text-legend"> Réglages</span>
							</span>
							<span class="leg fas fa-power-off"> 
								<span class="text-legend"> Déconnexion</span>
							</span>
							<div class="question-general page-section">Pages Admin</div> <span class="leg fas fa-cut"> 
								<span class="text-legend"> Salons</span>
							</span>
							<span class="leg fas fa-lock"> 
								<span class="text-legend"> Administration</span>
							</span>
							<span class="leg fas fa-chart-bar"> 
								<span class="text-legend"> Statistiques</span>
							</span>
							<span class="leg fas fa-pen-fancy"> 
								<span class="text-legend"> Avis</span>
							</span> -->
						</div>
						<div class="legend-list legend-list-two">
							<div class="legend-group">
								<span class="leg1 fas fa-plus">
									<span class="text-italic"> <i>Créer / Ajouter</i></span>
								</span>
								<span class="leg1 fas fa-toggle-on">
									<span class="text-italic"> <i>Toggle</i></span>
								</span>
							</div>
							<div class="legend-group">
								<span class="leg1 fas fa-trash">
									<span class="text-italic"> <i>Supprimer</i></span>
								</span>
								<span class="leg1 fas fa-folder">
									<span class="text-italic"> <i>Ficher</i></span>
								</span>
							</div>
							<div class="legend-group">
								<span class="leg1 fas fa-edit"> 
									<span class="text-italic"> <i>Éditer / Modifier</i></span>
								</span>
								<span class="leg1 fas fa-sliders-h">
									<span class="text-italic"> <i>Slider</i></span>
								</span>
							</div>
							<div class="legend-group">
								<span class="leg1 fas fa-times">
									<span class="text-italic"> <i>Retirer</i></span>
								</span>
								<span class="leg1 fas fa-print">
									<span class="text-italic"> <i>Impression</i></span>
								</span>
							</div>
													
							<!-- <div class="question-general page-section">Pages</div>
							<span class="leg fas fa-chart-pie"> 
								<span class="text-legend"> Dashboard</span>
							</span>
							<span class="leg fas fa-calendar-check"> 
								<span class="text-legend"> Rendez-vous</span>
							</span>
							<span class="leg fas fa-calendar-alt"> 
								<span class="text-legend"> Schedules</span>
							</span>
							<span class="leg fas fa-cubes"> 
								<span class="text-legend"> Stocks</span>
							</span>
							<span class="leg fas fa-user-friends"> 
								<span class="text-legend"> Clientèle</span>
							</span>
							<span class="leg fas fa-bell"> 
								<span class="text-legend"> Notifications</span>
							</span>
							<span class="leg fas fa-user-cog"> 
								<span class="text-legend"> Réglages</span>
							</span>
							<span class="leg fas fa-power-off"> 
								<span class="text-legend"> Déconnexion</span>
							</span>
							<div class="question-general page-section">Pages Admin</div> <span class="leg fas fa-cut"> 
								<span class="text-legend"> Salons</span>
							</span>
							<span class="leg fas fa-lock"> 
								<span class="text-legend"> Administration</span>
							</span>
							<span class="leg fas fa-chart-bar"> 
								<span class="text-legend"> Statistiques</span>
							</span>
							<span class="leg fas fa-pen-fancy"> 
								<span class="text-legend"> Avis</span>
							</span> -->
						</div>
						<div id="contact">
							<div class="question-general title-leg">{{ $t('modals.questions.contact') }}</div>
							<div class="legend-list">
								<a class="leg1" :href="'tel:' + $t('modals.questions.phone')"><i class="fas fa-phone"/>{{ $t('modals.questions.phone') }}</a><br/>
								<a class="leg1" :href="'mailto:' + $t('modals.questions.email')"><i class="fas fa-envelope"/>{{ $t('modals.questions.email') }}</a>
							</div>
						</div>
					</div>
				</div>
            </div>
         </template>
		<template v-slot:buttons>
			<input v-model="search" :placeholder="$t('modals.questions.searchbar')" />
			<button id="close" class="search btn btn-gray" v-on:click="$allowScrolling(); $emit('close')" type="button">{{$t("common.close")}}</button>
		</template>
	</Modal>
</template>

<script>
	import VueMarkdown from "vue-markdown"
	import Modal from '../components/Modal.vue'
	import removeAccents from 'remove-accents'

	export default {
		components: {
			VueMarkdown,
			Modal
		},
		data() {
			return {
				activeQ: Object.fromEntries(
					this.$t('modals.questions.questions')
						.concat(this.$t('modals.questions.questionsadmin'))
						.map(({ question }) => [question, false])
				),
				search: '',
			}
		},
		methods: {
			doSearch(questions) {
				if (!this.search) return questions.filter(({ question, response }) => {
					return (question.length > 0)
				})

				const search = removeAccents(this.search.toLowerCase()).split(' ')
					.filter(str => str)

				return questions
					.filter(({ question, response }) => {
						const q = removeAccents(question.toLowerCase())
						const a = removeAccents(response.toLowerCase())
						return question.length > 0 && search.every(item => q.toLowerCase().includes(item) || a.toLowerCase().includes(item))
					})
					.filter(pred => pred)
			},
		},
		computed: {
			salonDesc() {
				return {
					beauty: this.$store.state.salon.type === 'beauty',
					hairdresser: this.$store.state.salon.type === 'hairdresser',
					barber: this.$store.state.salon.type === 'barber',
					// starter: this.$store.state.salon.subscription === 'starter',
					// premium: this.$store.state.salon.subscription === 'premium',
					// multi: this.$store.state.salon.subscription === 'multi',
				}
			},
			questions() {
				return this.$t('modals.questions.questions')
					.filter(({ hide }) => typeof hide === 'function' ? !hide(this.salonDesc) : true)
					.map(({ response, ...fields }) => ({ ...fields, response: typeof response === 'function' ? response(this.salonDesc) : response }))
			},
			adminQuestions() {
				return this.$t('modals.questions.questionsadmin')
					.filter(({ hide }) => typeof hide === 'function' ? !hide(this.salonDesc) : true)
					.map(({ response, ...fields }) => ({ ...fields, response: typeof response === 'function' ? response(this.salonDesc) : response }))
			},
			shownQ() {
				return this.doSearch(this.questions
					.map((f, i) => ({ ...f, id: i })))
			},
			shownQAdmin() {
				return this.doSearch(this.adminQuestions
					.map((f, i) => ({ ...f, id: i })))
			},
		},
	}
</script>

<style scoped lang="scss" src="../css/modals/questions.scss"/>
