<template>
	<li class="nav-item" :class="{disabled: disabled}">
		<router-link v-if="!disabled && to" class="nav-link" :to="to">
			<div :class="{ 'side-item': side }">
				<i v-if="icon" :class="'d-inline-block ' + icon"></i>
				<div class="d-inline-block side-text"><slot/></div>
				<i v-if="icon2" :class="'d-inline-block icon2 ' + icon2"></i>
			</div>
		</router-link>
		<a v-else class="nav-link" @click="$event.preventDefault(); $emit('click', $event)">
			<div :class="{ 'side-item': side }">
				<i v-if="icon" :class="'d-inline-block ' + icon"></i>
				<div class="d-inline-block side-text"><slot/></div>
				<i v-if="icon2" :class="'d-inline-block icon2 ' + icon2"></i>
			</div>
		</a>
	</li>
</template>


<script>
	export default {
		props: [
			"to",
			"icon",
			"icon2",
			"side",
			"disabled"
		],
		data() {
			return {
			}
		}
	}
</script>
