<template>
	<Modal id="new-appointment-modal" :class="{['overlay-step' + explainOverlayStep]: ( showExplainOverlay && !tempHideExplainOverlay )}">
		<template v-slot:header>
			<h1 class="title-search">{{$t("common.newapp")}}<i class="far fa-calendar-check"></i></h1>
		</template>
		<template v-slot:content>
			<button v-if="showExplainOverlay && !tempHideExplainOverlay" id="overlay-close" class="btn btn-gray" @click="showExplainOverlay=false; explainOverlay=false; explainOverlayStep=0">Ignorer le tutoriel</button>
			<button v-if="showExplainOverlay && !tempHideExplainOverlay" id="overlay-hide" class="btn btn-gray" @click="tempHideExplainOverlay=true">Cacher le tutoriel<br/>pour cette étape</button>
			<div class="tab-widget">
				<div class="row tab-widget-buttons-wrapper">
					<h3
						class="subtitle linetop tab-widget-button overlay-step1 overlay-top overlay-b-white"
						v-for="trans, i in ['common.customer', 'common.services', 'administration.schedules']"
						:key="i"
						:class="( page === i ? 'active ' : '' ) + ( ( i == 2 && maxPage > 1 ) ? 'overlay-step4 overlay-top overlay-b-white ' : '' )"
						@click="maxPage >= i && (page = i); i == 2 && showExplainOverlay && !tempHideExplainOverlay && (explainOverlayStep++)"
						:style="{ cursor: maxPage >= i ? 'cursor' : 'default' }"
					>
						<span class="text-sub" :class="{ 'anim-underline': maxPage >= i }">
							{{ $t( trans ) }}
						</span>
					</h3>
				</div>
			</div>
			<form class="form " @submit.prevent>
				<Carousel
					:perPage="1"
					:paginationEnabled="false"
					:navigateTo="page"
					:scrollPerPage="true"
					:touchDrag="false"
					:mouseDrag="false"
				>
					<Slide class="gender-section w100 carousel-slide">
						<div v-show="page === 0" class="overlay-step3 overlay-top overlay-b-white">
							<div v-if="!edit" id="user-buttons">
								<div v-if="$store.state.domain == 'esthetics'" class="check-btn">
									<input id="create-user-btn" type="radio" v-model="userType" value="create-user">
									<label class="btn btn-secondary btn-day-mobile btn-checkable btn-user" for="create-user-btn" @click="showCreateUser=true;userType='create-user'">{{ $t('common.createclient') }}</label>
								</div>
								<div class="check-btn">
									<input id="btn-anonymous" type="radio" v-model="userType" value="anonymous">
									<label class="btn btn-primary btn-day-mobile btn-checkable" for="btn-anonymous" @click="showCreateUser = false; user = null">{{$tc('common.anonymous') }}</label>
								</div>
								<UserSearchBar ref="userSearchBar" :seeall="false" @change="showCreateUser = false; userType = ''; userSelected( $event )"/>
							</div>
							<div class="input-group form-group">
								<div v-if="!edit" class="row margin-0">
									<div v-if="showCreateUser" id="create-user">
										<div class="card card-user">
											<div class="input-group section w-100 new-gender" ref="new_gender">
												<div class="radio radio-primary">
													<input id="create_gender_f" name="create_gender" type="radio" value="female" v-model="newUser.gender">
													<label for="create_gender_f">{{$t("data.genders.female")}}</label>
												</div>
												<div class="radio radio-primary">
													<input id="create_gender_h" name="create_gender" type="radio" value="male" v-model="newUser.gender">
													<label for="create_gender_h">{{$t("data.genders.male")}}</label>
												</div>
											</div>
											<div class="input-group" ref="new_birthdate">
												<div id="create-birthdate2" class="input-group date" data-target-input="nearest">
													<input class="form-control datetimepicker-input form-control" data-target="#create-birthdate2" :placeholder="$t('common.datebirth')" onkeydown="return false;" type="text">
													<span class="input-group-append" data-target="#create-birthdate2" data-toggle="datetimepicker">
														<div class="input-group-text"><i class="fa fa-calendar"></i></div>
													</span>
												</div>
											</div>
											<div class="input-group" ref="new_fname">
												<div class="input-group-prepend">
													<span class="input-group-text">{{$t("common.fname")}}</span>
												</div>
												<input class="form-control" type="text" v-model="newUser.fname">
											</div>
											<div class="input-group" ref="new_lname">
												<div class="input-group-prepend">
													<span class="input-group-text">{{$t("common.lname")}}</span>
												</div>
												<input class="form-control" type="text" v-model="newUser.lname">
											</div>
											<div class="input-group" ref="new_phone">
												<div class="input-group-prepend">
													<span class="input-group-text">{{$t("common.phone")}}</span>
												</div>
												<input class="form-control" type="text" v-model="newUser.phone">
											</div>
											<div class="input-group" ref="new_email">
												<div class="input-group-prepend">
													<span class="input-group-text">{{$t("common.email")}}</span>
												</div>
												<input class="form-control" type="text" v-model="newUser.email">
											</div>
											<StatusButton id="create-user-btn" type="primary" @click="createUser( $event )">{{ $t("common.create") }}</StatusButton>
										</div>
									</div>
									<div v-else id="infos-user">
										<div class="card card-user">
											<template v-if="user">
												<div class="user-names">{{ user.fname }} {{ user.lname }}</div>
												<div class="user-contacts">
													{{ user.phone }}{{ user.email ? ' - ' + user.email : '' }}
												</div>
												<div class="loyalty">{{ $t('common.loyalty') }} : {{ user.loyalty || 0 }}</div>
											</template>
											<template v-else>
												<textarea class="form-control" :placeholder="$t('common.message')" v-model="message"></textarea>
											</template>
										</div>
									</div>
								</div>
								<div v-else class="row margin-0">
									<div id="infos-user">
										<div class="card card-user">
											<template v-if="user && !!user.unregistration">
												[{{ $t('common.accdel') }}]
											</template>
											<template v-else-if="user">
												<div class="user-names">{{ user.fname }} {{ user.lname }}</div>
												<div class="user-contacts">
													{{ user.phone }} {{ user.email ? ' - ' + user.email : '' }}
												</div>
												<div class="loyalty">{{ $t('common.loyalty') }} : {{ user.loyalty || 0 }}</div>
											</template>
											<template v-else>
												<textarea class="form-control" :placeholder="$t('common.message')" v-model="message"></textarea>
											</template>
										</div>
									</div>
								</div>
							</div>
							<!-- 
							<h3 class="subtitle linetop">
								<div class="text-sub">{{ $t( $store.getters.salonPrestationsHasLength() ? 'common.hair' : 'common.client' ) }}</div>
							</h3>
							<div id="gender-group" class="input-group form-group">
								<div class="sections row">
									<div class="">
										<h3>Pour :</h3>
									</div>
									<div class="section">
										<div class="check-btn">
											<input id="gender_f" name="gender" type="radio" value="female" v-model="gender" @change="onGenderChange()">
											<label class="btn btn-primary btn-day-mobile btn-checkable" for="gender_f">{{$t("data.genders.female")}}</label>
										</div>
										<div class="check-btn">
											<input id="gender_h" name="gender" type="radio" value="male" v-model="gender" @change="onGenderChange()">
											<label class="btn btn-primary btn-day-mobile btn-checkable" for="gender_h">{{$t("data.genders.male")}}</label>
										</div>
										<div class="check-btn" v-if="$store.getters.salonAcceptsChildren()">
											<input id="gender_enf" name="gender" type="radio" value="child" v-model="gender" @change="onGenderChange()">
											<label class="btn btn-primary btn-day-mobile btn-checkable" for="gender_enf">{{$t("data.genders.child")}}</label>
										</div>
									</div>
								</div>
							</div>
							<div v-if="gender != 'child' && $store.getters.salonPrestationsHasLength()" id="hair-group" class="input-group form-group">
								<div class="sections row">
									<div class="">
										<h3>Longueur :</h3>
									</div>
									<div class="section">
										<div :class="{ 'check-btn': true, large: ( gender == 'male' ) }">
											<input id="hair_short" name="hair" type="radio" value="short" v-model="hair" @change="tryNextPage()">
											<label class="btn btn-secondary btn-day-mobile btn-checkable" for="hair_short">{{$t("data.hairlength.short")}}</label>
										</div>
										<div v-if="gender != 'male'" :class="{ 'check-btn': true, large: ( gender == 'male' ) }">
											<input id="hair_half" name="hair" type="radio" value="half" v-model="hair" @change="tryNextPage()">
											<label class="btn btn-secondary btn-day-mobile btn-checkable" for="hair_half">{{$t("data.hairlength.half")}}</label>
										</div>
										<div :class="{ 'check-btn': true, large: ( gender == 'male' ) }">
											<input id="hair_long" name="hair" type="radio" value="long" v-model="hair" @change="tryNextPage()">
											<label class="btn btn-secondary btn-day-mobile btn-checkable" for="hair_long">{{$t("data.hairlength.long")}}</label>
										</div>
									</div>
								</div>
							</div> -->
						</div>
					</Slide>
					<Slide class="w100 carousel-slide">
						<div v-show="page === 1" class="overlay-step4 overlay-top overlay-b-white" style="margin-top: 2rem">
							<!-- <div class="choices">
								<div class="check-btn" v-if="services.length">
									<input id="carte" type="checkbox" v-model="showServices" @change="updateCarouselLayout(); onNotTechnicShow()">
									<label class="btn btn-primary btn-day-mobile btn-checkable btn-choice" for="carte">
										{{$t("common.carte")}}
									</label>
								</div>
								<div class="check-btn" v-if="packages.length">
									<input id="pack" type="checkbox" v-model="showPackages" @change="updateCarouselLayout(); onNotTechnicShow()">
									<label class="btn btn-primary btn-day-mobile btn-checkable btn-choice" for="pack">
										{{$t("common.pack")}}
									</label>
								</div>
								<div class="check-btn" v-if="services.length || packages.length">
									<input id="technic" type="checkbox" v-model="showTechnic" @change="updateCarouselLayout(); onTechnicShow()">
									<label class="btn btn-primary btn-day-mobile btn-checkable btn-choice" for="technic">
										{{$t("administration.technic")}}
									</label>
								</div>
							</div> -->
							<div v-if="showServices" id="prestations-cont" class="input-group form-group">
								<div class="row">
									<div class="groupbox visible row">
										<div class="groupbox-header">
											<div class="groupbox-l1"></div>
											<div class="groupbox-title">
												{{ $t("common.carte") }}
											</div>
											<div class="groupbox-l2"></div>
										</div>
										<div class="presta-content" id="presta-content">
											<!-- <template v-if="$store.getters.salonHasSteps()">
												<div v-for="( text, step ) in $t('data.servicesstep')[$store.state.salon.type]" class="col-cat col-md-4">
													<div v-for="service in services" v-if="service[genderHair] && service[genderHair][1] && parseInt(service.step) === parseInt(step)" class="checkbox checkbox-secondary">
														<input :ref="'service-' + service.id" :id="'service-' + service.id" class="d-none" type="checkbox" :checked="serviceSelected( service )" :disabled="serviceSelectedFromPackage( service )" @change="updateSelectedService( service, $event.target.checked ); updateCalendar(); selectedDate = null">
														<label :for="'service-' + service.id">{{ service.title }}</label>
													</div>
												</div>
											</template>
											<template v-else> -->
												<div class="col-cat col-md-4">
													<div v-for="service in services" v-if="service[genderHair] && service[genderHair][1]" class="checkbox checkbox-secondary">
														<input :ref="'service-' + service.id" :id="'service-' + service.id" class="d-none" type="checkbox" :checked="serviceSelected( service )" :disabled="serviceSelectedFromPackage( service )" @change="updateSelectedService( service, $event.target.checked ); updateCalendar(); selectedDate = null">
														<label :for="'service-' + service.id">{{ service.title }}</label>
													</div>
												</div>
											<!-- </template> -->
										</div>
									</div>
								</div>
							</div>
							<div v-if="showPackages" id="packages-cont" class="input-group form-group group-box-radio">
								<div class="row">
									<div class="row-md-12">
										<div class="groupbox visible">
											<div class="groupbox-header">
												<div class="groupbox-l1"></div>
												<div class="groupbox-title">
													{{ $t("common.pack") }}
												</div>
												<div class="groupbox-l2"></div>
											</div>
											<template v-if="!$store.getters.salonPrestationsHavePauses()">
												<div v-for="pack in packages" class="checkbox checkbox-secondary">
													<input :id="'package-' + pack.id" class="d-none" type="checkbox" :checked="packageSelected(pack)" @change="updateSelectedPackage(pack, $event.target.checked); updateCalendar(); selectedDate = null">
													<label :for="'package-' + pack.id">{{ packageTitle( pack ) }}</label>
												</div>
											</template>
											<template v-else>
												<div class="radio radio-gray">
													<input id="package-none" class="d-none" type="radio" name="package" @change="setSelectedPackage(null); updateCalendar(); selectedDate = null">
													<label for="package-none"><i>{{ $t('common.none') }}</i></label>
												</div>
												<div v-for="pack in packages" class="radio radio-primary">
													<input :id="'package-' + pack.id" class="d-none" type="radio" name="package" :checked="packageSelected(pack)" @change="setSelectedPackage(pack); updateCalendar(); selectedDate = null">
													<label :for="'package-' + pack.id">{{ packageTitle( pack ) }}</label>
												</div>
											</template>
										</div>
									</div>
								</div>
							</div>
							<div v-if="showTechnic" class="input-group form-group">
								<div class="sections2 row">
									<div class="box-custom">
										<div id="prestation-custom" class="groupbox visible">
											<div class="groupbox-header">
												<div class="groupbox-l1"></div>
												<div class="groupbox-title">
													{{ $t("data.servicestype[-1]") }}
												</div>
												<div class="groupbox-l2"></div>
											</div>
											<div ref="customContent" id="custom-content">
												<div class="d-flex">
													<div class="desc">Titre :</div>
													<div class="tech-input">
														<input ref="customTitle" id="custom_title" class="form-control form-control" v-model="customTitleRaw" type="text" @input="searchTech" autocomplete="off">
														<Popper
															class="dropdown d-block"
															trigger="clickToToggle"
															:options="{ placement: 'bottom' }"
															:visible-arrow="false"
															:force-show="showTechPopper"
															ref="techPopper"
														>
															<div class="dropdown-menu" v-show="searchTechResults.length > 0">
																<div class="dropdown-choices" @click="showTechPopper = false; searchTechResults = []">
																	<button v-for="tech in searchTechResults" class="dropdown-item" @click="replaceTech(tech)">{{ tech.title }}</button>
																</div>
															</div>
															<div class="ref" slot="reference"></div>
														</Popper>
													</div>
												</div>
												<div class="d-flex">
													<div class="desc">Durée :</div>
													<div class="d-flex custom-times" v-if="$store.getters.salonPrestationsHavePauses()">
														<div :class="'time-block work' + ( customTimelineError[0] ? ' error' : '' )">
															<div class="title">Travail</div>
															<ValidationInput
																id="custom_hours"
																:placeholder="$t('time.hrs')"
																class="form-control duration-input"
																type="positiveInt" nullable
																v-model="customTimelineRaw[0]"
																@valid="technicValid['customTimelineRaw[0]'] = $event"
															/>
															<ValidationInput
																id="custom_minuts"
																class="form-control duration-input"
																:placeholder="$t('time.min')"
																type="positiveInt" nullable
																v-model="customTimelineRaw[1]"
																@valid="technicValid['customTimelineRaw[1]'] = $event"
															/>
														</div>
														<div :class="'time-block pause' + ( customTimelineError[1] ? ' error' : '' )">
															<div class="title">Pause</div>
															<ValidationInput
																id="custom_hours"
																:placeholder="$t('time.hrs')"
																class="form-control duration-input"
																type="positiveInt" nullable
																v-model="customTimelineRaw[2]"
																@valid="technicValid['customTimelineRaw[2]'] = $event"
															/>
															<ValidationInput
																id="custom_minuts"
																class="form-control duration-input"
																:placeholder="$t('time.min')"
																type="positiveInt" nullable
																v-model="customTimelineRaw[3]"
																@valid="technicValid['customTimelineRaw[3]'] = $event"
															/>
														</div>
														<div :class="'time-block work' + ( customTimelineError[2] ? ' error' : '' )">
															<div class="title">Travail</div>
															<ValidationInput
																id="custom_hours"
																:placeholder="$t('time.hrs')"
																class="form-control duration-input"
																type="positiveInt" nullable
																v-model="customTimelineRaw[4]"
																@valid="technicValid['customTimelineRaw[4]'] = $event"
															/>
															<ValidationInput
																id="custom_minuts"
																class="form-control duration-input"
																:placeholder="$t('time.min')"
																type="positiveInt" nullable
																v-model="customTimelineRaw[5]"
																@valid="technicValid['customTimelineRaw[5]'] = $event"
															/>
														</div>
														<div :class="'time-block pause' + ( customTimelineError[3] ? ' error' : '' )">
															<div class="title">Pause</div>
															<ValidationInput
																id="custom_hours"
																:placeholder="$t('time.hrs')"
																class="form-control duration-input"
																type="positiveInt" nullable
																v-model="customTimelineRaw[6]"
																@valid="technicValid['customTimelineRaw[6]'] = $event"
															/>
															<ValidationInput
																id="custom_minuts"
																class="form-control duration-input"
																:placeholder="$t('time.min')"
																type="positiveInt" nullable
																v-model="customTimelineRaw[7]"
																@valid="technicValid['customTimelineRaw[7]'] = $event"
															/>
														</div>
														<div :class="'time-block work' + ( customTimelineError[4] ? ' error' : '' )">
															<div class="title">Travail</div>
															<ValidationInput
																id="custom_hours"
																:placeholder="$t('time.hrs')"
																class="form-control duration-input"
																type="positiveInt" nullable
																v-model="customTimelineRaw[8]"
																@valid="technicValid['customTimelineRaw[8]'] = $event"
															/>
															<ValidationInput
																id="custom_minuts"
																class="form-control duration-input"
																:placeholder="$t('time.min')"
																type="positiveInt" nullable
																v-model="customTimelineRaw[9]"
																@valid="technicValid['customTimelineRaw[9]'] = $event"
															/>
														</div>
													</div>
												<div class="d-flex" v-else>
													<ValidationInput
														id="custom_hours"
														class="form-control duration-input"
														type="positiveInt" nullable
														v-model="customTimelineRaw[0]"
														@valid="technicValid['customTimelineRaw[0]'] = $event"
													/>
													<span class="no-pause-suffix">{{ $t('time.hrs') }}</span>
													<ValidationInput
														id="custom_minuts"
														class="form-control duration-input"
														type="positiveInt" nullable
														v-model="customTimelineRaw[1]"
														@valid="technicValid['customTimelineRaw[1]'] = $event"
													/>
													<span class="no-pause-suffix">{{ $t('time.min') }}</span>
												</div>
												</div>
												<div id="custom_price" class="d-flex">
													<div class="desc">Prix :</div>
													<ValidationInput
														class="form-control price-input"
														type="positiveReal" nullable
														v-model="customPrice"
														@valid="technicValid['customPrice'] = $event"
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="alt-time-blocks">
								<div v-for="altTimeService in altTimeServices" class="input-group form-group group-box-radio">
									<div class="row">
										<div class="row-md-12">
											<div class="groupbox visible">
												<div class="groupbox-header">
													<div class="groupbox-l1"></div>
													<div class="groupbox-title">
														{{ altTimeService.title }}
													</div>
													<div class="groupbox-l2"></div>
												</div>
												<div class="radio radio-primary">
													<input :id="'alt-service-1-' + altTimeService.id" class="d-none" type="radio" :name="'alt-service-' + altTimeService.id"
														@change="selectAltTime(altTimeService.id, 'lighten'); selectedDate = null" :checked="isAltTimeSelected(altTimeService.id, 'lighten')">
													<label :for="'alt-service-1-' + altTimeService.id">{{ $t('common.lighten') }}</label>
												</div>
												<div class="radio radio-primary">
													<input :id="'alt-service-2-' + altTimeService.id" class="d-none" type="radio" :name="'alt-service-' + altTimeService.id"
														@change="selectAltTime(altTimeService.id, 'darken'); selectedDate = null" :checked="isAltTimeSelected(altTimeService.id, 'darken')">
													<label :for="'alt-service-2-' + altTimeService.id">{{ $t('common.darken') }}</label>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</Slide>
					<Slide class="w100 carousel-slide overlay-step5 overlay-top overlay-b-white">
						<div>
							<div v-if="showCalendar" class="message-end input-group form-group">
								<textarea class="form-control" :placeholder="$t('common.message')" v-model="message"></textarea>
							</div>
							<div v-if="showCalendar" v-show="page === 2 && selWorker" class="input-group form-group">
								<div id="calendar-group">
									<div class="d-flex calendar-title">
										<button class="btn btn-secondary" :disabled="!calendarCanGoLeft" @click="calendarGoLeft" id="new-appt-calendar-prev">
											<span class="fa fa-arrow-left"></span>
										</button>
										<h3 class="subtitle linetop schedules-title">
											<div class="text-sub">{{ $t( 'common.organizer' ) }}</div>
										</h3>
										<button class="btn btn-secondary" @click="calendarGoRight" id="new-appt-calendar-next">
											<span class="fa fa-arrow-right"></span>
										</button>
									</div>
									<div id="rdv-wrapper">
										<Calendar
											ref="calendar"
											id="rdv-calendar"
											:allowInsertion="true"
											:editing="edit ? edit : undefined"
											:insertionTimeline="insertionTimeline"
											:baseDate="edit ? getDate(edit.date) : undefined"
											@selected="blockSelected( $event )"
										></Calendar>
										<div id="selector-wrapper" class="appointment-wrapper"></div>
									</div>
								</div>
							</div>
							<!-- <div v-if="!selWorker" class="select-worker-hint">
								{{ $t("appointments.selectworker") }}
							</div> -->
						</div>
					</Slide>
				</Carousel>
			</form>
			<div v-if="$store.state.domain == 'esthetics'" class="carousel-arrows">
				<button class="btn btn-primary chevron" id="appt-modal-prev-page" :disabled="page === 0" @click="carouselGo('left')"><i class="fas fa-chevron-left" /></button>
				<div :class="maxPage > 1 && 'overlay-step4 overlay-top overlay-b-white'">
					<button class="btn btn-primary chevron" id="appt-modal-next-page" :disabled="page === maxPage" @click="carouselGo('right')"><i class="fas fa-chevron-right" /></button>
				</div>
			</div>
			<div v-if="showExplainOverlay && !tempHideExplainOverlay" id="explain-overlay">
				<div class="overlay-step1">
					<span>La prise de rendez-vous est séparée en 4 étapes</span>
					<button class="btn overlay-next" @click="explainOverlayStep++">Suivant</button>
				</div>
				<div class="overlay-step2">
					<button class="btn overlay-prev" @click="explainOverlayStepPrevious">Précédent</button>
					<div class="overlay-sub">
						<span>1. Choisissez le collaborateur concerné par le rendez-vous</span>
						<span class="text-muted">Vous pouvez le modifier à tout moment durant le processus de création de rendez-vous</span>
					</div>
					<button class="btn overlay-next" @click="explainOverlayStep++">Suivant</button>
				</div>
				<div class="overlay-step3">
					<button class="btn overlay-prev" @click="explainOverlayStepPrevious">Précédent</button>
					<span>2. Choisissez le client et ses caractéristiques</span>
					<button class="btn overlay-next" v-if="maxPage > 0" @click="explainOverlayStep++; page++">Suivant</button>
				</div>
				<div class="overlay-step4">
					<button class="btn overlay-prev" @click="explainOverlayStepPrevious">Précédent</button>
					<span>3. Sélectionnez le(s) forfait(s) et/ou prestations demandés par le client</span>
					<button class="btn overlay-next" v-if="maxPage > 1" @click="explainOverlayStep++; page++">Suivant</button>
				</div>
				<div class="overlay-step5">
					<button class="btn overlay-prev" @click="explainOverlayStepPrevious">Précédent</button>
					<span>4. Choisissez la date et l'heure du rendez-vous</span>
					<button class="btn overlay-next" v-if="canTakeApp" @click="explainOverlayStep++">Suivant</button>
				</div>
				<div class="overlay-step6">
					<button class="btn overlay-prev" @click="explainOverlayStepPrevious">Précédent</button>
					<span>5. Validez le rendez-vous</span>
				</div>
			</div>
		</template>
		<template v-slot:buttons>
			<div id="recap-mobile" class="mobile">
				<i>{{ customTitle || selectedTitles }}</i>
				<b>{{ selectedDate ? $d(selectedDate, "longlong") : '' }}</b>
			</div>
			<div id="hairdresser-group">
				<WorkersDropdown
					ref="workersDropdown"
					placement="top"
					class="overlay-step1 overlay-step2 overlay-top overlay-b-white overlay-z12 overlay-no-relative"
					:class="{ 'overlay-disabled': explainOverlayStep == 1 }"
					:general="false"
					:colors="true"
					:initialid="edit ? ( edit.workerid || edit.workers[0] ) : $store.state.worker.id"
					@change="updateSelWorker(); $refs.calendar ? $refs.calendar.workerChanged( $event ) : undefined"
				/>
			</div>
			<div>
				<div v-show="customTitle || selectedTitles" id="recap" class="desktop">
					{{ customTitle || selectedTitles }}
					<template v-if="selectedPrice !== null"> : {{ selectedPrice }}€</template>
				</div>
				<div id="recap2" class="desktop">
					{{ selectedDate ? $d(selectedDate, "longlong") : "" }}
				</div>
			</div>
			<div class="final-buttons">
				<StatusButton id="validate" class="overlay-step6 overlay-top overlay-b-white" type="primary" alert="modal" :enabled="canTakeApp || page < maxPage" @click="canTakeApp ? take( $event ) : tryNextPage($event)">{{ canTakeApp ? $t("common.validate") : $t("common.next") }}</StatusButton>
				<button id="cancel" class="btn btn-gray" @click="$emit('close')" type="button">{{ $t("common.cancel") }}</button>
			</div>
		</template>
	</Modal>
</template>

<script>
	import dayjs from "dayjs"
	import Popper from "vue-popperjs"
	import { Slide, Carousel } from 'vue-carousel'
	import Modal from '../components/Modal.vue'
	import WorkersDropdown from '../components/WorkersDropdown.vue'
	import UserSearchBar from '../components/UserSearchBar.vue'
	import StatusButton from '../components/StatusButton.vue'
	import { computeBlocksTimeline, computeServices, recurseAllDependencies } from '../servicesLogic.js'

	let explainOverlay = !!process.env.VUE_APP_DEMO

	export default {
		components: {
			Popper,
			WorkersDropdown,
			UserSearchBar,
			Modal,
			StatusButton,
			Slide,
			Carousel,
		},
		props: [
			"edit"
		],
		data() {
			return {
				stringify: JSON.stringify,
				showExplainOverlay: false,
				tempHideExplainOverlay: false,
				explainOverlayStep: 1,
				userType: "anonymous",
				user: null,
				newUser: { fname: '', lname: '', phone: '', email: '', gender: '', birthdate: '' },
				showCreateUser: false,
				gender: "",
				hair: this.$store.getters.salonPrestationsHasLength() ? null : 'short',
				services: [],
				packages: [],
				message: null,
				selWorker: false,
				showServices: true,
				showPackages: false,
				showTechnic: false,
			//	selectedPackageId: -1,
			//	selectedPackage: null,
				selectedServices: [],
				selectedPackages: [],
				selectedPackageServices: [],
				selectedPackageServicesCounter: {},
				insertionTimeline: [],
				showCalendar: false,
				calendarCanGoLeft: false,
				selectedDate: null,
				allServicesIds: [],
				showTechPopper: false,
				customTitle: "",
				searchTechResults: [],
				customTimelineRaw: [],
				customTimelineError: [],
				customTimeline: [],
				customPrice: null,
				timeline: [],
				loading: true,
				page: this.edit ? 2 : 0,
				alternateTimesSel: {},
				customTitleRaw: '',
				selectedAltTimes: [],
				technicValid: {
					'customTimelineRaw[0]': true,
					'customTimelineRaw[1]': true,
					'customTimelineRaw[2]': true,
					'customTimelineRaw[3]': true,
					'customTimelineRaw[4]': true,
					'customTimelineRaw[5]': true,
					'customTimelineRaw[6]': true,
					'customTimelineRaw[7]': true,
					'customTimelineRaw[8]': true,
					customPrice: true,
				},
				...(this.edit && this.edit.services.length === 0 ? {
					showTechnic: this.edit.services.length === 0,
					customTitle: ( this.edit.services.length === 0 ) ? this.edit.title : null,
					customTitleRaw: this.edit.title,
					customPrice: ( this.edit.services.length === 0 ) ? this.edit.price : null,
					customTimeline: ( this.edit.services.length === 0 ) ? this.edit.timeline : null,
					customTimelineRaw: this.createRawTimeline(this.edit.timeline),
				} : {})
			}
		},
		computed: {
			genderHair() {
				return "female"
				if (!this.gender) return null
				if ( this.$store.domain == "esthetics" ) {
					if (this.gender !== 'child' && !this.hair) return null
					return this.gender === 'child' ? 'child' : `${this.gender}_${this.hair}`
				} else {
					return this.gender
				}
			},
			selectedTitles() {
				const ret = []
				const alreadyIds = []
				const services = []
				const finalServices = []
				const skipPackagesServices = []
				for ( let i = 0; i < this.selectedPackages.length; i++ ) {
					if ( this.selectedPackages[i].title ) {
						ret.push( this.selectedPackages[i].title )
						const r = this.selectedPackages[i].services.map( s => isNaN(parseInt(s)) ? s.id : parseInt(s) )
						alreadyIds.push( ...r )
						skipPackagesServices.push( ...r )
					}
				}
				services.push( ...( this.selectedPackageServices.filter( id => skipPackagesServices.findIndex(x => x == id) < 0 ) ) )
				if ( this.services.length > 0 ) {
					services.push( ...this.selectedServices )
				}
				services.forEach( service => {
					if ( service && !finalServices.find( f => f.id == service.id ) && alreadyIds.findIndex( a => a == service.id) < 0 ) {
						finalServices.push( service )
					}
				})
				return [ ...finalServices.sort((a, b) => a.priority - b.priority).map(s => s.title), ...ret ].join( " - " )
			},
			selectedPrice() {
				let ret = 0
				let already = []
				for ( let i = 0; i < this.selectedPackages.length; i++ ) {
					ret = this.selectedPackages[i][this.genderHair]
					this.selectedPackages[i].services.forEach( serviceid => {
						already.push( parseInt(serviceid) )
					})
					if ( !ret ) {
						already = []
					}
				}

				const val = [ ...this.selectedPackageServices, ...this.selectedServices ]
					.filter((s, i, self) => self.findIndex(f => f && s && f.id == s.id) == i)
					.filter(s => already.findIndex(x => x == s.id) < 0)
					.reduce((acc, s) => {
						if (acc === null || typeof s[this.genderHair][0] !== 'number') return null
						return acc + parseFloat(s[this.genderHair][0] || 0)
					}, 0)

				if (this.customPrice === '')
					this.customPrice = null

				return val === null || this.customPrice === null ? null : ret + val + parseInt(this.customPrice || '0')
			},
			canTakeApp() {
				let ok = 0
				if ( this.genderHair && this.genderHair.length > 0 ) {
					ok++
				}
				if ( ( !this.$store.getters.salonPrestationsHavePauses() && this.timeline[0] > 0 ) || ( this.allServicesIds && this.allServicesIds.length > 0 ) || (this.showTechnic && this.customTitle && !this.customTimelineError.some(e => e) && typeof this.customTimeline[0] === 'number') ) {
					ok++
				}
				if (Object.values(this.technicValid).every(v => v)) {
					ok++
				}
				if (this.hasAllAltTimeServicesChoosen) {
					ok++
				}
				if ( this.customTitle || this.selectedTitles ) {
					ok++
				}
				if ( this.timeline && this.timeline.length > 0 ) {
					ok++
				}
				if ( ( this.selectedDate instanceof Date && !isNaN( this.selectedDate ) ) || ( this.selectedDate && this.selectedDate.isValid() ) ) {
					ok++
				}
				if ( this.$refs.workersDropdown && this.$refs.workersDropdown.workerid > 0 && !this.$store.getters.getWorker(this.$refs.workersDropdown.workerid).hidden ) {
					ok++
				}
				return ( ok === 8 )
			},
			maxPage() {
				// if (!this.gender)
					// return 0

				if (this.gender !== 'child' && this.$store.getters.salonPrestationsHasLength() && !this.hair)
					return 0

				if (!this.showTechnic && !this.selectedServices.length && !this.selectedPackageServices.length && !this.selectedPackages.length)
					return 1

				if (!this.hasAllAltTimeServicesChoosen)
					return 1

				if (this.showTechnic &&
						(!this.customTitle || this.customTimelineError.some(e => e) || typeof this.customTimeline[0] !== 'number' ||
							!Object.values(this.technicValid).every(v => v)))
					return 1

				return 2
			},
			altTimeServices() {
				return computeServices(this.$store.state.services, this.selectedServices, this.selectedPackages, false)
					.filter(service => service['alt_' + this.genderHair] && service['alt_' + this.genderHair][1] > 0)
			},
			hasAllAltTimeServicesChoosen() {
				if (!this.selectedAltTimes) return
				return this.altTimeServices.every(service => this.selectedAltTimes.some(entry => entry.id === service.id))
			},
		},
		watch: {
			page( value ) {
				if ( value == 2 ) {
					const f = () => {
						if ( this.$refs.calendar ) {
							this.$refs.calendar.resize()
						} else {
							setTimeout( f, 250 )
						}
					}
					this.$nextTick( () => {
						f()
					})
				}
			},
			showServices( value ) {
				if ( !value ) {
				//	this.selectedServices = []
				}
			},
			showPackages( value ) {
				if ( !value ) {
				//	this.selectedPackages = null
				//	this.selectedPackageId = null
				}
			},
			showCreateUser( value ) {
				if ( value ) {
					this.$nextTick( () => {
						const dt = $(this.$el).find('#create-birthdate2')
						dt.datetimepicker({
							"format": "DD MMM YYYY",
							"widgetPositioning": { "vertical": "bottom", "horizontal": "right" },
							"debug": ( process.env.VUE_APP_MODE === "development" ),
							"icons": { "time": "fa fa-clock" },
							"locale": this.$i18n.locale,
							"maxDate": dayjs(),
						})
						dt.on( "change.datetimepicker", ( e ) => {
							this.newUser.birthdate = e.date.format( "YYYY-MM-DD" )
						})
					})
				}
			},
			gender( value ) {
				if ( !this.loading ) {
					this.updateServices()
					this.selectedPackages = []
					this.selectedServices = []
					this.selectedPackageServices = []
					this.selectedPackageServicesCounter = {}
					this.allServicesIds = []
					this.timeline = []
					this.insertionTimeline = []
					this.selectedDate = null
					if ( this.showCalendar ) {
						if ( this.$refs.calendar ) {
							this.$refs.calendar.clearInsertion()
						}
						this.updateCalendar()
					}
					if ( (!!this.hair || value == "child") && !this.showTechnic ) {
						this.showServices = true
						/*
						if ( this.packages.length == 0 || [ "beauty" ].includes( this.$store.state.salon.type ) ) {
							this.showServices = true
						} else {
							this.showServices = false
						}
						*/
					}
					this.showServices = true
				}
			},
			hair( value ) {
				if ( !this.loading ) {
					this.updateServices()
					this.selectedPackages = []
					this.selectedServices = []
					this.selectedPackageServices = []
					this.selectedPackageServicesCounter = {}
					this.allServicesIds = []
					this.timeline = []
					this.insertionTimeline = []
					this.selectedDate = null
					if ( this.showCalendar ) {
						if ( this.$refs.calendar ) {
							this.$refs.calendar.clearInsertion()
						}
						this.updateCalendar()
					}
				}
			},
			/*
			selectedPackage( value, oldValue ) {
				if ( oldValue ) {
					oldValue.services.forEach( serviceid => {
						let service = this.$store.state.services.find( serv => serv.id == serviceid )
						const idx = this.selectedPackageServices.indexOf( service )
						if ( idx >= 0 ) {
							this.selectedPackageServices.splice( idx, 1 )
						}
					})
				}
				if ( value ) {
					value.services.forEach( serviceid => {
						let service = this.$store.state.services.find( serv => serv.id == serviceid )
						this.selectedPackageServices.push( service )
						if ( service.dependencies ) {
							service.dependencies.forEach( dep => {
								this.updateSelectedService( this.$store.state.services.find( serv => serv.id == dep ), true ) 
							})
						}
					})
				}
				this.updateCalendar()
			},
			*/
			customTimelineRaw( value ) {
				this.customTimeline = []
				this.customTimelineError = []
				let timeline = []

				for ( let i = 0; i < value.length; i += 2 ) {
					if ( !isNaN(parseInt(value[i])) && !isNaN(parseInt(value[i+1])) ) {
						timeline[i / 2] = value[i] * 60 + parseInt(value[i + 1])
					} else if ( !isNaN(parseInt(value[i])) ) {
						timeline[i / 2] = value[i] * 60
					} else if ( !isNaN(parseInt(value[i+1])) ) {
						timeline[i / 2] = parseInt(value[i+1])
					} else {
						timeline[i / 2] = null
					}
				}
				let started = false
				let err = false
				for ( let i = timeline.length - 1; i >= 0; i-- ) {
					if ( timeline[i] != null ) {
						started = true
					}
					if ( started && (timeline[i] == null || timeline[i] < 5) ) {
						this.customTimelineError[i] = true
						err = true
					}
				}
				if ( !err && timeline[0] !== null && Object.values(this.technicValid).every(val => val) ) {
					this.customTimeline = timeline
					this.updateCalendar()
				}
				this.selectedDate = null
			},
			altTimeServices(val) {
				// remove alt time services when unsel
				this.selectedAltTimes = this.selectedAltTimes.filter(entry => val.some(service => service.id == entry.id))
			}
		},
		methods: {
			explainOverlayStepPrevious() {
				console.log( "explainOverlayStepPrevious" )
				this.explainOverlayStep--
				console.log( "explainOverlayStep", this.explainOverlayStep )
				this.tempHideExplainOverlay = false
				if ( this.explainOverlayStep <= 3 ) {
					this.page = 0
				}
				if ( this.explainOverlayStep == 4 ) {
					this.page = 1
				}
				if ( this.explainOverlayStep >= 5 ) {
					this.page = 2
				}
				this.$forceUpdate()
			},
			updateSelWorker() {
				const drop = this.$refs.workersDropdown
				const r = !!drop && ( drop.workerid > 0 && /*drop.workerid != this.$store.getters.getGeneralWorker().id &&*/ !this.$store.getters.getWorker(drop.workerid).hidden )
				console.log( "selWorker", r, drop )
				this.selWorker = r
			},
			onGenderChange() {
				this.hair = this.$store.state.domain == "esthetics" ? (!this.$store.getters.salonPrestationsHasLength() ? 'short' : '') : ""
				if (!this.$store.getters.salonPrestationsHasLength() || this.gender == "child")
					this.tryNextPage()
			},
			selectAltTime(id, val) {
				const index = this.selectedAltTimes.findIndex(a => a.id == id)

				const entry = { id, val }

				if (index >= 0)
					this.selectedAltTimes[index] = entry
				else
					this.selectedAltTimes.push(entry)
				this.updateCalendar()
			},
			isAltTimeSelected(id, val) {
				return this.selectedAltTimes.some(a => a.id == id && a.val == val)
			},
			createRawTimeline(timeline) {
				return timeline.flatMap(a => [Math.floor(a / 60), a % 60])
			},
			tryNextPage( cb ) {
				if ( this.page < this.maxPage ) {
					this.page++
					if ( this.showExplainOverlay ) {
						this.explainOverlayStep++
						this.tempHideExplainOverlay = false
					}
				}
				if ( cb ) {
					cb( -1 )
				}
			},
			onTechnicShow() {
				console.log('technic')
				this.selectedPackages = []
				this.selectedServices = []
				this.selectedPackageServices = []
				this.selectedPackageServicesCounter = {}
				this.showPackages = false
				this.showServices = false
				this.selectedDate = null
				this.allServicesIds = []
			},
			onNotTechnicShow() {
				console.log('no technic')
				this.showTechnic = false
				this.showTechPopper = false
				this.customTitle = ""
				this.customTitleRaw = ""
				this.searchTechResults = []
				this.customTimelineRaw = []
				this.customTimelineError = []
				this.customTimeline = []
				this.technicValid = {}
				this.customPrice = null
				this.selectedDate = null
			},
			updateCarouselLayout() {
				this.$nextTick( () => {
					window.dispatchEvent(new Event('resize'))
				})
			},
			carouselGo(dir) {
				if (dir === 'left') {
					this.page = Math.max(this.page - 1, 0)
					this.explainOverlayStep = Math.max( this.explainOverlayStep - 1, 0 )
				}
				if (dir === 'right') {
					this.page = Math.min(this.page + 1, this.maxPage)
					this.explainOverlayStep = Math.min( this.explainOverlayStep + 1, 6 )
				}
			},
			getDate( str ) {
				return new Date( str )
			},
			createUser( callback ) {
				let missing = false
				Object.keys( this.newUser ).forEach( field => {
					if ( field !== "email" ) {
						const value = this.newUser[ field ]
						if ( !value || value.length == 0 ) {
							missing = true
							this.$refs['new_' + field].classList.add( "required" )
						} else {
							this.$refs['new_' + field].classList.remove( "required" )
						}
					}
				})
				if ( missing ) {
					callback( false, this.$t( "errors.missing" ) )
				} else {
					this.$api.salonsusers.post( this.newUser ).then( response => {
						callback( true )
						this.userSelected( response.data[0] )
						this.showCreateUser = false
						this.userType = ''
					}).catch( error => {
						console.error( error )
						callback( false, this.$t( error.response.data.error || error ) )
					})
				}
			},
			searchTech( event ) {
				let text = event.target.value.toLowerCase()
				let start = event.target.selectionStart - 1
				let end = event.target.selectionStart - 1
				while ( start > 0 && text.charAt(start) !== ' ' && text.charAt(start) !== '\t' ) {
					start--
				}
				if ( text.charAt(start) === ' ' || text.charAt(start) === '\t' ) {
					start++
				}
				while ( end < text.length && text.charAt(end) !== ' ' && text.charAt(end) !== '\t' ) {
					end++
				}
				const word = text.substr( start, end - start )
				if ( !word || word.length === 0 ) {
					this.searchTechResults = []
					this.showTechPopper = false
					this.$refs.customContent.classList.remove( "drop-visible" )
					this.customTitle = ""
					return
				}
				let ret = []
				this.$store.state.services.forEach( service => {
					if ( service.type < 0 ) {
						if ( service.title.toLowerCase().indexOf( word ) >= 0 ) {
							ret.push( service )
						}
					}
				})
				this.searchTechResults = ret
				this.$refs.customContent.classList.add( "drop-visible" )
				this.showTechPopper = true
				this.customTitle = this.$refs.customTitle.value
			},
			replaceTech(tech) {
				const title = tech.title

				const price = tech[{ child: 'child', male: 'male_short', female: 'female_short' }[this.gender]][0]
				if (price)
					this.customPrice = price

				const old = this.$refs.customTitle.value
				let start = this.$refs.customTitle.selectionStart - 1
				let end = this.$refs.customTitle.selectionStart - 1
				while ( start > 0 && old.charAt(start) !== ' ' && old.charAt(start) !== '\t' ) {
					start--
				}
				if ( old.charAt(start) === ' ' || old.charAt(start) === '\t' ) {
					start++
				}
				while ( end < old.length && old.charAt(end) !== ' ' && old.charAt(end) !== '\t' ) {
					end++
				}
				this.customTitleRaw = old.substr( 0, start ) + title.trim() + old.substr( end )
				this.customTitle = this.customTitleRaw
				this.$refs.customTitle.selectionStart = ( old.substr( 0, start ) + title.trim() ).length
				this.$refs.customTitle.selectionEnd = this.$refs.customTitle.selectionStart
				this.$refs.customTitle.focus()
			},
			packageTitle( pack ) {
				if ( !pack ) {
					return ""
				}
				if ( pack.title ) {
					return pack.title
				}
				return pack.services.reduce( ( a, b ) => a + ( a != "" ? " - " : "" ) + this.$store.getters.getService( b ).title, "" )
			},
			userSelected( user ) {
				this.user = user
				let age = dayjs().diff( dayjs(this.user.birthdate), "year" )
				if ( this.$store.getters.salonAcceptsChildren() && age < ( this.$store.state.salon.youthage || 16 ) ) {
					this.gender = "child"
					this.hair = ''
				} else {
					this.gender = user.gender
				}
				this.updateServices()
			},
			updateServices() {
				this.gender = 'female'
				if ( true ) {
				// if ( this.gender != '' && ( this.$store.state.domain == "coachs" || this.hair != '' || this.gender == 'child' ) && !( this.gender == 'male' && this.hair == 'half' ) ) {
					let services = [];
					let packages = [];
					// this.gender_hair = this.gender + ( this.hair ? ( "_" + this.hair) : "" );
	
					console.log("services", this.$store.state.services)
					for ( var i = 0; i < this.$store.state.services.length; i++ ) {
						let service = this.$store.state.services[i];
						let values = service[ this.genderHair ];
						if ( Array.isArray( values ) && values.length > 0 ) {
							services.push( service );
						}
					}
	
					for ( var i = 0; i < this.$store.state.formulas.length; i++ ) {
						let pack = this.$store.state.formulas[i];
						let genders = pack.genders.split( "," );
						if ( genders.includes( this.gender ) ) {
							if ( pack.title == null ) {
								pack.title = pack.services.reduce( ( a, b ) => a + ( a != "" ? " - " : "" ) + this.$store.getters.getService( b ).title, "" );
							}
							packages.push( pack );
						}
					}

					this.services = services
					this.packages = packages

					if (!this.showTechnic) {
						if ( this.edit ) {
							this.showServices = true
						} else {
						//	if ( [ "beauty" ].includes( this.$store.state.salon.type ) ) {
						//		this.showPackages = false
						//		this.showServices = true
						//	} else {
								this.showPackages = !!this.packages.length
								this.showServices = true // !this.packages.length
						//	}
						}

						if (!this.services.length && !this.packages.length) {
							this.showPackages = false
							this.showServices = false
							this.showTechnic = true
						}
					}
				} else {
					this.services = []
					this.packages = []
				}
				this.selectedServices = []
				this.selectedPackageServices = []
				this.selectedPackageServicesCounter = {}

				if ( this.edit ) {
					let skipServices = []
					if ( this.edit.packages ) {
						for ( let i = 0; i < this.edit.packages.length; i++ ) {
							const pack = this.$store.state.formulas.find( f => f.id == this.edit.packages[i] )
							this.updateSelectedPackage( pack, true )
							skipServices.push( ...recurseAllDependencies( this.$store.state.services, pack.services ) )
						//	this.selectedPackages.push( this.$store.state.formulas.find( f => f.id == this.edit.packages[i] ) )
						}
					}
					console.log( "Init skipServices", skipServices )
					for ( let i = 0; i < this.edit.services.length; i++ ) {
						if ( !skipServices.find( skip => this.edit.services[i] == skip.id ) ) {
							this.selectedServices.push( this.$store.state.services.find( serv => serv.id == this.edit.services[i] ) )
						}
					}
					if ( this.selectedServices.length > 0 ) {
						this.showServices = true
					}
					if ( this.selectedPackages.length > 0 ) {
						this.showPackages = true
					}
				}
			},
			updateSelectedService( service, checked ) {
				if ( checked && !this.selectedServices.includes( service ) && service && service[this.genderHair] && service[this.genderHair][1] ) {
					this.selectedServices.push( service )
					if ( service.dependencies ) {
						service.dependencies.forEach( dep => {
							this.updateSelectedService( this.$store.state.services.find( serv => serv.id == dep ), checked ) 
						})
					}
				} else if ( !checked && this.selectedServices.includes( service ) ) {
					this.selectedServices.splice( this.selectedServices.indexOf( service ), 1 )
				}
			},
			updateSelectedPackage( pack, checked ) {
				console.log( "updateSelectedPackage", pack, checked )
				if ( checked && !this.selectedPackages.includes( pack ) && pack.genders.split(",").includes(this.gender) ) {
					this.selectedPackages.push( pack )
					pack.services.forEach( serviceid => {
						const service = this.$store.state.services.find( serv => serv.id == serviceid )
						this.selectedPackageServicesCounter[service.id] = this.selectedPackageServicesCounter[service.id] || 0
						this.selectedPackageServicesCounter[service.id]++
						if ( service.dependencies ) {
							service.dependencies.forEach( dep => {
								this.selectedPackageServicesCounter[dep] = this.selectedPackageServicesCounter[dep] || 0
								this.selectedPackageServicesCounter[dep]++
							})
						}
					})
				} else if ( !checked && this.selectedPackages.includes( pack ) ) {
					this.selectedPackages.splice( this.selectedPackages.indexOf( pack ), 1 )
					pack.services.forEach( serviceid => {
						const service = this.$store.state.services.find( serv => serv.id == serviceid )
						if ( this.selectedPackageServicesCounter[service.id] ) {
							this.selectedPackageServicesCounter[service.id]--
							if ( service.dependencies ) {
								service.dependencies.forEach( dep => {
									if ( this.selectedPackageServicesCounter[dep] ) {
										this.selectedPackageServicesCounter[dep]--
									}
								})
							}
						}
					})
				}

				this.selectedPackageServices = []
				const ids = Object.keys( this.selectedPackageServicesCounter )
				ids.forEach( serviceid => {
					if ( this.selectedPackageServicesCounter[serviceid] > 0 ) {
						this.selectedPackageServices.push( this.$store.state.services.find( serv => serv.id == serviceid ) )
					}
				})
			},
			setSelectedPackage( pack ) {
				this.selectedPackages = []
				this.selectedPackageServicesCounter = []

				if ( pack && pack.genders.split(",").includes(this.gender) ) {
					this.selectedPackages = [ pack ]
					pack.services.forEach( serviceid => {
						const service = this.$store.state.services.find( serv => serv.id == serviceid )
						this.selectedPackageServicesCounter[service.id] = 1
						if ( service.dependencies ) {
							service.dependencies.forEach( dep => {
								this.selectedPackageServicesCounter[dep] = 1
							})
						}
					})
				} else if ( !pack ) {
				}

				this.selectedPackageServices = []
				const ids = Object.keys( this.selectedPackageServicesCounter )
				ids.forEach( serviceid => {
					if ( this.selectedPackageServicesCounter[serviceid] > 0 ) {
						this.selectedPackageServices.push( this.$store.state.services.find( serv => serv.id == serviceid ) )
					}
				})
			},
			serviceSelected( service ) {
				return this.selectedServices.indexOf( service ) >= 0 || this.selectedPackageServices.indexOf( service ) >= 0
			},
			packageSelected( pack ) {
				return this.selectedPackages.indexOf( pack ) >= 0
			},
			serviceSelectedFromPackage( service ) {
				return this.selectedPackageServices.indexOf( service ) >= 0
			},
			calendarGoLeft() {
				this.$refs.calendar.dateLeft()
				let weekStart = this.$refs.calendar.weekStart( new Date() )
				weekStart.setHours( 0 )
				weekStart.setMinutes( 0 )
				if ( dayjs( this.$refs.calendar.weekStart(this.$refs.calendar.date) ).isBefore(weekStart) ) {
					this.calendarCanGoLeft = false
				}
			},
			calendarGoRight() {
				this.$refs.calendar.dateRight()
				this.calendarCanGoLeft = true
			},
			updateCalendar() {
				this.updateSelWorker();

				if ( this.$refs.calendar ) {
					this.$refs.calendar.resetSelection()
					this.selectedDate = null
				}

				if ( this.customTimeline && this.customTimeline.length > 0 && this.customTimeline[0] !== null ) {
					this.timeline = this.customTimeline
				} else {
					let timeline = [ 0 ]
					let allServices = []
					let skipPackagesDuration = []
					this.allServicesIds = []
					if ( this.selectedServices.length > 0 ) {
						this.selectedServices.forEach( service => allServices[service.id] = true )
					}
					if ( !this.$store.getters.salonPrestationsHavePauses() ) {
						for ( let i = 0; i < this.selectedPackages.length; i++ ) {
							const duration = parseInt( this.selectedPackages[i]["dur_"+this.genderHair] )
							if ( !isNaN(duration) ) {
								timeline[0] += duration
								skipPackagesDuration.push( this.selectedPackages[i] )
							}
						}
					}
					console.log( "skipPackagesDuration", skipPackagesDuration )
					const skipServices = []
					for ( let i = 0; i < this.selectedPackages.length; i++ ) {
						if ( skipPackagesDuration.indexOf( this.selectedPackages[i] ) >= 0 ) {
							this.selectedPackages[i].services.forEach( serviceid => skipServices.push(serviceid) )
						} else {
							this.selectedPackages[i].services.forEach( serviceid => allServices[serviceid] = true )
						}
					}
					console.log( "this.selectedServices", this.selectedServices )
					console.log( "skipServices", skipServices )
					console.log( "allServices", allServices )
					if ( this.selectedPackageServices.length > 0 ) {
						this.selectedPackageServices.forEach( service => { if ( service && skipServices.findIndex(id => id == service.id) < 0 ) { allServices[service.id] = true } } )
					}
					allServices.forEach( ( _, id ) => this.allServicesIds.push(id) )
					this.allServicesIds = this.allServicesIds.filter( (id, i, self) => self.findIndex(x => x == id) == i )
					this.allServicesIds = this.allServicesIds.sort( ( a, b ) => this.$store.getters.getService(a).priority - this.$store.getters.getService(b).priority )
					for ( let i = 0; i < this.allServicesIds.length; i++ ) {
						let service = this.$store.getters.getService(this.allServicesIds[i])
						let service_timeline = service[(this.isAltTimeSelected(service.id, 'lighten') ? 'alt_' : '') + this.genderHair].slice( 2 )
						for ( let j = 0; j < service_timeline.length; j++ ) {
							timeline[timeline.length - 1] += service_timeline[j]
							if ( timeline.length % 2 == 0 || j + 1 < service_timeline.length ) {
								timeline.push( 0 )
							}
						}
					}
					this.timeline = timeline
				}
				this.insertionTimeline = computeBlocksTimeline( this.timeline, this.$store.state.config.block_size )

				this.showCalendar = true
				this.$nextTick( () => {
					this.$forceUpdate()
					if (!this.$refs.calendar) return
					this.$refs.calendar.workerChanged( this.edit ? ( this.edit.workerid || this.edit.workers[0] ) : this.$refs.workersDropdown.workerid )
				})
			},
			blockSelected( column ) {
				this.selectedDate = new Date( dayjs(column.date).format() )
				if ( this.showExplainOverlay && this.explainOverlayStep == 5 ) {
					this.explainOverlayStep = 6
					this.tempHideExplainOverlay = false
				}
			},
			take( callback ) {
				let data = {
					date: dayjs(this.selectedDate).toISOString(),
					userid: ( this.user ? this.user.id : null ),
					["gender" + (this.$store.state.domain == "esthetics" ? "_hair" : "")]: this.genderHair,
					services: this.allServicesIds,
					packages: this.selectedPackages.map( p => p.id ),
					workers: [ this.$refs.workersDropdown.workerid ],
					message: this.message,
					timeline: this.timeline,
					title: this.customTitle || this.selectedTitles,
					price: this.selectedPrice
				}
				if ( this.$store.state.domain == "esthetics" ) {
					data.alternatives = this.selectedAltTimes.filter(entry => entry.val === 'lighten').map(entry => entry.id)
				}
				if ( this.edit ) {
					data.date = ( this.selectedDate ? data.date : this.edit.date )
					data.userid = this.edit.userid
					if ( data.services.isSame( this.edit.services ) ) {
						// Dont reload title if services didnt change
						data.title = this.edit.title
					}
					let patch = {}
					Object.keys( data ).forEach( key => {
						if ( Array.isArray( data[key] ) ) {
							if ( !data[key].isSame( this.edit[key] ) ) {
								patch[key] = data[key]
							}
						} else if ( data[key] !== this.edit[key] && !( ( data[key] === null && this.edit[key] === undefined ) || ( data[key] === undefined && this.edit[key] === null ) ) ) {
							patch[key] = data[key]
						}
					})
					if ( Object.keys( patch ).length > 0 ) {
						this.$api.appointment.patch( this.edit.id, data ).then( response => {
							callback( true )
							setTimeout( () => {
								this.$emit( "confirm", { ...data, id: this.edit.id } )
							}, 1250 )
						}).catch( error => {
							callback( false, error.response.data.error )
						})
					}
				} else {
					this.$api.appointments.post( data ).then( response => {
						callback( true )
						setTimeout( () => {
							this.$emit( "confirm", { ...data, id: response.data[0].id } )
						}, 1250 )
					}).catch( error => {
						console.error( error )
						callback( false, error.response.data.error )
					})
				}
			},
			click( event ) {
				if ( event.target.getAttribute( "id" ) === "custom_title" ) {
					this.showTechPopper = true
				} else if ( !event.target.classList.contains( "dropdown-menu" ) && !event.target.classList.contains( "dropdown-item" ) ) {
					this.showTechPopper = false
				}
			}
		},
		async mounted() {
			if ( explainOverlay && document.documentElement.clientWidth >= 768 ) {
				explainOverlay = false
				this.showExplainOverlay = true
			}
			console.log( "edit", this.edit )
			if ( this.edit ) {
				// this.gender_hair = this.edit.gender_hair
				if ( this.edit.gender || this.edit.gender_hair ) {
					this.gender = this.edit.gender || this.edit.gender_hair.split( "_" )[0]
					this.hair = ( ( this.edit.gender_hair || "" ).split( "_" ) || [] )[1] || ''
					this.updateServices()
					this.updateCalendar()
					this.selectedDate = dayjs(this.edit.date, 'YYYY-MM-DD HH:mm', true)
					this.user = ( await this.$store.getters.getUser( this.edit.userid ) )
					if ( this.user && this.user.data ) {
						this.user = this.user.data[0]
					} else {
						this.user = null
					}
					this.message = this.edit.message
				}
				this.selectedAltTimes = this.altTimeServices.map(serv => ({ id: serv.id, val: this.edit.alternatives.includes(serv.id) ? 'lighten' : 'darken' }))
			} else {
				this.updateServices()
			}
			this.$nextTick( () => {
				this.loading = false
				this.$nextTick( () => {
					this.$forceUpdate()
				})
			})

			window.addEventListener( "click", this.click )
		},
		beforeDestroy() {
			window.removeEventListener( "click", this.click )
		}
	}
</script>

<style scoped lang="scss" src="../css/modals/newappointment.scss"/>
