import { render, staticRenderFns } from "./NewAppointmentSummary.vue?vue&type=template&id=743b5b24&scoped=true"
import script from "./NewAppointmentSummary.vue?vue&type=script&lang=js"
export * from "./NewAppointmentSummary.vue?vue&type=script&lang=js"
import style0 from "../css/modals/newappointmentsummary.scss?vue&type=style&index=0&id=743b5b24&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "743b5b24",
  null
  
)

export default component.exports