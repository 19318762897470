<template>
	<Popper
		id="search-popper"
		class="dropdown"
		trigger="clickToToggle"
		tagName="div"
		:options="{ placement: 'bottom' }"
		:visible-arrow="false"
		@show="searchResults.length ? $emit('dropdown-open') : $refs.searchDrop.doClose()"
		@hide="$emit('dropdown-close')"
		ref="searchDrop"
	>
		<div id="search-list">
			<div tag="div" v-for="result in searchResults" :key="result.id" class="block-infos" v-on:click="click( result )">
				<div class="result-names">{{ result.name }}</div>
				<div class="result-infos">{{ result.phone }} {{ result.email }}</div>
			</div>
			<div v-if="seeall === undefined || seeall == true" class="see-all" v-on:click="click( 'all' )">{{$t("common.seeall")}} </div>
		</div>
		<div
			id="search-box"
			class="input-group"
			slot="reference"
		>
			<input
				id="search-user"
				class="form-control"
				placeholder="Recherche client"
				size="1"
				type="text"
				ref="input"
				@input="searchUser( $event.target.value )"
			/>
			<div class="input-group-append">
				<i class="btn btn-secondary fas fa-search input-group-text"></i>
			</div>
		</div>
	</Popper>
</template>



<script>
	import Popper from "vue-popperjs";

	export default {
		props: [
			"seeall",
			"clearOnClick",
		],
		components: {
			Popper
		},
		data() {
			return {
				searchResults: []
			}
		},
		watch: {
			searchResults( newVal ) {
				if ( newVal.length > 0 ) {
					this.$refs.searchDrop.doShow()
					this.$emit( 'dropdown-open' )
				} else {
					this.$refs.searchDrop.doClose()
					this.$emit( 'dropdown-close' )
				}
			}
		},
		methods: {
			click( result ) {
				if ( this.clearOnClick ) {
					this.searchResults = []
				}
				this.$refs.searchDrop.doClose()
				this.$refs.input.value = ""
				this.$emit( "change", result )
			},
			searchUser( value ) {
				if ( value.length > 0 ) {
					var queries = {
						"lname[~*]": value,
						"fname[~*]": value,
						"email[~*]": value,
						"phone[~*]": value
					};
					this.$api.get( "/users/search", { "{or}": queries, "{max}": 5 }).then( response => {
						for ( var i = 0; i < response.data.length; i++ ) {
							var user = response.data[i];
							user.name = user.fname + " " + user.lname;
						}
						this.searchResults = response.data;
					}).catch( error => {
						this.searchResults = [];
						console.log( error );
					});
				} else {
					this.searchResults = [];
				}
			}
		}
	}
</script>

<style scoped lang="scss" src="../css/site/user-search-bar.scss"/>

