<template>
	<div :class="'btn-status-block' + ( hidden ? ' hidden' : '' )">
		<div v-if="alert !== 'modal'" :class="'alert-wrapper' + ( message !== '' ? ' visible' : '' )">
			<div :class="'alert alert-' + alertType">{{message}}</div>
		</div>
		<button :name="name" :disabled="enabled !== undefined && enabled == false" :type="attrType" ref="button" tabindex="0" :class="'btn btn-' + type + ' btn-status ' + currentClass + ( ( enabled !== undefined && enabled == false ) ? ' disabled' : '' )" v-on:click="clickWrapper">
			<span><slot/></span>
			<svg version="1.1" viewBox="0 0 130.2 130.2" xmlns="http://www.w3.org/2000/svg" xmlns:space="preserve" xmlns:xlink="http://www.w3.org/1999/xlink">
				<polyline class="path check" fill="none" stroke="#73AF55" stroke-width="24" stroke-linecap="round" stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 "></polyline>
				<line class="path line" fill="none" stroke="#D06079" stroke-width="24" stroke-linecap="round" stroke-miterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3"></line>
				<line class="path line" fill="none" stroke="#D06079" stroke-width="24" stroke-linecap="round" stroke-miterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2"></line>
			</svg>
			<div class="anim">
				<div class="duo duo1">
					<div class="dot dot-a"></div>
					<div class="dot dot-b"></div>
				</div>
				<div class="duo duo2">
					<div class="dot dot-a"></div>
					<div class="dot dot-b"></div>
				</div>
			</div>
		</button>
		<Modal v-if="alert === 'modal' && message !== ''" name="edit-service-alert" type="small" :above="true">
			<template v-slot:header>
				<h1 class="title-search">{{ $t('titles.error') }}</h1>
			</template>
			<template v-slot:content>
				<div class="alert">{{message}}</div>
			</template>
			<template v-slot:buttons>
				<button id="cancel" class="btn btn-gray" @click="message = ''" type="button">{{$t("common.close")}}</button>
			</template>
		</Modal>
	</div>
</template>


<script>
	import Modal from '../components/Modal.vue'

	export default {
		SUCCESS: true,
		ERROR: false,
		CANCEL: -1,
		components: {
			Modal
		},
		props: [
			"type",
			"enabled",
			"hidden",
			"onclick",
			"alert",
			"trigger",
			"speed",
			"attrType",
			"name",
		],
		data() {
			return {
				alertType: "danger",
				working: false,
				message: "",
				currentClass: "",
			}
		},
		methods: {
			reset() {
				this.message = ""
				this.working = false
				this.currentClass = ""
				this.alertType = "danger"
			},
			click() {
				this.clickWrapper()
			},
			clickWrapper() {
				if ( this.working || ( this.enabled !== undefined && this.enabled == false ) || this.trigger === "none" ) {
					return
				}
				this.message = ""
				this.working = true

				this.$refs.button.style["min-width"] = this.$refs.button.offsetWidth + "px"
				this.currentClass = "working"

				if ( this.timer !== undefined ) {
					clearTimeout( this.timer )
					this.timer = undefined
				}

				if ( this.onclick ) {
					this.onclick( this.clicked )
				} else {
					this.$emit( 'click', this.clicked )
				}
			},
			clicked( state, msg ) {
				this.message = msg || ""
				if ( this.message && this.message.length > 0 ) {
					if ( this.$te( this.message, "fr" ) ) {
						this.message = this.$t( this.message )
					}
					if ( this.$te( this.message, "en" ) ) {
						this.message = this.$t( this.message, "en" )
					}
					if ( this.$te( "errors." + this.message, "fr" ) ) {
						this.message = this.$t( "errors." + this.message )
					}
					if ( this.$te( "errors." + this.message, "en" ) ) {
						this.message = this.$t( "errors." + this.message, "en" )
					}
				}
				if ( state === "loading" || state === 0 ) { // Loading
					this.$refs.button && ( this.$refs.button.style["min-width"] = "" )
					this.currentClass = "working"
					this.working = false
					return
				}
				if ( state === "canceled" || state < 0 ) { // Canceled
					this.$refs.button && ( this.$refs.button.style["min-width"] = "" )
					this.currentClass = ""
					this.working = false
					return
				}

				var clazz = ( ( state === "success" || state === true ) ? "success" : "danger" )
				this.currentClass = clazz + " btn-" + clazz
				this.alertType = clazz

				let thiz = this
				if ( this.timer !== undefined ) {
					clearTimeout( this.timer )
					this.timer = undefined
				}
				this.timer = setTimeout( function() {
					if ( thiz.$refs && thiz.$refs.button ) {
						thiz.$refs.button.style["min-width"] = ""
					}
					thiz.currentClass = ""
				}, this.speed || 3000 )

				this.working = false
			}
		}
	}
</script>
