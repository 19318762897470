<template>
	<input
		:class="{ error }"
		v-model="val"
		@input="onInput"
		:disabled="disabled"
        :data-type="type"
	/>
</template>

<script>
const validators = {
	positiveReal: {
		validate: val => !!/^[+]?([0-9]*[.,])?[0-9]+$/.exec(val),
		parse: val => parseFloat(val),
		fromValue: val => String(val)
	},
	real: {
		validate: val => !!/^[+-]?([0-9]*[.,])?[0-9]+$/.exec(val),
		parse: val => parseFloat(val),
		fromValue: val => String(val)
	},
	positiveInt: {
		validate: val => !!/^[+]?[0-9]+$/.exec(val),
		parse: val => parseInt(val),
		fromValue: val => String(val)
	},
	int: {
		validatee: val => !!/^[+-]?[0-9]+$/.exec(val),
		parse: val => parseInt(val),
		fromValue: val => String(val)
	}
}

export default {
	props: {
		value: { default: null },
		type: {
			type: String,
			validator: val => Object.keys(validators).includes(val)
		},
        validate: { type: Function, default: null },
		nullable: { type: Boolean, default: false },
		disabled: { default: false }
	},
	data() {
		return {
			val:
				this.value === null ? "" : validators[this.type].fromValue(this.value),
			error: false
		}
	},
	methods: {
		onInput(ev) {
			const val = ev.target.value
			this.error = false

			if (this.nullable && val === "") {
				this.onValue(null)
				return
			}

			const validator = validators[this.type]
            const parsed = validator.parse(val)
            let err = false

            if (this.validate && !this.validate(parsed))
                err = true

			if (!err && validator.validate(val)) this.onValue(parsed)
			else this.onError()
		},
		onValue(val) {
            console.log('Value', val)
			this.$emit("input", val)
			this.$emit("valid", true)
		},
		onError() {
            console.log('Error')
			this.error = true
			this.$emit("error")
			this.$emit("valid", false)
		}
	},
	watch: {
		value(v) {
			this.val =
				this.value === null ? "" : validators[this.type].fromValue(this.value)
		}
	}
}
</script>
