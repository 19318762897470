export function recurseDependencies( salonServices, service, total ) {
	total = total || []
	for ( const depId of service.dependencies ) {
		if ( !total.find( s => s.id == depId ) ) {
			const dep = salonServices.find( s => s.id == depId )
			if ( dep ) {
				total.push( { ...dep, isDependency: true } )
				if ( dep.dependencies ) {
					recurseDependencies( salonServices, dep, total )
				}
			}
		}
	}
	return total
}

export function recurseAllDependencies( salonServices, services ) {
	let total = []
	services.forEach( service => {
		if ( typeof service != "object" ) {
			total.push( salonServices.find( s =>s.id == service ) )
		} else {
			total.push( service )
		}
	})
	const base = [ ...total ]
	base.forEach( service => {
		recurseDependencies( salonServices, service, total )
	})
	return total
}


export function computeServices( salonServices, services, package_, getDependencies = true ) {
	const total = []

	if ( services ) {
		for ( const serv of services ) {
			const id = parseInt( serv )
			if ( isNaN(id) ) {
				total.push( { ...serv } )
			} else {
				total.push( { ...( salonServices.find( s => s.id == id ) || {} ) } )
			}
		}
	}

	const handlePackage = (pack) => {
		if ( pack && pack.services ) {
			for ( const serv of pack.services ) {
				const id = parseInt( serv )
				if ( isNaN(id) ) {
					total.push( { ...serv } )
				} else {
					total.push( { ...( salonServices.find( s => s.id == id ) || {} ) } )
				}
			}
		}
	}

	if ( package_ ) {
		if ( Array.isArray(package_) ) {
			package_.forEach( pack => handlePackage( pack ) )
		} else {
			handlePackage( package_ )
		}
	}

	if (getDependencies) {
		for ( const serv of total ) {
			if ( serv.dependencies ) {
				recurseDependencies( salonServices, serv, total )
			}
		}
	}

	const ret = total.filter( (service, i, self) => self.findIndex(s => s.id == service.id) == i )
	return ret
}


export function computeBlocksTimeline( timeline, blockSize ) {
	console.log( "computeBlocksTimeline", timeline, blockSize )
	let minutesTimeline = []
	let blocksTimeline = []

	
	for ( let i = 0; i < timeline.length; i++ ) {
		const active = ( i % 2 == 0 )
		minutesTimeline.push( ...new Array(timeline[i]).fill(active) )
	}
	for ( let i = 0; i < minutesTimeline.length; i += blockSize ) {
		blocksTimeline.push( minutesTimeline.slice( i, i + blockSize ).some( x => !!x ) )
	}

	return blocksTimeline

/*
	let blocksTimeline = []
	let t = 0
	let max = 0

	for ( let i = 0; i < timeline.length; i++ ) {
		let active = ( i % 2 == 0 )
		let mathFunc = ( active ? Math.floor : Math.ceil )
		for ( let j = 0; j < Math.floor( timeline[i] / blockSize + 0.5 ); j++ ) {
			const pos = mathFunc(t / blockSize) + j
			max = Math.max( pos, max )
			blocksTimeline[pos] = active
		}
		t += timeline[i]
	}
	console.log( "computeBlocksTimeline before", blocksTimeline )
	for ( let i = 0; i < max; i++ ) {
		console.log( i, blocksTimeline[i] )
		if ( blocksTimeline[i] !== true && blocksTimeline[i] !== false ) {
			blocksTimeline[i] = false
		}
	}
//	blocksTimeline.splice( max )
	blocksTimeline.flat()
	if ( t % blockSize != 0 && timeline.length % 2 == 1 ) {
	//	blocksTimeline.push( true )
	}

	return blocksTimeline
*/
}
