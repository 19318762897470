<template>
	<table :class="`coif-calendar${ allowInsertion ? ' insertion': '' }${ inspecting ? ' inspecting': '' }`" ref="table">
		<thead id="calendar-head" :class="{ fixed: fixedHeader }" :style="{ width: headWidth }" ref="head">
			<tr>
				<th>Heure</th>
				<th v-for="( day, index ) in days.filter((_, index) => index < daysCount)" :key="index" :class="{ closed: day.closed && daysCount > 1, today: day.date.isAfter(today()) && day.date.isBefore(today().add(1,'day')) }">
					{{ day.text }}
				</th>
			</tr>
		</thead>
		<tbody @mouseleave="$refs.insertionHoverWrapper ? ( $refs.insertionHoverWrapper.style.display = 'none' ) : false">
			<tr v-for="( row, index ) in rows.filter(row => !row.skip)" :key="row.id" :class="index % 2 == 0 ? 'hrow' : ''">
				<th :class="index % 2 == 0 ? 'hour-th' : 'hidden-th'">
					<div v-if="index % 2 == 0" class="hour">{{ row.hour }}</div>
				</th>
				<CalendarBlock
					v-for="( column, colIndex ) in row.columns"
					:key="column.id"
					v-show="colIndex < daysCount"
					:ref="column.id"
					:workerid="workerid"
					:groupid="groupid"
					:hour="row.hour"
					:id="uid + '_' + column.id"
					:class="{
						selectable: column.selectable,
						overflow: column.overflow,
						forbid: column.forbid,
						past: column.past,
						today: column.today && !allowInsertion,
						closed: column.closed && daysCount > 1,
						cannot: allowInsertion && column.cannot
					}"
					:data-calendar-slot="true"
					:data-date="column.date"
					:data-day="column.day"
					:data-text="row.hour"
					:data-selectable="column.selectable"
					:data-overflow="column.overflow"
					:data-forbid="column.forbid"
					:data-past="column.past"
					:data-today="column.today && !allowInsertion"
					:data-closed="column.closed && daysCount > 1"
					:data-cannot="allowInsertion && column.cannot"
					@hover="( allowInsertion && !column.touched ) ? hoverCell( $event ) : false"
					@leave="column.touched = false; allowInsertion ? leaveCell( $event ) : false"
					@touchstart="column.touched = true"
					@click="allowInsertion ? selectedCell( $event, column ) : false"
				/>
			</tr>
			<tr ref="insertionHoverWrapper" class="insertion-hover">
				<CalendarBlock
					v-if="allowInsertion"
					ref="insertionHover"
					:workerid="-1"
				/>
			</tr>
			<tr ref="insertionSelectWrapper" class="insertion-hover">
				<CalendarBlock
					v-if="allowInsertion"
					v-show="insertedDate && showInsertedDate"
					ref="insertionSelect"
					:workerid="-1"
					:hour="insertedHour"
				/>
			</tr>
		</tbody>
		<Popper
			v-if="!allowInsertion"
			class="calendar-event-dialog"
			ref="eventPopper"
			trigger="clickToOpen"
			:force-show="forceShowPopper || popperShow"
			:visible-arrow="false"
			@show="currentlyDetailedVisible = true; inspecting = true"
			@hide="currentlyDetailedVisible = false; inspecting = false"
		>
			<div v-show="currentlyDetailed" class="card">
				<div class="card-body">
					<h6 class="card-subtitle mb-2 text muted">{{ dayjs((currentlyDetailed||{}).date).format("dddd DD MMMM - HH:mm") }}</h6>
					<div class="block-app">
						<span class="app-title">
							{{ (currentlyDetailed||{}).title }}
						</span>
						<span class="app-details">
							{{ $t('common.for') }}
							<i v-if="currentlyDetailedUser && !!currentlyDetailedUser.unregistration" class="font-weight-bold">
								{{ '[' + $tc('common.accdel') + ']' + ' ' }}
							</i>
							<router-link v-else-if="currentlyDetailedUser" :to="'/user?id=' + currentlyDetailedUser.id">{{ currentlyDetailedUser.lname + " " + currentlyDetailedUser.fname }}</router-link>
							<i v-else class="font-weight-bold">
								{{ $tc('common.anonymous') + ' ' }}
							</i>
							{{ $t('common.with') }}
							<router-link :to="'/worker?id=' + (currentlyDetailed||{}).workerid">
								<b>{{ $store.getters.getWorker( (currentlyDetailed||{}).workerid ).name }}</b>
							</router-link>
						</span>
					</div>
					<div v-if="(currentlyDetailed||{}).status && (currentlyDetailed||{}).status.search('canceled') >= 0" class="canceled">
						{{ $t('common.cancel') }}
					</div>
					<div class="stateapp statefinish" v-else-if="(currentlyDetailed||{}).status && (currentlyDetailed||{}).status.indexOf('finished')>=0">{{ $t('index.fini') }}</div>
					<div class="stateapp" v-else-if="(currentlyDetailed||{}).status && (currentlyDetailed||{}).status.indexOf('absent')>=0">{{ $t('common.absent') }}</div>
					<div class="stateapp" v-else-if="dayjs((currentlyDetailed||{}).date).isBefore(today())" >{{ $t('common.missed') }}</div>
					<span v-if="(currentlyDetailed||{}).message" class="app-detail-message">
						« {{ (currentlyDetailed||{}).message.slice(0, 256) + ((currentlyDetailed||{}).message.length > 256 ? '...' : '') }} »
					</span>
				</div>
			</div>
			<button
				id="event-toggler"
				slot="reference"
				ref="eventToggler"
			></button>
		</Popper>
		<Confirm v-if="allowEditing && showDeleteConfirm" @confirm="deleteAppointment( showDeleteConfirm.id, showDeleteConfirm.callback )" @close="showDeleteConfirm.callback( -1 ); forceShowPopper = false; showDeleteConfirm = false" ref="statusConfirm">
			{{ $t('appointments.confirmcancel') }}
		</Confirm>
		<!--NewAppointment v-if="showEditAppointmentModal" @confirm="showEditAppointmentModal=false; forceShowPopper=false; currentlyDetailed=null" @close="showEditAppointmentModal=false; forceShowPopper=false" :edit="showEditAppointmentModal" name="newAppointment"/-->
		<div v-show="currentlyDetailedVisible" class="edit-buttons">
			<!--button @click="showEditAppointmentModal = currentlyDetailed" class="btn btn-outline-gray" type="button">{{$t("common.mod")}}</button-->
			<button
				class="btn btn-outline-gray"
				type="button"
				:class="{ disabled: !!(currentlyDetailed||{}).status }"
				@click="!(currentlyDetailed||{}).status && $emit( 'edit', currentlyDetailed )"
			>{{$t("common.mod")}}</button>
			<StatusButton
				@click="forceShowPopper = true; showDeleteConfirm = { id: (currentlyDetailed||{}).id, callback: $event };"
				alert="modal"
				:enabled="!(currentlyDetailed||{}).status"
				type="outline-danger"
				class="cancelbutton"
			>{{$t("common.cancel")}}</StatusButton>
			<!-- TBD div class="card-link" href="#"></div-->
		</div>
	</table>
</template>



<script>
	import Vue from 'vue'
	import Color from 'color'
	import dayjs from 'dayjs'
	import Popper from 'vue-popperjs'
	import CalendarBlock from './CalendarBlock.vue'
	import StatusButton from './StatusButton.vue'
	import Confirm from '../modals/Confirm.vue'
//	import NewAppointment from '../modals/NewAppointment.vue'
	import calendarWorker from 'worker-loader!../calendarWorker.js'
	let CalendarBlockClass = Vue.extend( CalendarBlock )

	export default {
		components: {
			Confirm,
			CalendarBlock,
			StatusButton,
//			NewAppointment: NewAppointment,
			Popper
		},
		props: [
			"allowEditing",
			"allowInsertion",
			"insertionTimeline",
			"fixedHeader",
			"baseDate",
			"editing",
			"baseDaysCount",
		],
		data() {
			return {
				alert: ( xx ) => { window.alert(xx) },
				dayjs: dayjs,
				daysCount: this.baseDaysCount || 7,
				date: this.baseDate ? this.weekStart( this.baseDate ) : null,
				openIdx: 0,
				workerid: 0,
				groupid: 0,
				days: [],
				rows: [],
				appointments: [],
				headWidth: "10px",
				perfCounter: 0,
				isBuilding: false,
				forceShowPopper: false,
				popperShow: false,
				inspecting: false,
				currentlyDetailed: null,
				currentlyDetailedUser: null,
				currentlyDetailedVisible: false,
				showDeleteConfirm: false,
				showEditAppointmentModal: false,
				insertedDate: null,
				insertedHour: "",
				showPastAppointments: this.$store.state.worker.show_past_appointments,
				uid: Math.random().toString(10).substring(0,6)
			}
		},
		computed: {
			showInsertedDate() {
				let weekStart = this.weekStart( this.date )
				weekStart.setHours( 0 )
				weekStart.setMinutes( 0 )
				let weekEnd = weekStart.addDays( this.daysCount )
				if ( this.insertedDate && dayjs(this.insertedDate).isAfter(weekStart) && dayjs(this.insertedDate).isBefore(weekEnd) ) {
					return true
				}
				return false
			}
		},
		watch: {
			insertionTimeline( value ) {
				if ( this.$refs.insertionHover ) {
					const hoverColor = Color( this.$store.getters.getWorker( this.workerid ).color ).saturate( 0.25 ).alpha( 0.4 ).toString()
				//	const selectedColor = Color( this.$store.getters.getWorker( this.workerid ).color ).saturate( 0.25 ).lighten( 0.1 ).toString()
					const selectedColor = this.$store.getters.getWorker( this.workerid ).color
					this.$refs.insertionHover.addAppointment({
						id: -1,
						color: hoverColor,
						width: "100%",
						widthForWorker: "100%",
						height: ( this.insertionTimeline.length * 1.3 ) + "rem",
						views: [{ style: { background: hoverColor, "min-height": ( this.insertionTimeline.length * 1.3 ) + "rem" }, class: "appointment-back" }]
					})
					this.$refs.insertionSelect.addAppointment({
						id: -1,
						color: selectedColor,
						width: "100%",
						widthForWorker: "100%",
						height: ( this.insertionTimeline.length * 1.3 ) + "rem",
						views: [{ style: { background: selectedColor, "min-height": ( this.insertionTimeline.length * 1.3 ) + "rem" }, class: "appointment-back" }]
					})
				}
			},
			'$route'( value ) {
				if ( value.path.indexOf( "/appointments" ) == 0 ) {
					this.$nextTick( () => {
						this.resize()
					})
				}
			}
		},
		methods: {
			resetSelection() {
				this.insertedDate = null
				this.insertedHour = ""
				this.$refs.insertionSelectWrapper.style.display = ""
			},
			weekStart( date ) {
				if ( !this.daysCount || this.daysCount == 7 ) {
					while ( date.getDay() != 0 ) {
						date = date.addDays( -1 )
					}
				}
				return date
			},
			today() {
				return (dayjs().hour(0).minute(0))
			},
			deleteAppointment( id, callback ) {
				this.$api.appointment.patch( id, { status: "canceled" } ).then( response => {
					callback( true )
					this.showDeleteConfirm = false
					setTimeout( () => {
						this.forceShowPopper = false
					}, 500 )
				}).catch( error => {
					callback( false, error.response.data.error )
				})
			},
			workerChanged( id ) {
				this.groupid = 0
				this.workerid = id
				this.rebuildSchedule()
				if ( this.$refs.insertionHover ) {
					const hoverColor = Color( this.$store.getters.getWorker( this.workerid ).color ).saturate( 0.25 ).alpha( 0.4 ).toString()
				//	const selectedColor = Color( this.$store.getters.getWorker( this.workerid ).color ).saturate( 0.25 ).lighten( 0.1 ).toString()
					const selectedColor = this.$store.getters.getWorker( this.workerid ).color
					this.$refs.insertionHover.addAppointment({
						id: -1,
						color: hoverColor,
						views: [{ style: { background: hoverColor, "min-height": ( this.insertionTimeline.length * 1.345 ) + "rem" }, class: "appointment-back" }]
					})
					this.$refs.insertionSelect.addAppointment({
						id: -1,
						color: selectedColor,
						views: [{ style: { background: selectedColor, "min-height": ( this.insertionTimeline.length * 1.345 ) + "rem" }, class: "appointment-back" }]
					})
				}
			},
			groupChanged( id ) {
				this.workerid = 0
				this.groupid = id
				this.rebuildSchedule()
			},
			daysChanged( count ) {
				this.currentlyDetailedVisible = false
				this.inspecting = false
				this.forceShowPopper = false
				this.daysCount = count
				this.updateSchedule()
				if ( count > 1 ) {
					this.updateAppointments()
				}
			},
			dateLeft() {
				this.currentlyDetailedVisible = false
				this.inspecting = false
				this.forceShowPopper = false
				this.currentlyDetailed = null
				this.date = this.date.addDays( -this.daysCount )
				this.clearAppointments()
				this.updateSchedule()
				this.updateAppointments()
			},
			dateRight() {
				this.currentlyDetailedVisible = false
				this.inspecting = false
				this.forceShowPopper = false
				this.currentlyDetailed = null
				this.date = this.date.addDays( this.daysCount )
				this.clearAppointments()
				this.updateSchedule()
				this.updateAppointments()
			},
			dateString( date ) {
				let dd = String(date.getDate());
				let mm = String(date.getMonth() + 1); //January is 0!
				if ( dd.length == 1 ) {
					dd = "0" + dd;
				}
				if ( mm.length == 1 ) {
					mm = "0" + mm;
				}
				let yyyy = date.getFullYear();
				return yyyy + '-' + mm + '-' + dd;
			},
			dayString( date ) {
				let day = ['dim.', 'lun.', 'mar.', 'mer.', 'jeu.', 'ven.', 'sam.'][date.getDay()];
				let month = ['jan.', 'févr.', 'mars', 'avr.', 'mai', 'juin', 'juill.', 'août', 'sept.', 'oct.', 'nov.', 'déc.'][date.getMonth()];
				return day + " " + date.getDate() + " " + month;
			},
			hourString( hour, pad ) {
				let h = Math.floor( hour )
				let m = Math.floor( ( hour - h ) * 60 + 0.5 )
				if ( pad ) {
					if ( h < 10 ) { h = "0" + h }
					if ( m < 10 ) { m = "0" + m }
				}
				return h + ":" + m
			},
			hourNumber( str ) {
				if ( typeof str === "string" ) {
					let matches = str.match( /(\d*):(\d*)/ )
					if ( matches != null ) {
						return parseInt( matches[1] ) + ( parseInt( matches[2] ) / 60.0 )
					} else {
					//	return parseInt( str ) / 60.0
					}
				}
				if ( typeof str === "number" ) {
					return str / 60
				}
				return null
			},
			updateSchedule( generalSched, workerSched ) {
				this.rebuildSchedule( generalSched, workerSched )
			},
			rebuildSchedule( generalSched, workerSched ) {
				console.log( "rebuildSchedule", generalSched, workerSched )
				console.log( "this.daysCount", this.daysCount, this.date )
				if ( this.date == null ) {
					if ( this.daysCount == 1 ) {
						this.date = new Date()
					} else {
						this.date = this.weekStart( new Date() )
					}
				}
				console.log( "this.date", this.date )
				this.date.setHours( 0 )
				this.date.setMinutes( 0 )
				this.date.setSeconds( 0 )
				let today = dayjs( this.date )

				// Get worker schedule (or general if no worker specified)
				let schedules = ( generalSched || this.$store.getters.getSchedules() ).sort( ( a, b ) => a.id - b.id )
				console.log("schedules", schedules)
				console.log("workerid", this.workerid)
				if ( this.workerid > 0 ) {
					let sched = ( workerSched || ( this.$store.getters.getWorker( this.workerid ) || {} ).schedule )
					if ( sched && sched.length > 0 ) {
						let worker_schedules = []
						for ( let i = 0; i < sched.length; i++ ) {
							let dayIdx = sched[i][0] - 1
							let day = {}
							if ( sched[i].length == 1 || ( sched[i][1] === null && sched[i][2] === null && sched[i][3] === null && sched[i][4] === null ) ) {
								day = schedules[dayIdx]
							} else {
								day = {
									morningop: sched[i][1],
									morningclos: sched[i][2],
									afternop: sched[i][3],
									afternclos: sched[i][4],
								}
								if ( day.morningop === null ) {
									day.morningop = schedules[dayIdx].morningop
								}
								if ( day.morningclos === null ) {
									day.morningclos = schedules[dayIdx].morningclos
								}
								if ( day.afternop === null ) {
									day.afternop = schedules[dayIdx].afternop
								}
								if ( day.afternclos === null ) {
									day.afternclos = schedules[dayIdx].afternclos
								}
								console.log("day", dayIdx, ": ", day)
							}
							worker_schedules[dayIdx] = day
						}
						for ( let i = 0; i < 7; i++ ) {
							worker_schedules[i] = worker_schedules[i] || {}
						}
						schedules = worker_schedules.sort( ( a, b ) => a.id - b.id )
					}
				}

				// Calculate schedule
				let blockSize = this.$store.state.config.block_size
				let openIdx = 1000
				let closeMorningIdx = 0
				let openAfternoonIdx = 1000
				let closeIdx = 0
				let now = new Date()
				let nowIdx = Math.floor( ( now.getHours() * 60 + now.getMinutes() ) / blockSize + 0.5 )
				for ( let i = 0; i < schedules.length; i++ ) {
					schedules[i].morningop = this.hourNumber( schedules[i].morningop )
					schedules[i].morningclos = this.hourNumber( schedules[i].morningclos )
					schedules[i].afternop = this.hourNumber( schedules[i].afternop )
					schedules[i].afternclos = this.hourNumber( schedules[i].afternclos )
					schedules[i].morningopIdx = schedules[i].morningop * 60 / blockSize
					schedules[i].morningclosIdx = schedules[i].morningclos * 60 / blockSize
					schedules[i].afternopIdx = schedules[i].afternop * 60 / blockSize
					schedules[i].afternclosIdx = schedules[i].afternclos * 60 / blockSize
					if ( schedules[i].morningop != null && schedules[i].morningop !== 0 ) {
						openIdx = Math.min( openIdx, schedules[i].morningopIdx || 24 )
					}
					if ( schedules[i].morningclos != null ) {
						closeMorningIdx = Math.max( closeMorningIdx, schedules[i].morningclosIdx || 0 )
					}
					if ( schedules[i].afternop != null ) {
						openAfternoonIdx = Math.min( openAfternoonIdx, schedules[i].afternopIdx || 24 )
					}
					if ( schedules[i].afternclos != null ) {
						closeIdx = Math.max( closeIdx, schedules[i].afternclosIdx || 0 )
					}
				}
				closeIdx = Math.max( closeIdx, closeMorningIdx )

				// Set headers titles
				for ( let day = 0; day < this.daysCount; day++ ) {
					let date = this.date.addDays( day )
					let dayOfWeek = date.getDay() - 1
					let sched = schedules[dayOfWeek < 0 ? 6 : dayOfWeek]
					let open = ( sched.morningop && sched.morningclos ) || ( sched.afternop && sched.afternclos )
					this.$set( this.days, day, {
						date: dayjs(date).add( 1, "minute" ),
						text: this.dayString( date ),
						dayOfWeek: dayOfWeek < 0 ? 6 : dayOfWeek,
						closed: !open,
						endTime: Math.max( sched.morningclos, sched.afternclos )
					})
				}

				const realOpenIdx = openIdx
				const realCloseIdx = closeIdx
				let overflowOpen = 0
				let overflowClose = 0
				if ( this.workerid > 0 ) {
					const worker = this.$store.getters.getWorker( this.workerid )
					openIdx -= worker.overflow_open / blockSize;
					closeIdx += worker.overflow_close / blockSize;
					overflowOpen = worker.overflow_open / blockSize
					overflowClose = worker.overflow_close / blockSize
				} else {
					const overflow_open = this.$store.state.workers.reduce( ( acc, w ) => Math.max( acc, w.overflow_open ), 0 )
					const overflow_close = this.$store.state.workers.reduce( ( acc, w ) => Math.max( acc, w.overflow_close ), 0 )
					openIdx -= overflow_open / blockSize;
					closeIdx += overflow_close / blockSize;
					overflowOpen = overflow_open / blockSize
					overflowClose = overflow_close / blockSize
				}

				console.log("openIdx", openIdx, "closeIdx", closeIdx, "realOpenIdx", realOpenIdx, "realCloseIdx", realCloseIdx, "overflowOpen", overflowOpen, "overflowClose", overflowClose)
				for ( let hourIdx = openIdx; hourIdx < closeIdx; hourIdx++ ) {
					// Skip lunch time, but keep a 30 minutes intermediate gray zone
					let hour = Math.floor( hourIdx * blockSize / 60 ) + ( hourIdx * blockSize % 60 ) / 60
					this.rows[ hourIdx - openIdx ] = this.rows[ hourIdx - openIdx ] || {}
					let row = this.rows[ hourIdx - openIdx ]
					row.id = hourIdx
					row.skip = false
					row.hour = this.hourString( hour, true )
					row.columns = row.columns || []
					for ( let day = 0; day < this.daysCount; day++ ) {
						let sched = schedules[this.days[day].dayOfWeek]
						let open = ( sched.morningop && sched.morningclos ) || ( sched.afternop && sched.afternclos )
						let selectable = ( hourIdx >= sched.morningopIdx && hourIdx < sched.morningclosIdx ) || ( hourIdx >= sched.afternopIdx && hourIdx < sched.afternclosIdx )
						/*
						if ( this.allowInsertion && ( hourIdx < realOpenIdx || hourIdx >= realCloseIdx ) ) {
							selectable = true
						}
						*/
						const overflow = ( ( hourIdx >= sched.morningopIdx - overflowOpen && hourIdx < sched.morningopIdx ) || ( hourIdx >= sched.afternclosIdx && hourIdx < sched.afternclosIdx + overflowClose ) )
						row.columns[day] = row.columns[day] || {}
						let column = row.columns[day]
						column.id = "d" + ( day + 1 ) + '_h' + hourIdx
						column.closed = !open
					//	column.selectable = ( day == 0 && hourIdx <= nowIdx ) ? false : selectable
						column.past = dayjs(this.date.addDays(day)).set('hour',parseInt(hour)).set('minute',(hour - parseInt(hour)) * 60).isBefore(new Date())
						column.selectable = column.past ? false : ( selectable || ( this.allowInsertion && overflow ) )
						column.overflow = overflow
						column.forbid = !column.selectable //!selectable
						column.today = ( dayjs(this.date.addDays( day )).format("YYYY-MM-DD") == dayjs().format("YYYY-MM-DD") )
						if ( column.today ) {
							const nowTime = dayjs().hour() * 60 + dayjs().minute()
							if ( nowTime >= this.days[day].endTime * 60 ) {
								column.today = false
							}
						}
						column.day = this.dayString( this.date.addDays( day ) )
						column.date = dayjs( this.date.addDays( day ) ).format( 'YYYY-MM-DD ' ) + row.hour
						if ( this.allowInsertion && this.insertionTimeline ) {
							column.cannot = false
							if ( hourIdx + this.insertionTimeline.length > closeIdx ) {
								column.cannot = true
							}
							if ( !column.selectable ) {
								for ( let k = 0; k < this.insertionTimeline.length; k++ ) {
									let idx = hourIdx - openIdx - k
									if ( idx < 0 ) {
										break
									}
									this.rows[idx].columns[day].cannot = true
								}
							}
						}
					}
				}
				for ( let hourIdx = closeIdx; this.rows[hourIdx - openIdx]; hourIdx++ ) {
				//	console.log( "closing", hourIdx )
					for ( let day = 0; day < this.daysCount; day++ ) {
						/*
						if ( this.workerid > 0 && !generalSched ) { // If generalSched exists, this means that general schedules have moved, so we have to delete rows instead of greying them
							this.rows[hourIdx - openIdx].columns[day].selectable = false
							this.rows[hourIdx - openIdx].columns[day].forbid = true
						} else {
							*/
							delete this.rows[hourIdx - openIdx]
						//}
					}
				}

				this.openIdx = openIdx
				if ( this.insertionTimeline && this.appointments && this.appointments.length > 0 ) {
					this.appointments.forEach( app => {
						if ( !this.editing || app.id != this.editing.id ) {
							this.lockPlace( app )
						}
					})
				}
			},
			lockPlace( appointment ) {
				for ( let i = 0; i < appointment.blocksTimeline.length; i++ ) {
					if ( appointment.workerid === this.workerid && appointment.blocksTimeline[i] ) {
						const hourIdx = appointment.hourIdx
						let row = this.rows[ hourIdx + i - this.openIdx ]
						if ( row ) {
							let column = row.columns[ appointment.dayIdx ]
							if ( column ) {
								column.cannot = true
							}
						}
						if ( ( i === 0 || !appointment.blocksTimeline[i - 1] ) && this.insertionTimeline ) {
							for ( let j = i - 1; hourIdx + j > hourIdx - this.insertionTimeline.length; j-- ) {
								let idx = ( hourIdx + j ) - this.openIdx
								if ( idx < 0 ) {
									break
								}
								let cannot = false
								for ( let k = 0; k < this.insertionTimeline.length; k++ ) {
									if ( this.insertionTimeline[k] && appointment.blocksTimeline[j + k] ) {
										cannot = true
										break
									}
								}
								if ( cannot ) {
									this.rows[idx].columns[appointment.dayIdx].cannot = true
								}
							}
							/*
							for ( let k = 1; k < this.insertionTimeline.length; k++ ) {
								let idx = ( hourIdx + i - k ) - this.openIdx
								if ( idx < 0 ) {
									break
								}
								this.rows[idx].columns[appointment.dayIdx].cannot = true
							}
							*/
						}
					}
				}
			},
			clearAppointments( noWait ) {
				let thiz = this
				function wait() {
					if ( !noWait && thiz.isBuilding ) {
						setTimeout( wait, 40 )
					} else {
						thiz.isBuilding = true
						thiz.perfCounter = performance.now()
						for ( let i = 0; i < thiz.appointments.length; i++ ) {
							let appointment = thiz.appointments[i]
							if ( appointment ) {
								let id = "d" + ( appointment.dayIdx + 1 ) + '_h' + appointment.hourIdx
								if ( thiz.$refs[ id ] && thiz.$refs[ id ][0] ) {
									thiz.$refs[ id ][0].clearAppointments()
								}
							}
						}
						thiz.appointments = []
						thiz.isBuilding = false
					}
				}
				wait()
			},
			updateAppointments() {
				let thiz = this
				function wait() {
					if ( thiz.isBuilding ) {
						setTimeout( wait, 40 )
					} else {
						thiz.isBuilding = true
						thiz.clearAppointments( true )
						let minDate = thiz.dateString( thiz.date )
						if ( !thiz.showPastAppointments && dayjs(thiz.date).isBefore(dayjs()) ) {
							minDate = dayjs().format( "YYYY-MM-DD 00:00" )
						}
						let queries = {
							"date[gte]": minDate,
							"date[lt]": thiz.dateString( thiz.date.addDays( thiz.daysCount ) ),
							"{sort}": "asc:date"
						}
						thiz.$api.appointments.get( queries ).then( response => {
							response.data = ( response.data || [] ).filter( app => ( app.status === null || app.status.toLowerCase().indexOf("canceled") < 0 ) )
							thiz.workerThread.postMessage( {
								action: "updateAppointments",
								blockSize: thiz.$store.state.config.block_size,
								workers: thiz.$store.getters.getWorkers(),
								daysCount: thiz.daysCount,
								date: thiz.date,
								appointments: response.data,
							})
						}).catch(error => {
							thiz.isBuilding = false
							console.log(error)
						})
					}
				}
				wait()
			},
			resize() {
				/*
				if ( this.fixedHeader ) {
					let table = $(this.$refs.table)
					let head = $(this.$refs.head)
					let headers = head.find("th")
					let firstRow = table.find("tbody").find("tr").first()
					this.headWidth = table.outerWidth() + "px"
					let i = 0
					let lastWidth = 0
					firstRow.find("th, td").each( function() {
						lastWidth = $(this).outerWidth()
						$(headers[i]).css( "width", lastWidth )
						i++
					})
				}
				*/
				const colWidth = [ ...this.$refs.head.children[0].children ].find( ( th, i ) => i > 0 && !th.classList.contains("closed") ).offsetWidth
				if ( this.$refs.insertionHover ) {
					this.$refs.insertionHoverWrapper.style.width = colWidth + "px"
				}
				if ( this.$refs.insertionSelect ) {
					this.$refs.insertionSelectWrapper.style.width = colWidth + "px"
				}
			},
			clicked( event ) {
				$( ".appointment" ).removeClass( "inspecting" )
				let elem = $( event.target );
				let i = 0;
				while ( i < 2 && !elem.hasClass( "appointment" ) ) {
					elem = elem.parent();
					i++;
				}
				if ( elem.hasClass( "appointment" ) ) {
					let id = elem.attr( "data-id" );
					let app = null;
					for ( let i = 0; i < this.appointments.length; i++ ) {
						if ( id == this.appointments[i].id ) {
							app = this.appointments[i];
							break;
						}
					}
					if ( app ) {
						event.stopPropagation()
						this.popperShow = true
						this.currentlyDetailedUser = null
						this.currentlyDetailed = app
						elem.addClass( "inspecting" )
						$(this.$refs.eventToggler).css( 'left', event.pageX - 1 + "px" )
						$(this.$refs.eventToggler).css( 'top', event.pageY - 1 + "px" )
						this.$nextTick( () => {
							setTimeout( () => {
								this.$store.getters.getUser( this.currentlyDetailed.userid ).then( response => {
									this.currentlyDetailedUser = response && response.data[0]
								}).catch( error => console.log( error ) )
							}, 100 )
						})
						return false
					}
				} else {
					this.popperShow = false
				}
			},
			clearInsertion() {
				this.$refs.insertionSelectWrapper.style.top = ""
				this.$refs.insertionSelectWrapper.style.left = ""
				this.$refs.insertionSelectWrapper.style.display = ""
			},
			hoverCell( event ) {
				const base_id = event.target.id.substr( 0, event.target.id.indexOf( "h" ) + 1 )
				let base_id_x = parseInt( event.target.id.substr( event.target.id.indexOf( "h" ) + 1 ) )
				let dest = null
				
				for ( let i = 0; i < this.insertionTimeline.length; i++ ) {
					let elem = document.getElementById( base_id + ( base_id_x - i ) )
					if ( elem && !elem.classList.contains( "cannot" ) ) {
						dest = elem
						break
					}
				}

				if ( !dest ) {
					return true
				}

				this.$refs.insertionHoverWrapper.style.top = dest.offsetTop + "px"
				this.$refs.insertionHoverWrapper.style.left = dest.offsetLeft + "px"
				this.$refs.insertionHoverWrapper.style.display = "block"

				return true
			},
			leaveCell( event ) {
				/*
				if ( event.target.classList.contains( "selectable" ) ) {
					const base_id = event.target.id.substr( 0, event.target.id.indexOf( "h" ) + 1 )
					let base_id_x = parseInt( event.target.id.substr( event.target.id.indexOf( "h" ) + 1 ) )
					for ( let i = 0; i < this.insertionTimeline.length; i++ ) {
						let elem = document.getElementById( base_id + ( base_id_x + i ) )
						if ( elem && !elem.classList.contains( "selected" ) ) {
							elem.style['background-color'] = "";
						}
					}
				}
				*/
			},
			selectedCell( event, column, isTouch ) {
				column.touched = false
				if ( this.workerid <= 0/* || this.workerid == this.$store.getters.getGeneralWorker().id*/ ) {
					return
				}
				const base_id = event.target.id.substr( 0, event.target.id.indexOf( "h" ) + 1 )
				let base_id_x = parseInt( event.target.id.substr( event.target.id.indexOf( "h" ) + 1 ) )
				let dest = null

				for ( let i = 0; i < this.insertionTimeline.length; i++ ) {
					let elem = document.getElementById( base_id + ( base_id_x - i ) )
					if ( elem && !elem.classList.contains( "cannot" ) ) {
						dest = elem
						if ( i > 0 ) {
							column = this.rows[ ( base_id_x - i ) - this.openIdx ].columns[ parseInt( base_id.substr( base_id.indexOf( "d" ) + 1 ) ) - 1 ]
						}
						break
					}
				}
				if ( !dest ) {
					return
				}
/*
				this.$refs.insertionSelectWrapper.style.top = dest.offsetTop + "px"
				this.$refs.insertionSelectWrapper.style.left = dest.offsetLeft + "px"
*/
				this.$refs.insertionHoverWrapper.style.display = ""
				this.$refs.insertionSelectWrapper.style.display = "block"
				this.$refs.insertionSelectWrapper.style.top = "0px"
				this.$refs.insertionSelectWrapper.style.left = "0px"
				dest.appendChild( this.$refs.insertionSelectWrapper )


				const date = dayjs( dest.getAttribute( "data-date" ) )
				const h = date.hour()
				const m = date.minute()
				this.insertedDate = date
				this.insertedHour = ( h < 10 ? ( "0" + h ) : h ) + ":" + ( m < 10 ? ( "0" + m ) : m )

				if ( isTouch ) {
					event.preventDefault()
					this.$nextTick( () => {
						this.$refs.insertionHoverWrapper.style.display = ""
					})
				}

				this.$emit( 'selected', Object.assign( JSON.parse(JSON.stringify(column)), { id: this.uid + '_' + column.id } ) )
			},
			update( generalSched, workerSched ) {
				this.updateSchedule( generalSched, workerSched )
				this.$forceUpdate()
				this.updateAppointments()
			},
			appointmentsWatcher( event ) {
				this.$nextTick( () => {
					console.log( "EVENT", event )
					// We only want to insert appointments that belong to currently displayed week
					let affected = []
					for ( let i = 0; i < event.data.length; i++ ) {
						if ( event.data[i].date == null || event.data[i].date.length == 0 ) {
							// Probably a PATCH that did not modify date, we have to retrieve the corresponding appointment
							for ( let j = 0; j < this.appointments.length; j++ ) {
								if ( this.appointments[j].id == event.data[i].id ) {
									let date = dayjs( this.appointments[j].date )
									if ( date.isAfter( this.date ) && date.isBefore( this.date.addDays( this.daysCount ) ) ) {
										affected.push( event.data[i] )
									}
									break
								}
							}
						} else {
							let date = dayjs( event.data[i].date )
							if ( date.isAfter( this.date ) && date.isBefore( this.date.addDays( this.daysCount ) ) ) {
								affected.push( event.data[i] )
							}
						}
					}
					console.log( "Affected : ", affected )
					if ( event.method == "POST" || event.method == "PATCH" ) {
						this.workerThread.postMessage( {
							action: "addAppointments",
							blockSize: this.$store.state.config.block_size,
							workers: this.$store.getters.getWorkers(),
							daysCount: this.daysCount,
							date: this.date,
							appointments: affected
						})
					}
				})
			},
			workersWatcher( event ) {
				let update = false
				let update_a0 = undefined
				let update_a1 = undefined
				if ( event.method == "PATCH" ) {
					if ( typeof event.data[0].show_past_appointments != "undefined" ) {
						this.showPastAppointments = event.data[0].show_past_appointments
						update = true
					}
					if ( event.data[0].id == this.$store.getters.getGeneralWorker().id && event.data[0].schedule ) {
						update = true
						update_a0 = event.data[0].schedule
					}
					if ( event.data[0].id == this.workerid && event.data[0].schedule ) {
						update = true
						update_a1 = event.data[0].schedule
					}
				}
				if ( update ) {
					this.update( update_a0, update_a1 )
				}
			},
			schedulesWatcher( event ) {
				console.log( event )
				this.update( event.data )
			}
		},
		created() {
			let thiz = this
			this.workerThread = new calendarWorker()
			this.workerThread.onmessage = function( message ) {
				if ( message.data.action == "setProgress" ) {
					thiz.$app.progressBarSet( message.data.progress )
				} else if ( message.data.action == "setAppointments" ) {
					thiz.$app.progressBarDone()
					let appointments = message.data.appointments
					Object.keys( appointments ).forEach(function ( id ) {
						if ( thiz.$refs[ id ] && thiz.$refs[ id ][0] ) {
							if ( thiz.editing ) {
								thiz.$refs[ id ][0].setAppointments( appointments[id].filter( app => app.id != thiz.editing.id ), thiz.perfCounter )
							} else {
								thiz.$refs[ id ][0].setAppointments( appointments[id], thiz.perfCounter )
							}
							for ( let i = 0; i < appointments[id].length; i++ ) {
								let app = appointments[id][i]
								if ( !thiz.appointments.includes( app ) ) {
									thiz.appointments.push( app )
								}
								if ( !thiz.editing || app.id != thiz.editing.id ) {
									thiz.lockPlace( app )
								}
							}
						}
					})
					thiz.isBuilding = false
					thiz.$forceUpdate()
				} else if ( message.data.action == "addAppointments" ) {
					let appointments = message.data.appointments
					Object.keys( appointments ).forEach(function ( id ) {
						if ( thiz.$refs[ id ] && thiz.$refs[ id ][0] ) {
							for ( let i = 0; i < appointments[id].length; i++ ) {
								let app = appointments[id][i]
								if ( app.status && app.status.indexOf("canceled") >= 0 ) {
									let idx = thiz.appointments.findIndex( a => a.id == app.id )
									if ( idx >= 0 ) {
										thiz.appointments.splice( idx, 1 )
										thiz.$refs[ id ][0].removeAppointment( app )
									}
								} else {
									let oldApp = thiz.appointments.find( a => a.id == app.id )
									if ( oldApp ) {
										console.log( "oldApp", oldApp, oldApp.date, app.date )
										if ( oldApp.date != app.date ) {
											console.log( oldApp.date )
											let day = dayjs( oldApp.date ).hour(0).minute(0).second(0).millisecond(0)
											let dayIdx = Math.ceil( day.diff( thiz.date, 'days', true ) )
											let hourIdx = Math.floor( dayjs( oldApp.date ).diff( day, 'minutes', true ) / thiz.$store.state.config.block_size )
											let row = thiz.rows[ hourIdx - thiz.openIdx ]
											if ( row ) {
												let column = row.columns[ dayIdx ]
												if ( column ) {
													console.log( thiz.$refs[column.id] )
													let calendarBlock = thiz.$refs[column.id][0]
													if ( calendarBlock ) {
														console.log( calendarBlock, app.id )
														calendarBlock.removeAppointment( app )
													}
												}
											}
											oldApp.date = app.date
											/*
											for ( let [key, ref] of Object.entries(thiz.$refs) ) {
												if ( key == "d7_h54" ) {
													console.log( key, ref, app )
												}
												if ( ref ) {
													for ( let r = 0; r < ref.length; r++ ) {
													//	if ( ref[r] && ref[r].appointments && ref[r].appointments.length > 0 ) {
													//		console.log( key, ref, "removeAppointment", app.id )
															ref[r].removeAppointment( app )
													//	}
													}
												}
											}
											*/
										}
									} else {
										thiz.appointments.push( app )
									}
									if ( !thiz.editing || app.id != thiz.editing.id ) {
										thiz.$refs[ id ][0].addAppointment( app )
										thiz.lockPlace( app )
									}
								}
							}
						}
					})
				}
			}
		},
		mounted() {
			this.updateSchedule()
			this.$forceUpdate()
			this.updateAppointments()
		//	if ( !this.insertionTimeline ) {
				this.$api.appointments.watch( this.appointmentsWatcher )
				this.$api.workers.watch( this.workersWatcher )
				this.$api.schedules.watch( this.schedulesWatcher )
		//	}
			if ( this.$refs.insertionHover ) {
				const hoverColor = Color( this.$store.getters.getWorker( this.workerid ).color ).saturate( 0.25 ).alpha( 0.4 ).toString()
				const selectedColor = Color( this.$store.getters.getWorker( this.workerid ).color ).saturate( 0.25 ).lighten( 0.1 ).toString()
				this.$refs.insertionHover.addAppointment({
					id: -1,
					color: hoverColor,
					width: "100%",
					widthForWorker: "100%",
					height: ( this.insertionTimeline.length * 1.345 ) + "rem",
					views: [{ style: { background: hoverColor, "min-height": ( this.insertionTimeline.length * 1.345 ) + "rem" }, class: "appointment-back" }]
				})
				this.$refs.insertionSelect.addAppointment({
					id: -1,
					color: selectedColor,
					width: "100%",
					widthForWorker: "100%",
					height: ( this.insertionTimeline.length * 1.345 ) + "rem",
					views: [{ style: { background: selectedColor, "min-height": ( this.insertionTimeline.length * 1.345 ) + "rem" }, class: "appointment-back" }]
				})
			}
			if ( this.editing ) {
				this.insertedDate = dayjs( this.editing.date )
				const h = this.insertedDate.hour()
				const m = this.insertedDate.minute()
				this.insertedHour = ( h < 10 ? ( "0" + h ) : h ) + ":" + ( m < 10 ? ( "0" + m ) : m )
			}
		},
		destroyed() {
			this.$api.appointments.unwatch( this.appointmentsWatcher )
			this.$api.workers.unwatch( this.workersWatcher )
		},
		updated() {
			this.resize()
			this.$el.removeEventListener( 'click', this.clicked )
			this.$el.addEventListener( 'click', this.clicked )
			if ( this.editing && this.$refs.insertionSelectWrapper.style.display != "block" && this.appointments && this.appointments.length > 0 ) {
				let app = this.appointments.find( a => a.id == this.editing.id )
				let row = this.rows[ app.hourIdx - this.openIdx ]
				if ( row ) {
					let column = row.columns[ app.dayIdx ]
					if ( column ) {
						const id = this.uid + "_" + column.id
						const elem = document.getElementById( id )
						if ( elem ) {
							// HACK : at this time elem is at (0,0) so we have to wait for it to be placed by the renderer → find another way to wait for elem to be placed
							let self = this
							let f = null
							f = function() {
								if ( elem.offsetTop == 0 && elem.offsetLeft == 0 ) {
									setTimeout( f, 50 )
								} else {
									self.$refs.insertionSelectWrapper.style.top = elem.offsetTop + "px"
									self.$refs.insertionSelectWrapper.style.left = elem.offsetLeft + "px"
									self.$refs.insertionSelectWrapper.style.display = "block"
								}
							}
							f()
						}
					}
				}
			}
		},
		beforeMount() {
			window.addEventListener( "resize", this.resize )
		},
		beforeDestroy() {
			window.removeEventListener( "resize", this.resize )
		}
	}
</script>
