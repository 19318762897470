/*
- {user} a pris un rendez-vous
- {worker} a pris un rendez-vous pour {user}
- {user} a annulé un rendez-vous
- {worker} a annulé le rendez-vous de {user}
- {user} a modifié un rendez-vous
- {worker} a modifié le rendez-vous de {user}
- {user} a posté un avis
- {worker} a modifié le compte de {user}/{worker}
- {worker} a changé sa couleur de thème
- {worker} a changé sa couleur de planning
- {user} a supprimé son compte
- {user} s'est créé un compte
- {worker} a créé un compte pour {user}
- {worker} a fait une demande de congé(s) (visible pour ($store.state.worker.admin==true) uniquement)
- {user} n'est pas venu au rendez-vous du ... à ... 
- il reste moins de -5 ""
*/
export default function( domain ) {
	domain = domain || process.env.VUE_APP_DOMAIN || "esthetics"
	const tappoints = domain == "coachs" ? "Séances" : "Rendez-vous"
	const tappoint = domain == "coachs" ? "Séance" : "Rendez-vous"
	const namep = domain == "coachs" ? "Mentor PRO" : "Mentor PRO"
	const name = "Mentor"
	const mail = domain == "coachs" ? "mentorapp.fr" : "espacetemps.io"
	const link = domain == "coachs" ? "https://mentorapp.fr" : "https://espacetemps.io"
	const videos = domain == "coachs" ? "vidéos" : "photos"
	const video = domain == "coachs" ? "vidéo" : "photo"
	const coach = domain == "coachs" ? "coach" : "Espace-Temps"
	const account = domain == "coachs" ? "Compte" : "Salon"
	const selfappoint = domain == "coachs" ? "sa séance" : "son rendez-vous"
	const anappoint = domain == "coachs" ? "une séance" : "un rendez-vous"
	const appoint = domain == "coachs" ? "séance" : "rendez-vous"
	const aappoint = domain == "coachs" ? "une séance" : "un rendez-vous"
	const theappoint = domain == "coachs" ? "la séance" : "le rendez-vous"
	const newappoint = domain == "coachs" ? "nouvelle séance" : "nouveau rendez-vous"
	const appoints = domain == "coachs" ? "séances" : "rendez-vous"
	const next = domain == "coachs" ? "prochaine" : "prochain"
	const finished = domain == "coachs" ? "terminée" : "rendez-vous"
	const visits = domain == "coachs" ? "séances" : "visites"
	const schedule = domain == "coachs" ? "planning" : "salon"
	const login = domain == "coachs" ? "login" : "nom du salon"
	const reservations = domain == "coachs" ? "réservations" : "rendez-vous"
	const pronom = domain == "coachs" ? "une" : "un"
	const pronoml = domain == "coachs" ? "la" : "le"
	const two = domain == "coachs" ? "deux" : "quatre"
	const activitie = domain == "coachs" ? "activité" : "salon"
	const target = domain == "coachs" ? "autour de vous" : "dans votre salon"
	const nextapp = domain == "coachs" ? "Prochaines séances" : "Prochains rendez-vous"
	const appval = domain == "coachs" ? "Séances validées" : "Rendez-vous validé(s)/pris"
	const appmod = domain == "coachs" ? "Séance modifiée" : "Rendez-vous modifié"
	const appcancel = domain == "coachs" ? "Séance annulée" : "Rendez-vous annulé"
	const reserved = domain == "coachs" ? "réservé" : "pris"
	const reserve = domain == "coachs" ? "réserver" : "prendre"
	const reserveapp = domain == "coachs" ? "Réserver une séance" : "Prendre rendez-vous"
	const nonenext = domain == "coachs" ? "Aucune prochaine séance" :  "Aucun prochain rendez-vous"
	const nonecurrent = domain == "coachs" ? "Aucune séance en cours" : "Aucun rendez-vous en cours"
	const validates = domain == "coachs" ? "Validées" : "Validés"
	const validatesorcancel = domain == "coachs" ? "Validées/Annulées" : "Validés/Annulés"
	const canceled = domain == "coachs" ? "Annulées" : "Annulés"
	
	return {
		en: {
			lang: "English",
			icons: [
				"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAYCAYAAACbU/80AAAEK0lEQVRIie2WX0xTVxzHT2KybPgvZLikEOMWR6wRZUJjURYN6nSZc9GEPZDs3xMPW7L5tD9qsqXshTnICGZPI5sxMWGSTZyZi4ss04CibM64FRgI6Cy0IC29l/ZA7z33s4dybyutwfowX3aSX3Jve37f3+f+zjm/8xPVotLnE4tVoxA0CoGvqQVfUwvrSloQoj7D7P/t+fP90ufm5TU4v8/384nFqlpU+kR68HShwJjOypVfPjTAokWfcap94L4ANoSwXyIVJUy9shPjajf2CEcMXtjVj9frd0zTTACCbjejLhejLhdBtxsATTOded1XdCzLAkCNh9DfrSVSUeKYHdcBmNy0LmneEmJNR7BmZwGIRk1qa4cfGGDbtj56e+POR8x2nCO8c0tKf3Mp0Yb6FICdmugXDUxsLmXCs5YJz1oiNfswBvoBME2L1tbJBQHicUUgkAS3pnW0Tz5y9CY8awm/U0us/yZSKmKxBCdO+FMAUipigyNMvvc2oY1rCG1cw3jFBmLHvwbLwjQtzp+PMj2tMgBCHk8yaDLjJK71cPflHY7OxL4X0Tt+QUqFlIquLp29e9vm9ouoZ/fub+ns1J0J+q8XCe1/ibHSYsZKi5msfQMzOIZhWE6QewA2bcIm0Jo+Z6zMzVhpMcHKMqZavkJqM0ipGB6eobk5hNfrT9vYcw9er5/GxiADA8nJcjpB5Ng3jG4p48761YxWlhE/+4OzttkAzPEQd9avJrChmLuHPyQWGHc+qq0tzPbtfc5ecgDsJch1ZM1ADsM5to8cwD4Otth/ZRl14H+AoNudk40WFqYEi4py9s+ohI9sE9oPmmaiaSbxuEKpZFWzlELpOmZ0CiXlggCWkcCMRjGjUaxEIqlhQSJhOfq2pW7PtELU1hZ2Csf4qdNcf3YVfzxdSPj775zA1gIZkIMD+J/38vtT+YwcPEhcn0VKRUeHxp49f2cWIiHqcbmO0tGhIaUirs0w/MH79BQs50Z5KbE/byQDWzByS2a/C+YtganrDL71Gj1PLuOvXTvQBm8hpWJkZJYDB25nr4RSKrTBW9yo2kZ3/hL6Xt2PEYkkBZXFjz9F2Lq1N+ttGCovB2AqajIzq+aALQINR7hSsIzfnlnJ+JmzTnZPngxnAoTaz3B1VRGXli/mtu9jLDMZaCahqPs08MD9QE3NTcIRw8lG5Odzju7QocPE9QRSKlpbe1mxojl1DDuXPsFlVwF3T7ennKcMampu5twRVVX10XVJQ81dnXJ4iGsVHjqXPM71HVVoQ/9k1oGe50qI9fqd9HVf1amq6rsneC4tmdfrp/loEMNILok5PU3fm69zMe8xLq8qSqsD85pSw1AcPHQha0eca1csRD0ezzEiUzNZ/erEUlNUi0pfXRqEt+L4fYM/DIAQ9eTnN2X41YmlZrWo9P0LHdofF7ihYxUAAAAASUVORK5CYII=",
				"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAWCAYAAAChWZ5EAAABFUlEQVRIie2WoWrEQBRFH2RchjW1ZSGQZlXFiEDk/kAgZteE+qrq1VHJDhFhTU1UfyMikG5NRfcDagsphJqawopbURJKq2ZGjIk44ql35t0rhu4ugpul2J15KMFDCR6VPyjMi/UBBSNVeulQTEuxO7sihwk8KnUEUDDqiYcSrsjx+DLgeBrgJQ28pFGa/W2Lj88vJbKqRlbVmASOpwFPvxaozP7GRCAq4Ypc6+UjQdrh9epSiTGGfwKql/CSBisjgbkDcwfmDvyJQAejDizWB/CohL9t4W9aBGmHVdopzde3z/oCWVWjuH9QPqEpUwTWBcZTqJ7QlCkC6wLWI7AuUDDqNb9TxuwZvZF0KN4zerexXDoUfwN55DSb0Y0+tQAAAABJRU5ErkJggg=="
			],
			time: {
				defaultformat: "MMM DD, YYYY hh:mm A",
				weekdays: [ "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday" ],
				shortweekdays: [ "Mon.", "Tue.", "Wed.", "Thu.", "Fri.", "Sat.", "Sun." ],
				morning: "Morning",
				afternoon: "Afternoon",
				range: "From {start} to {end}",
				rangeToEndOfDay: "From {start} to {end} (end of day)",
				rangeFromStartOfDay: "From {start} (start of day) to {end}",
				exact: "The {date}",
				days: "{count} days | {count} day | {count} days",
				hours: "hour | hour",
				minutes: "minute | minutes",
				hrs: "hrs",
				min: "min",
				shorthours: "{count}h",
				shorttime: "{h}h{m}m",
				age: "{count} years old",
				agerange: "{start} - {end} years old"
			},
			data: { // Related to database, all fields MUST exist for any language
				subscription: {
					starter: "Starter",
					premium: "Premium",
					multi: "Multi"
				},
				genders: {
					male: "Male",
					female: "Female",
					child: "Young"
				},
				genders_short: {
					m: "M",
					f: "F",
					c: "Y"
				},
				agegroups: {
					under25: "Under 25 years old",
					range2545: "Between 25 and 45 years old",
					over45: "Over 45 years old",
				},
				hairlength: {
					short: "Short",
					half: "Mid-length",
					long: "Long",
				},
				servicesstep: {
					"1": "Preparation",
					"2": "Haircut",
					"3": "Sink",
				},
				servicestype: {
					"1": "Only in packages",
					"2": "À la carte & in packages",
				//	"3": "Basic",
				//	"4": "Extra",
					"-1": "Technical", // Negative types can not be used in automatic scheduling because of their high variability
				},
				absences: {
					"": "Pending",
					"validated": "Validated",
					"refused": "Refused"
				},
				stockstypes: [
					"Mask",
					"Hair care",
					"Serums",
					"Permanent colouring",
					"Natural coloration",
					"Semi-permanent colouring",
					"Colour Refreshing",
					"Oxidizing colouring",
					"Bleach",
					"Smoothing",
					"Styling foam",
					"Styling gel",
					"Wax",
					"Lotion",
				],
				stockscolors: [
					"Black", 
					"Dark Brown",
					"Medium Brown",
					"Light Brown",
					"Dark blond",
					"Blonde",
					"Light Blonde",
					"Very Light Blonde",
					"Light Ash Blonde",
				],
				absences: {
					"": "Pending",
					"validated": "Validated",
					"refused": "Refused"
				},
				messagesstatus: {
					sent: "Sent"
				},
				notifications: {
					1: { message: "{user} {action} an appointment for {data.date}", action: "has made" },
					2: { message: "{worker} {action} an appointment for {data.user} for {data.date}", action: "has made" },
					3: { message: "{user} {action} an appointment", action: "canceled" },
					4: { message: "{worker} {action} {data.user}'s appointment", action: "canceled" },
					5: { message: "{user} {action} an appointment", action: "has modified" },
					6: { message: "{worker} {action} {data.user}'s appointment", action: "has changed" },
					21: { message: "{worker} {action} {data.user}'s appointment", action: "has finished" },
					7: { message: "{user} {action} a review", admin: true, action: "posted" },
					8: { message: "{worker} {action} {user}'s account", action: "has modified" },
					9: { message: "{worker} {action} {data.name}'s account", action: "has modified" },
					10: { message: "{worker} {action} their theme color", action: "changed" },
					11: { message: "{worker} {action} their schedule color", action: "changed" },
					12: { message: "{user} {action} their account", action: "has deleted" },
					13: { message: "{user} {action} an account", action: "has created" },
					14: { message: "{worker} {action} an account for {user}", action: "has created" },
					15: { message: "{worker} {action} a leave", admin: true, action: "has requested" },
					25: { message: "{worker} {action} a leave", admin: true, action: "will be taking" },
					23: { message: "{worker} {action} your leave request", self: true, selfid: "worker", action: "has accepted" },
					24: { message: "{worker} {action} your leave request", self: true, selfid: "worker", action: "has refused" },
					16: { message: "{data.user} {action} to the appointment of {data.date}", action: "did not come" },
					17: { message: "There are less than 5 {data.name} {data.color} (ref {data.reference}) left !", premium: true },
					22: { message: "There are less than 10 {data.name} {data.color} (ref {data.reference}) left !", premium: true },
					18: { message: "{worker} {action} stock {data.name} {data.color} (ref {data.reference})", premium: true, action: "has modified" },
					19: { message: "{worker} {action} a stock ({data.name} {data.color}, ref {data.reference})", premium: true, action: "has created" },
					20: { message: "{worker} {action} a stock", premium: true, action: "has deleted" },
					26: { message: "{action} sent successfully ! ({data.pushcount} notifications, {data.smscount} SMS, {data.price}€)", admin: true, action: "Marketing campaign" },
					update: { message: "A new {action} is available ! Click here to install it", action: "update" }
				},
			},
			errors: {
				notfound: "Page not found",
				missingtoken: "You must first login",
				missing: "Please fill all input fields",
				missingfield: {
					salonid: "Please supply the field 'salonid'",
					userid: "Please supply the field 'userid'",
					text: "Please supply the field 'text'",
				},
				min2services: "Please select at least two services",
				toolong: "A supplied field was too long",
				nosalon: "You must supply the salon ID",
				badsubscription: "Your subscription does not allow you to perform this action",
				noid: "No ID was supplied",
				missingfields: "Some fields are missing from the form",
				argon2: "An unknown error happened",
				keynotallowed: "The supplied key is not allowed",
				baddomain: "The supplied fomain is not valid",
				security: {
					cannotget: "You do not have permission to perform a GET request to this endpoint",
					cannotpost: "You do not have permission to perform a POST request to this endpoint",
					cannotpatch: "You do not have permission to perform a PATCH request to this endpoint",
					cannotdelete: "You do not have permission to perform a DELETE request to this endpoint",
					cannotpatchbatch: "You do not have permission to perform a batch PATCH request to this endpoint",
					cannotdeletebatch: "You do not have permission to perform a batch DELETE request to this endpoint",
				},
				business: {
					badsiret: "The supplied SIRET number is invalid",
					cannotcreate: "Could not create the database entry",
				},
				values: {
					number: {
						onlypositive: "The supplied number cannot be negative",
					},
					string: {
						toolong: "One of the fields is too long"
					}
				},
				schedule: {
					badtiming: "Some schedules are overlapping"
				},
				worker: {
					mismatch: "You cannot access data from other workers",
					hidden: "The supplied worker is hidden",
					notfound: "The worker was not found",
					generalusernamereserved: "You cannot name an account 'general'",
					generalusernamefixed: "The salon account username can not be changed",
					generaladminfixed: "The salon account cannot be an administrator account",
					emptypasswordcheck: "You must confirm the password",
					cantdeleteadmin: "You cannot delete an administrator account",
					cantdeletegeneral: "You cannot delete the salon account",
					cantdeletemanager: "You cannot delete the manager account",
					cantdeleteself: "You cannot delete your own account",
					adminonly: "You must be an administrator do perform this action",
					invalidpassword: "The supplied password is invalid",
				},
				pass: {
					mismatch: "New passwords mismatch",
					missing: "Please complete all fields",
					weak: "The password you supplied is to weak",
					wrong: "The password you supplied is not correct",
				},
				user: {
					notfound: "User does not exist",
					mismatch: "You cannot access data from other users",
					appphoneexists: "This phone number is already associated with an account in the application",
					appemailexists: "This email address is already associated with an account in the application",
					phoneexists: "A user with the same phone number already exists",
					emailexists: "A user with the same email address already exists",
					banned: "You are banned",
					alreadyactive: "The user is already validated",
					codeexpired: "The code is expired",
					codeinvalid: "The code is invalid",
				},
				client: {
					notfound: "ID does not exist",
				},
				formula: {
					notfound: "The formula was not found",
				},
				service: {
					notfound: "The service was not found",
					notworker: "The worker cannot perform this service",
				},
				appointment: {
					invaliddate: "An invalid date was supplied",
					noworker: "No worker was supplied",
					notimeline: "No timeline was supplied",
					overlap: "There is already an appointment in the selected timeslot",
					notfound: "The appointment could not be found",
					cannotdelete: "Cannot delete an appointment",
				},
				batch: {
					onlysortingorapp: "You can only edit the priority or visibility of several services at once",
					onlyseen: "Only the 'seen' field can be batch-patched, 'salonid' must be set",
					nobatch: "Cannot perform a batch request",
				},
				review: {
					notfound: "The review could not be found",
					deletebadowner: "You must own this review to delete it",
					neverwent: "You must have had an appointment in this salon to post a review",
				},
				salon: {
					notfound: "The salon could not be found",
					mismatch: "You cannot access this salon",
					readonly: "You only have read access on this salon",
				},
				invalid: {
					email: "The supplied email address is not valid",
					phone: "The supplied phone number is not valid",
					birthdate: "The supplied birthdate is not valid",
					phoneexists: "The supplied phone number is already linked to an account",
					emailexists: "The supplied email address is already linked to an account",
					appphoneexists: "This phone number is already linked with a user account",
					appemailexists: "This email address is already linked with a user account",
					timeline: "The supplied timeline is invalid",
					clienttype: "Invalid clientType",
				},
				invoice: {
					notfound: "The invoice could not be found",
				}
			},
			titles: {
				appname: `${namep}`,
				appoint: `${tappoints}`,
				admin: "Administration",
				calendar: "Calendar",
				customer: "Customer",
				customers: "Customers",
				dashboard: "Dashboard",
				hairdressers: "Hairdresser's",
				disco: "Disconnect",
				notifs: "Notifications",
				reviews: "Reviews",
				settings: "Settings",
				schedule: "Schedules",
				stocks: "Stocks",
				stockssearch: "Search - Stocks",
				statistics: "Statistics",
				error: "Error",
				productused: "Stock(s) used",
				summary: "Summary",
				nextvisit: "Next visit",
				history: {
					basic: "Appointments",
					today: "Today's appointments",
					month: "This month's appointments",
					year: "This year's appointments",
				},
				login: "Login"
			},
			common: {
				organizer:"Organizer",
				unchanged: "Put nothing to leave unchanged",
				browse: "Browse ...",
				add: "Add ...",
				networkerror: "Network error",
				reload: "Reload",
				all: "All",
				app: "App",
				thedate: "The",
				dashboard: "Dashboard",
				appointment: "appointment",
				appoints: "appointments",
				enterprise: "Hairdresser's",
				account: "Account",
				salon: "Salon",
				for : "for",
				with: "with",
				cancel: "Cancel",
				none: "None",
				newapp : "New appointment",
				nextapp: "Next appointment",
				nextappoint: "Next appointments",
				appinprogress: "Appointments in progress",
				validatetaken: "Appointments validated/taken",
				month: "this month",
				appday : "Today's appointments",
				appmonth: "Appointments this month",
				appmod: "Appointment modified",
				appcancel: "Appointment canceled",
				year: "this year",
				appyear: "appointments this year",
				client: "client",
				worker: "employee",
				allworkers: "All",
				anonymous: "Anonymous",
				create: "Create",
				createclient: "Create client account",
				registered: "Customers",
				lactivities: "Lastest activities",
				reviews: "Reviews",
				dispute: "Dispute",
				week: "Week",
				day: "Day",
				modified: "modified",
				legend: "Legend",
				general: "General",
				dateand: "Date and",
				date: "Date",
				and: "and",
				hour: "hour",
				hours:"Hour",
				min: "minutes",
				staff: "Staff member",
				customer: "customer",
				customers: "customers",
				category: "Category",
				close: "Close",
				seeless: "See less",
				seeall: "See all",
				seemore: "See more",
				login: "Login",
				name: "Name",
				price: "Price",
				wom: "Women",
				woman: "Woman",
				men: "Men",
				man: "Man",
				young: "Young",
				youth: "Youth",
				duration: "Durations (minutes)",
				durate: "Duration",
				action: "Action",
				dependencies:"Dependencies",
				dependence: "Dependence",
				workt: "work time",
				btime: "break time",
				ex: "example",
				col: "a color",
				hair: "Hair",
				dry: "drying",
				save: "Save",
				save2: "Save",
				order: "order",
				ordserv: "Sorting",
				onlyfor: "For",
				usern: "Username",
				email: "E-mail",
				pwd: "Password",
				yourpwd: "Your password",
				currentpwd: "Current password",
				npasswd: "New password",
				confirmpwd: "Confirm new password",
				forgotpwd: "Forgot Password",
				requestsent: "Request sent",
				requestadm: "Holidays / Leave",
				adminacc:"Administrator account",
				manageracc:"Manager account",
				infos: "General informations",
				secu: "Security",
				confirm: "Confirm",
				status: "Status",
				message: "Message",
				schedule: "schedule",
				request: "Request for leave / holidays",
				step: "Step",
				type: "Type",
				visibility: "Visiblity",
				brand: "Brand",
				brands: "Brands",
				tref: "this reference",
				ref: "ref.",
				descript: "Description",
				op: "Open",
				mod: "Modify",
				tweek: "this week",
				tyear: "this year",
				users: "Users",
				phone: "Phone",
				search: "Search",
				searcusto: "search customer",
				gender: "Gender",
				genders: "Genders",
				lname: "Last name",
				fname: "First name",
				datebirth: "Date of birth",
				loyalty: "Loyalty points",
				points: "0 points | {count} point | {count} points",
				customer: "Customer",
				perso: "personalized",
				notifs: "notifications",
				mappoint: "made an appointment",
				dappoint: "canceled an appointment",
				postrev: "posted a review",
				showonapp: "Show on app",
				sless: "See less",
				sms: "Marketing campaign",
				ad: "Advertising",
				quote: "on quote",
				sdate: "Start date",
				choosesdate: "Choose starting date",
				chooseedate: "Choose ending date",
				edate: "End date",
				services: "Services",
				service: "Service",
				complet: "completed",
				pack: "Packages",
				package: "Package",
				attend: "Attendance",
				present: "Present",
				canceled: "Canceled",
				absent: "Absent",
				missed: "Missed",
				total: "total",
				reason: "Reason",
				reasons: "Reason(s)",
				reasonop:"(optionnal)",
				login: "login",
				chpass: "Change password",
				challenge: "Challenge",
				by: "by",
				hol: "Leave / Holidays",
				holiday: "Holidays",
				worksch: "Work schedules",
				color: "Color",
				website: "App",
				bills: "Bills",
				accdel: "deleted account",
				question: "Questions ?",
				forbidcust: "Prevent this client from making appointments online",
				id: "Id",
				apply:"Apply",
				del:"Delete",
				dispute: "Dispute",
				remember: "Remember me",
				cosmetics: "Cosmetics",
				return: "Return",
				annulation: "Cancellation",
				yes:"Yes",
				count:"Total",
				utilstock:"Stocks utilisation",
				usesstock:"Uses stocks",
				emailphone: "Email / Phone",
				carte: "À la carte",
				takeappointment: "Take an Appointment",
				from: "From",
				to: "To",
				validate: "Validate",
				clientid: "ID",
				age: "Age range",
				nouser: "No user",
				novisit: "No visit",
				notification: "Notification",
				left: "Left ...",
			},
			index: {
				newreviews: "New Reviews",
				promote: "Promote",
				onthesite: "display on the website",
				fini: "Finish",
				began: "Began",
				missing: "Missing",
				surecancel: "Cancel this appointment?",
				surereview: "Dispute the review ?",
				confirmabsent: "Missing customer ?",
				
			},
			appointments: {
				reduction: "Customer's reduction",
				confirmcancel: "Do you really want to cancel this appointment ?"
			},
			administration: {
				text: "Subscribe to appointment's online !",
				contracttermination: "Termination of subscription",
				subscribe: "Switch to Premium pack",
				admin: "Admin",
				administrator: "administrator",
				explain1: "15 minutes break, then 40 minutes waiting, then 5 minutes ",
				visible: "visible on the appointment",
				progdate: "Programming Date",
				webview: "Website overview",
				createsite: "Create my Website",
				notsup:"Services not supplied",
				team: "Team",
				fullday: "Full day",
				multipromote: "Mutiples Promotes",
				price: "Price ($)",
				total: "Total :",
				time: "\[Work time\]  \[Break time\]  \[Work time\]  \[Break time\]  \[Work time\] ...",
				time2: "Exemple : For color 15  40  10, exposure time of 15 min, then waiting of 40 minutes , then 10 minutes of washing / drying.",
				onlyweb: "Only on Website / App",
				address1: "Address",
				address2: "Zipcode / City",
				subapp: "Modify my App Espace-Temps",
				infos: "Informations",
				services: "Services",
				packages: "Packages",
				schedules: "Schedules",
				gallery: "Gallery",
				term: "Termination",
				cancelall: "Cancel the appointments ?",
				cancelallconfirm: "Cancel the appointments ?",
				cancelapp: "Cancel my App Espace-Temps",
				viewapp: "See my App Espace-Temps",
				contactinfos: "Contact informations",
				technic: "Technic",
				closure: "Temporary closure of the online appointment calendar",
				anticipated: "Please anticipate closing as soon as possible",
				lowest:"From ...",
				highest:"Up to ...",
				range: "Between ... and ...",
				noprices: "Do not display prices",
				reviewsandpromote: "Reviews and Promote",
				seedashboard: "See Dashboard",
				salonclose: "Temporary closure | Temporary closures",
				fromaddress: "Search using address",
				dblclickplace: "Double click to specify another location",
				locationservicemsg: "",
				sorting: `The planning system with holes requires that a **passage order** between the services be established. For example, a Shampoo is done **before** a Haircut, but **after** a Hair Color.
				This list represents **from top to bottom** the switching orders from the **first service to the last**, it is possible to grab (*keep pressed*) each of the services and move it in order to change its order of passage.
				**Services with break times have priority**, there is no effect in reversing a **Drying** and a **Brushing** between them for example.
				Tip: If you had to perform all of your services with one client, in what order would you do it?`,
				salonname: "Salon's name",
				youthlimit: "Age limit young",
				servonapp: "Available on the App",
			},
			bills: {
				adsalon: "Salon ID",
				infos: "Informations",
				codemulti: "Multi-Salon Code",
				sub: "Subscription",
				apponline: "Appointments Online",
				payment: "Payment",
				bank: "Bank",
				card: "Credit card",
				levy: "Payment",
				bills: "Bills",
				billof: "Bills of",
				cancelconfirm: "Are you sure you want to cancel your subscription ? This action is irreversible."
			},
			reviews: {
				dispute: {
					reason: "Dispute reason",
					offensive: "Offensive, rude, vulgar, threatening, obscene, defamatory, discriminatory remarks",
					commercial: "Commercial content",
					spam: "SPAM",
				}
			},
			salons: {
				addtitle: "Add a Salon",
				add: "Add a Salon",
			},
			notification: {
				filters: {
					all: 'All',
					cancelled: 'Cancellation',
					edits: 'Modifications',
					appointments: 'Appointments',
					stocks: 'Stocks',
					holidays: 'Holidays',
				},
			},
			infiniteloader: {
				noResult: 'No result!',
				error: 'Error while fetching results :(',
			},
			stocks: {
				stocks: "Stocks",
				stock: "Stock",
				barcode: "Barcode",
				cond: "Condition",
				range: "Range",
				ranges: "Ranges",
				color: "Color",
				used: "Used",
				remain: "(remaining)",
				nfil: "Create new filter based on this search",
				nnewfilter: "Name of the new filter",
				cancelfilt: "Delete filter",
				setfilter: "Setting's filter",
				whatev: "Whatever",
				sout: "Out of stock",
				fiveless: "5 and less",
				tenless: "10 and less",
				refcode: "Reference",
				createfilter: "New filter",
				createfiltername: "New filter name",
				initstock: "Initial stocks",
				deletefilter: "Delete the filter ?",
				type: "Type",
			},
			statistics: {
				statistics: "statistiques",
				day: "Today",
				month: "Month ",
				week: "Week",
				year: "Year",
				apps: "Total appointments",
				appsperhours: "Total appointments per hour",
				appsperhour: "Appointments per hour",
				onlineperhour: "Appointments online per hour",
				rpart: "participation rate",
				details: "details",
				tempo: "Temporalities",
				hdressorphone: "on Hairdresser's or by phone",
				online: "Online",
				rush: "Rush",
				offpeak: "off-peak times",
				total: "Total",
				comp: "Comparative",
				general: "General",
				resume: "Resume",
				inshort: "In short",
				today: "Today",
				thisWeek: "This week",
				thisMonth: "This month",
				thisYear: "This year",
				custom: "Custom",
				periods: "Periods",
				compare: "Compare",
				appfinished: "Validated",
				appfinishcanceled: "Validated / Cancelled",
				appcanceled: "Cancelled",
				absences: "Absences",
				top100: "Top 100",
				top3: "Top 3",
				rangestart: "Beginning of the period",
			//	users: "Clients",
			//	newusers: "Nouveaux clients",
				users: "Users",
				newusers: "New users",
				leftusers: "Unsubscribed users",
				newexit: "News / Unsubscribed",
				topage: "Top age range",
				topgender: "Top gender",
				totalused: "Total used",
				toprange: "Most used product's range",
				topranges: "Most used product's ranges",
				toptypes: "Most types used",
				topmanufacturer: "Most brand used",
				topstock: "Higher quantity of products used",
				attendance: "Attendance",
				attendancerate: "Attendance rate",
				totalhours: "Total working hours",
				totaldays: "Total working days",
				topworkhours: "Most hours worked",
				bestworker: "Most active worker",
				topwork: {
					hour: "Most productive hour",
					day: "Most productive day",
					month: "Most productive month",
					year: "Most productive year",
				},
				totalsub: "Total customers",
				totalnewsub: "Total new customers",
				totalnewoldsub: "Total new / old customers",
				brand: "Most used brands",
				globaluse: "Global utilisation",
				bestuse: "TOP 1 Utilisation", 
				hours: "Hours",
				days: "Days",
			},
			schedules: {
				seeevent: "See event",
				specify: "Specify time",
				seeon: "See on Administration",
				holidaystat: "Status : ",
			},
			settings: {
				themes: "Themes",
				acctheme: "Account theme",
				schtheme: "Schedule theme",
				language: "Language",
				pastapps: "Show appointment's history",
			},
			customerlist: {
				customers: "customers",
				customb: "customer base",
			},
			customer: {
				visits: "Visits",
				colref: "Colors references",
				delete: "Delete this account"
			},
			user: {
				confirmdelete: "Do you really want to delete this customer account ?",
				registered: "Registration date",
				unregistered: "Unegistration date"
			},
			editstaff: {
				automatic: "Automatic",
				cannotdeleteself: "Unable to delete your own account.",
				cannotdeletemanager: "Unable to delete the manager account.",
				mustbeadmin: "You must be an administrator to perform this action.",
			},
			vacation: {
				confirmdelete: "Do you really want to remove and cancel this vacation request ?",
				confirmredirect: "Do you really want to edit this vacation request ? This action will delete the current request and redirect you to the Schedules page to make a new request."
			},
			pack: {
				confirmdelete: "Do you want to delete ?",
			},
			modals: {
				finalize: {
					finalize: "Validate",
				},
				confirm: {
					title: "confirmation",
				},
				editservice: {
					alternates: "Alternates times",
					alternatessub: "example : Highlighting",
					onlyquote: "Only on quotation",
					hasdependency: "There is a formulas that depends on this service. Please delete it first.",
				},
				editstaff: {
					usernamemustbeunique: "The username field must be unique.",
				},
			},
			sms: {
				confirmdelete: "Do you really want to delete this broadcast message ?",
				charleft: "Left: {count} / {max}",
				now: "In 30 minutes",
				scheduled: "Scheduled"
			},
			emails: {
				forgotpwd: {
					subject: "",
					body: "",
				}
			},
		},
		fr: {
			lang: "Français",
			icons: [
				"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAYCAYAAACbU/80AAAAe0lEQVRIiWPw05kt7qY/77Kb/rz/+HDTxLkk4aVcXHjxcm7uy8u4ucUZiLGcFg6AOYIBZkGa0ye8+OuP3yThp7KyeDHMEaMOGHXAqANGHTDqgFEHjDpg1AGjDhgUDhiwRukybu5zDH46s8VdDeZdpbcDlnFzn1vGzS0OADUyRmnjA/hwAAAAAElFTkSuQmCC",
			],
			time: {
				defaultformat: "DD MMM YYYY HH:mm",
				weekdays: [ "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche" ],
				shortweekdays: [ "lun.", "mar.", "mer.", "jeu.", "ven.", "sam.", "dim." ],
				morning: "Matin",
				afternoon: "Après-midi",
				range: "Du {start} au {end}",
				rangeToEndOfDay: "Du {start} au {end} (fin de journée)",
				rangeFromStartOfDay: "Du {start} (début de journée) au {end}",
				exact: "Le {date}",
				days: "{count} jours | {count} jour | {count} jours",
				hours: "heure | heures",
				minutes: "minute | minutes",
				hrs: "heures",
				min: "min",
				shorthours: "{count}h",
				shorttime: "{h}h{m}m",
				age: "{count} ans",
				agerange: "{start} - {end} ans"
			},
			data: { // Related to database
				subscription: {
					starter: "Starter",
					premium: "Premium",
					multi: "Multi"
				},
				genders: {
					male: "Homme",
					female: "Femme",
					child: "Jeune"
				},
				genders_short: {
					m: "H",
					f: "F",
					c: "J"
				},
				agegroups: {
					under25: "Moins de 25 ans",
					range2545: "Entre 25 et 45 ans",
					over45: "Plus de 45 ans",
				},
				hairlength: {
					short: "Courts",
					half: "Mi-longs",
					long: "Longs",
				},
				servicesstep: {
					hairdresser: {
						"1": "Préparation",
						"2": "Coupe",
						"3": "Bac"
					},
					barber:  {
						"101": "Préparation",
						"102": "Coupe",
						"103": "Bac"
					},
					beauty: {
						"201": "Épilation",
						"202": "Soin visage",
						"203": "Soin corps",
						"204": "Mise en beauté",
						"205": "Onglerie",
					},
					spa:  {
						"301": "Étape 1",
						"302": "Étape 2",
						"303": "Étape 3"
					},
				},
				servicestype: {
					"1": "Seulement en forfait",
					"2": "À la carte & en forfait",
				//	"3": "Basique",
				//	"4": "Supplément",
					"-1": "Technique",
				},
				stockstypes: [
					"Masque",
					"Soin",
					"Sérum",
					"Coloration permanente",
					"Coloration naturelle",
					"Coloration temporaire",
					"Raviveur de couleur",
					"Oxydant",
					"Décolorant",
					"Lissage",
					"Mousse coiffante",
					"Gel",
					"Cire",
					"Lait de Coiffage",
				],
				stockscolors: [
					"Noir", 
					"Châtain foncé",
					"Châtain",
					"Châtain clair",
					"Blond foncé",
					"Blond",
					"Blond clair",
					"Blond très clair",
					"Blond platine",
				],
				absences: {
					"": "En attente",
					"validated": "Validé",
					"refused": "Refusé",
					"canceled": "Annulé"
				},
				messagesstatus: {
					sent: "Envoyé"
				},
				notifications: {
					1: {
						filters: ['appointments', 'users'],
						message: `{user} {action} ${anappoint} pour le {data.date}`,
						action: 'a pris',
					},
					2: {
						filters: ['appointments', 'users'],
						message: `{worker} {action} ${anappoint} pour {data.user} pour le {data.date}`,
						action: 'a pris',
					},
					3: {
						filters: ['appointments', 'cancelled', 'users'],
						message: `{user} {action} ${selfappoint} du {data.date}`,
						action: 'a annulé',
					},
					4: {
						filters: ['appointments', 'cancelled', 'users'],
						message: `{worker} {action} le rendez-vous de {data.user}`,
						action: 'a annulé',
					},
					5: {
						filters: ['appointments', 'edits'],
						message: `{user} {action} ${anappoint}`,
						action: 'a modifié',
					},
					6: {
						filters: ['appointments', 'edits', 'users'],
						message: `{worker} {action} le rendez-vous de {data.user}`,
						action: 'a modifié',
					},
					21: {
						filters: ['appointments', 'users'],
						message: `{worker} {action} le rendez-vous de {data.user}`,
						action: 'a terminé',
					},
					7: {
						filters: ['users'],
						message: '{user} {action} un avis',
						premium: true,
						admin: true,
						action: 'a posté',
					},
					27: {
						filters: ['users', 'other'],
						message: "Le service modération {action} de l'avis de {data.user}",
						premium: true,
						admin: true,
						action: 'a validé la suppression',
					},
					28: {
						filters: ['users', 'other'],
						message: "Le service modération {action} de l'avis de {data.user}",
						premium: true,
						admin: true,
						action: 'a refusé la suppression',
					},
					8: {
						filters: ['edits', 'users', 'edits'],
						message: `{worker} {action} le compte de {user}`,
						action: 'a modifié',
					},
					9: {
						filters: ['edits', 'users', 'edits'],
						message: `{worker} {action} le compte de {data.name}`,
						action: 'a modifié',
					},
					10: {
						filters: ['edits'],
						message: `{worker} {action} sa couleur de thème`,
						action: 'a changé',
					},
					11: {
						filters: ['edits'],
						message: `{worker} {action} sa couleur de planning`,
						action: 'a changé',
					},
					12: {
						filters: ['users'],
						message: `{user} {action} son compte`,
						action: 'a supprimé',
					},
					13: {
						filters: ['users'],
						message: `{data.user} {action} un compte`,
						action: "s'est créé",
					},
					14: {
						filters: ['users'],
						message: `{worker} {action} un compte pour {data.user}`,
						action: 'a créé',
					},
					15: {
						filters: ['holidays'],
						message: '{worker} {action} de congé(s)',
						admin: true,
						action: 'a fait une demande',
					},
					25: {
						filters: ['holidays'],
						message: '{worker} {action} un congé',
						admin: true,
						action: 'a posé',
					},
					23: {
						filters: ['holidays'],
						message: '{worker} {action} votre demande de congé',
						self: true,
						selfid: 'worker',
						action: 'a accepté',
					},
					24: {
						filters: ['holidays'],
						message: '{worker} {action} votre demande de congé',
						self: true,
						selfid: 'worker',
						action: 'a refusé',
					},
					16: {
						filters: ['appointments', 'users'],
						message: `{data.user} {action} au rendez-vous du {data.date}`,
						action: "n'est pas venu",
					},
					17: {
						filters: ['stocks'],
						message: 'Il reste moins de 5 {data.name} {data.color} (réf {data.reference})',
						stocks: true,
					},
					22: {
						filters: ['stocks'],
						message: 'Il reste moins de 10 {data.name} {data.color} (réf {data.reference})',
						stocks: true,
					},
					18: {
						filters: ['stocks', 'edits'],
						message: '{worker} {action} le stock {data.name} {data.color} (réf {data.reference})',
						stocks: true,
						action: 'a modifié',
					},
					19: {
						filters: ['stocks'],
						message: '{worker} {action} un stock ({data.name} {data.color}, réf {data.reference})',
						stocks: true,
						action: 'a créé',
					},
					20: {
						filters: ['stocks'],
						message: '{worker} {action} un stock',
						stocks: true,
						action: 'a supprimé',
					},
					26: {
						filters: ['other'],
						message: '{action} envoyée avec succès ! ({data.pushcount} notifications, {data.smscount} SMS, {data.price}€)',
						admin: true,
						action: 'Campagne marketing',
					},
					update: {
						filters: ['other'],
						message: "Une nouvelle {action} est disponible ! Cliquez ici pour l'installer",
						action: 'mise à jour',
						mendatory: true,
					}
				},
				notificationfilters: {
					all: { name: 'Tout' },
					cancelled: { name: 'Annulations' },
					edits: { name: 'Modifications' },
					appointments: { name: `${appoints}` },
					stocks: { name: 'Stocks', stocks: true },
					holidays: { name: 'Congés' },
	// 				staff: { name: 'Équipe' },
					users: { name: 'Utilisateurs' },
					other: { name: 'Autre' },
				},
			},
			errors: {
				timeout: "Délais d'attente dépassé. Veuillez réessayer",
				notfound: "Page non trouvée",
				missingtoken: "Vous devez d'abord vous connecter",
				missing: "Veuillez remplir tous les champs",
				missingfield: {
					salonid: "Le champ 'salonid' doit être précisé",
					userid: "Le champ 'userid' doit être précisé",
					text: "Le champ 'text' doit être précisé",
				},
				min2services: "Veuillez choisir au moins deux prestations",
				toolong: "Une des valeurs envoyées est trop longue",
				nosalon: "Vous devez préciser un identifieur de salon",
				badsubscription: "Votre abonnement ne vous permet pas de faire cette action",
				noid: "Vous devez préciser un identifieur",
				missingfields: "Certains champs sont manquants dans le formulaire",
				argon2: "Une erreur inconnue est survenue",
				keynotallowed: "Une des clés données n'est pas autorisée",
				baddomain: "Le domaine est invalide",
				nomessage: "Aucun message spécifié",
				invaliddate: "Date invalide",
				nogender: "Aucun genre spécifié",
				noage: "Aucune tranche d'âge spécifiée",
				security: {
					cannotget: "Vous n'avez pas la permission de faire une requête GET sur ce chemin",
					cannotpost: "Vous n'avez pas la permission de faire une requête POST sur ce chemin",
					cannotpatch: "Vous n'avez pas la permission de faire une requête PATCH sur ce chemin",
					cannotdelete: "Vous n'avez pas la permission de faire une requête DELETE sur ce chemin",
					cannotpatchbatch: "Vous n'avez pas la permission de faire une requête batch PATCH sur ce chemin",
					cannotdeletebatch: "Vous n'avez pas la permission de faire une requête batch DELETE sur ce chemin",
				},
				business: {
					badsiret: "Le numéro de SIRET donné est invalide",
					cannotcreate: "Impossible de créer l'entré dans la base de donnée",
				},
				values: {
					number: {
						invalid: "Le nombre spécifié est invalide",
						onlyinteger: "Le nombre spécifié ne peut pas avoir de virgule",
						numberonly: "La valeur spécifiée doit être un nombre",
						onlypositive: "Les valeurs numériques doivent être positive",
						"5more": "Les durées doivent être d'au moins 5 minutes"
					},
					string: {
						toolong: "Un des champs est trop long",
					},
					time: {
						noncontiguous: "Les durées ne doivent pas contenir de trou",
						invalid: "La date/heure spécifiée est invalide",
						nopast: "La date/heure spécifiée ne peut pas être dans le passé"
					},
					daterange: {
						inverted: "La date de début se trouve après la date de fin"
					},
					constraints: {
						unique: {
							workers_username: "Ce nom d'utilisateur est déjà utilisé"
						}
					}
				},
				schedule: {
					badtiming: "Certains horaires se chevauchent ou sont invalides"
				},
				worker: {
					mismatch: "Vous ne pouvez pas accéder aux données des autres collaborateurs",
					hidden: "Le collaborateur donné est caché",
					notfound: "Le collaborateur n'existe pas",
					generalusernamereserved: "Vous ne pouvez pas nommer un compte 'general' ou 'salon'",
					generalusernamefixed: "Le compte salon ne peut pas changer de nom d'utilisateur",
					generaladminfixed: "Le compte salon ne peut pas être administrateur",
					emptypasswordcheck: "Vous devez confirmer le mot de passe",
					cantdeleteadmin: "Vous ne pouvez pas supprimer un compte administrateur",
					cantdeletegeneral: "Vous ne pouvez pas supprimer le compte salon",
					cantdeletemanager: "Vous ne pouvez pas supprimer le compte gérant",
					cantdeleteself: "Vous ne pouvez pas supprimer votre compte",
					adminonly: "Vous devez être administrateur pour effectuer cette action",
					invalidpassword: "Le mot de passe donné est invalide",
					usernamealnumonly: "Le nom d'utilisateur doit contenir uniquement des caractères alphanumériques (a-z et 0-9)"
				},
				pass: {
					mismatch: "Les nouveaux mots de passe ne correspondent pas",
					missing: "Veuillez remplir tous les champs",
					weak: "Le nouveau mot de passe est trop faible",
					wrong: "Mot de passe erroné"
				},
				user: {
					notfound: "L'utilisateur n'existe pas",
					mismatch: "Vous ne pouvez pas accéder aux données des autres clients",
					appphoneexists: "Il existe déjà un compte avec ce numéro de téléphone sur l'application",
					appemailexists: "Il existe déjà un compte avec cette adresse email sur l'application",
					phoneexists: "Un utilisateur avec le même numéro de téléphone existe",
					emailexists: "Un utilisateur avec la même adresse email existe",
					banned: "Vous êtes banni",
					alreadyactive: "L'utilisateur est déjà validé",
					codeexpired: "Le code est expiré",
					codeinvalid: "Le code est invalide",
				},
				client: {
					notfound: "Cet identifiant n'existe pas"
				},
				formula: {
					notfound: "La formule n'existe pas",
				},
				service: {
					notfound: "Le service n'a pas été trouvé",
					notworker: "Le collaborateur ne peut pas réaliser cette prestation",
				},
				appointment: {
					invaliddate: "La date est invalide",
					noworker: "Aucun collaborateur n'est spécifié",
					notimeline: "Aucune chronologie n'est spécifié",
					overlap: `Il existe ${anappoint} dans le créneau horaire sélectionné`,
					notfound: `${pronoml} ${appoint} est introuvable`,
					cannotdelete: "Impossible de supprimer un rendez-vous",
				},
				batch: {
					onlysortingorapp: "Vous ne pouvez que éditer la visibilité et la priorité de plusieurs prestations",
					onlyseen: "Seulement le champ 'seen' peut être modifié par batch,le champs 'salonid' doit être spécifié",
					nobatch: "Impossible de faire une requête batch",
				},
				review: {
					notfound: "L'avis n'existe pas",
					deletebadowner: "Vous devez être auteur de cet avis pour le supprimer",
					neverwent: `Vous devez avoir déjà avoir eu au moins ${anappoint} dans ce salon pour pouvoir poster un avis`,
					alreadydisputed: "Cet avis a déjà été contesté",
					noreason: "Aucune raison spécifiée"
				},
				salon: {
					notfound: "Le salon n'existe pas",
					mismatch: "Vous ne pouvez pas accéder à ce salon",
					readonly: "Vous ne n'avez qu'un accès de lecture sur ce salon",
				},
				invalid: {
					email: "L'adresse email donné est invalide",
					phone: "Le numéro de téléphone donné est invalide",
					birthdate: "La date de naissance donnée est invalide",
					phoneexists: "Le numéro de téléphone donné est déjà lié à un compte",
					emailexists: "L'adresse email donnée est déjà liée à un compte",
					appphoneexists: "Le numéro de téléphone donné est déjà lié à un compte sur l'application",
					appemailexists: "L'adresse email donnée est déjà liée à un compte sur l'application",
					timeline: "La chronologie donnée est invalide",
					clienttype: "Votre type de client est invalide",
				},
				invoice: {
					notfound: "La facture n'existe pas",
				},
				campaign: {
					norecipient: "Aucun destinataire trouvé",
				},
			},
			titles: {
				appname: `${namep}`,
				menu: "Menu",
				appoint: `${tappoints}`,
				admin: "Administration",
				calendar: "Calendrier",
				customer: "Client",
				customers: "Clientèle",
				dashboard: "Tableau de bord",
				connect: "Connexion",
				disco: "Déconnexion",
				hairdressers: "Salons",
				notifs: "Notifications",
				reviews: "Avis",
				settings: "Réglages",
				schedule: "Absences",
				stocks: "Stocks",
				stockssearch: "Recherche - Stocks",
				statistics: "Statistiques",
				error: "Erreur",
				productused: "Stock(s) utilisé(s)",
				summary: "Récapitulatif",
				nextvisit: `${next} ${appoint}`,
				history: {
					basic: `${appoints}`,
					today: `${appoints} de ce jour`,
					month: `${appoints} ce mois-ci`,
					year:  `${appoints} cette année`,
				},
				login: "Se connecter",
			},
			common: {
				others: 'Autres',
				organizer:"Agenda",
				unchanged: "Image (ne rien mettre pour laisser inchangé)",
				browse: "Parcourir ...",
				add: "Ajouter ...",
				networkerror: "Erreur réseau",
				reload: "Recharger",
				all: "Tout",
				app: "App",
				thedate: "Le",
				dashboard: "tableau de bord",
				appointment: `${appoint}`,
				appoints: `${appoints}`,
				enterprise: "salon",
				account: "Compte",
				salon: "Salon",
				for : "pour",
				with: "avec",
				cancel: "Annuler",
				none: "Aucun",
				newapp : `${newappoint}`, 
				nextapp: `${next} ${appoint}`,
				nextappoint: `${nextapp}`,
				appinprogress: `${tappoints} en cours`,
				validatetaken: `${appval}`,
				month: "ce mois-ci",
				appday : `${tappoints} de ce jour`,
				appmonth: `${tappoints} ce mois-ci`,
				appmod: `${appmod}`,
				appcancel: `${appcancel}`,
				year: "cette année",
				appyear: `${tappoints} cette année`,
				client: "Client",
				worker: "Collaborateur",
				allworkers: "Tous",
				anonymous: "Anonyme | Anonymes",
				create: "Créer",
				createclient: "Créer nouvelle fiche client",
				registered: "Clients inscrits",
				lactivities: "Dernières activités",
				reviews: "Avis",
				dispute: "Contester",
				week: "Semaine",
				day: "Journée",
				modified: "modifié",
				legend: "Légende",
				general: "Général",
				dateand: "Date et",
				date: "Date",
				and: "et",
				hour: "heures",
				hours:"Heures",
				min: "minutes",
				staff: "Collaborateur",
				customer: "client",
				customers: "clients",
				category: "Catégorie",
				close: "Fermer",
				seeall: "Voir tout",
				seemore: "Voir plus",
				seeless: "Voir moins",
				login: "login",
				name: "Nom",
				price: "Prix",
				wom: "Femmes",
				woman: "Femme",
				men: "Hommes",
				man: "Homme",
				young: "Jeune",
				youth: "Jeunes",
				duration: "durées (minutes)",
				durate:"Durée",
				action: "Action",
				dependencies:"Dépendances",
				dependence: "Dépendance",
				workt: "temps de travail",
				btime: "temps de pause",
				ex: "exemple",
				col: "une couleur",
				hair: "Cheveux",
				dry: "séchage",
				save: "Sauvegarder",
				save2: "Enregistrer",
				order: "ordre",
				ordserv: "Ordre",
				onlyfor: "pour",
				usern: "Nom d'utilisateur",
				email: "E-mail",
				pwd: "Mot de passe",
				yourpwd: "Votre mot de passe",
				currentpwd: "Mot de passe actuel",
				npasswd: "Nouveau mot de passe",
				confirmpwd: "Confirmer le nouveau mot de passe",
				forgotpwd: "Mot de passe oublié ?",
				requestsent: "Demande envoyée",
				adminacc:"Compte administrateur",
				manageracc:"Compte gérant",
				generalacc:"Compte salon",
				infos: "Informations générales",
				secu: "Sécurité",
				confirm: "Confirmer",
				status: "Statut",
				message: "Message",
				schedule: "planning",
				request: "Demande de congé(s) / absence(s)",
				requestadm: "Congé(s) / Absence(s)",
				type: "Type",
				step: "Étape",
				visibility: "Visiblité",
				brand: "Marque",
				brands: "Marques",
				tref: "cette référence",
				ref: "réf.",
				descript: "Description",
				op: "Ouvert",
				mod: "Modifier",
				tweek: "cette semaine",
				tyear: "cette année",
				users: "Utilisateurs",
				phone: "Téléphone",
				search: "Recherche",
				searcusto: "recherche client",
				gender: "Genre",
				genders: "Genres",
				lname: "Nom de famille",
				fname: "Prénom",
				datebirth: "Date de naissance",
				loyalty: "Points de fidélité",
				points: "0 points | {count} point | {count} points",
				customer: "Client",
				perso: "personnalisé",
				notifs: "notifications",
				mappoint: `a ${reserved} ${anappoint}`,
				cappoint: `a supprimé ${anappoint}`,
				postrev: "a posté un avis",
				showonapp: "Contester",
				sless: "voir moins",
				sms: "campagne marketing",
				ad: "Publicité",
				quote: "sur devis",
				sdate: "Date de début",
				choosesdate: "Choisir la date de début",
				chooseedate: "Choisir la date de fin",
				edate: "Date de fin",
				services: "Prestations",
				service: "Prestation",
				complet: "prestations réalisées",
				pack: "Forfaits",
				package: "Forfait",
				attend: "Présence",
				present: "Présent",
				canceled: "Annulé",
				absent: "Absent",
				missed: "Manqué",
				total: "total",
				reason: "motif",
				reasons: "Motif(s)",
				reasonop:"(facultatif)",
				optional: "(optionnel)",
				login: "Identifiants",
				chpass: "Changer le mot de passe",
				challenge: "Challenge",
				by: "par",
				hol: "Congé(s)",
				holiday: "Congés",
				worksch: "Horaires",
				color: "Couleur",
				bills: "Abonnement",
				website: `Page ${name}`,
				accdel: "compte supprimé",
				question: "Une question ?",
				forbidcust: `Empêcher ce client de ${reserve} en ligne`,
				id: "Id",
				apply:"Appliquer",
				del:"Supprimer",
				dispute: "Contester",
				remember: "Se souvenir de moi",
				cosmetics: "Produits",
				return: "Retour",
				annulation: "Annulation",
				yes:"Oui",
				count:"Total",
				utilstock:"Utilisation des stocks",
				usesstock:"Utilise des stocks",
				emailphone: "Email / Téléphone",
				carte: "À la carte",
				takeappointment: `${reserveapp}`,
				from: "De",
				to: "À",
				next: "Suivant",
				validate: "Valider",
				clientid: "Identifiant",
				age: "Tranches d'âges",
				nouser: "Aucun utilisateur",
				novisit: "Aucune visite",
				notification: "Notification",
				noresult: "Aucun résultat",
				noholiday: "Aucun congé",
				lighten: "Éclaircir",
				darken: "Foncer",
				stockleft: "Il reste ... (%)",
				priceplaceholder: "Aucun prix spécifié",
				temporarycloses: "Fermetures temporaires",
				salontemporaryclose: "Fermeture temporaire du salon"
			},
			index: {
				newreviews: "Nouveaux avis",
				promote: "Mise en avant",
				onthesite: "Contester",
				fini: "Terminé",
				began: "Commencé",
				missing: "Absent",
				surecancel: `Annuler ${pronoml} ${appoint} ?`,
				surereview: "Contester l'avis ?",
				confirmabsent: "Client absent ?",
				sureremoveproduct: "Supprimer la mise en avant ?",
				notstarted: "Non commencé"
			},
			appointments: {
				reduction: "Réduction client",
				confirmcancel: `Voulez-vous vraiment annuler ${pronoml} ${appoint}`,
				selectworker: "Sélectionnez un collaborateur en bas à gauche de la modale pour continuer",
				nonenext: `${nonenext}`,
				nonecurrent: `${nonecurrent}`,
				nonelast: "Aucune activité",
			},
			administration: {
				text:"Souscrivez au rendez-vous en ligne !",
				contracttermination: "Résiliation de l'Abonnement",
				subscribe: "Passer à la formule Premium",
				admin: "Admin",
				administrator: "administrateur",
				explain1: "15 minutes de pause, puis 40 minutes d'attente, puis 5 minutes",
				visible: "visible sur la page de rendez-vous",
				progdate: "Date de Programmation",
				webview: "Voir mon Site Web",
				createsite: "Créer mon Site Web",
				notsup:"Prestations non réalisées",
				team: ( domain == "coachs" ? "Compte" : "Équipe" ),
				fullday: "Journée complète",
				multipromote: "Mises en avant mutiples",
				price: "tarifs (€)",
				total: "Total :",
				time: "\[Temps de travail\]  \[Temps de pause\]  \[Temps de travail\]  \[Temps de pause\]  \[Temps de travail\] ...",
				time2: "Exemple : pour une couleur 15  40  10, 15 minutes de pose, suivies de 40 minutes d'attente, puis 10 minutes de bac/séchage.",
				onlyweb: "Uniquement à titre indicatif sur l'application",
				address1: "Adresse",
				address2: "CP / Ville",
				subapp: `Modifier ma Page ${name}`,
				infos: "Informations",
				services: "Prestations",
				packages: "Forfaits",
				schedules: "Horaires",
				gallery: "Galerie",
				term: "Résiliation",
				cancelall: "Annuler les Rendez-vous",
				cancelallconfirm: "Annuler les Rendez-vous ?",
				cancelapp: `Supprimer ma Page ${name}`,
				viewapp: "Voir ma Page Espace-Temps",
				contactinfos: "Coordonnées",
				technic: "Technique",
				closure: "Clôture temporaire du calendrier de rendez-vous en ligne",
				anticipated: "Veuillez anticiper la fermeture le plus tôt possible ",
				lowest:"À partir de ...",
				highest:"Jusqu'à ...",
				range: "Entre ... et ...",
				noprices: "Ne pas afficher de prix",
				reviewsandpromote: "Avis et Mise en avant",
				seedashboard: "Voir Tableau de Bord",
				salonclose: "Fermeture temporaire | Fermetures temporaires",
				fromaddress: "Trouver à partir de l'adresse",
				dblclickplace: "Double cliquer pour spécifier une autre position",
				locationservicemsg: "Localisation <a href='https://adresse.data.gouv.fr/' target='_blank'>Etalab</a>",
				sorting: `Le système de planning à trous nécessite qu'un **ordre de passage** entre les prestations soit établi. Par exemple, un Shampooing est effectué **avant** une Coupe, mais **après** une Coloration.
				Cette liste représente **de haut en bas** les ordres de passage de la **première prestation à la dernière**, il est possible d'attraper (*maintenir appuyé*) chacune des prestations et de la déplacer afin de changer son ordre de passage.
				**Les prestations avec temps de pauses sont prioritaires**, il n'y a aucune incidence à intervertir un **Séchage** et un **Brushing** entre-eux par exemple.
				Conseil : Si vous deviez réaliser toutes vos prestations avec un seul client, dans quel ordre le feriez-vous ?`,
				salonname: domain == "coachs" ? "Nom affiché sur l'app" : "Nom du salon",
				youthlimit: "Âge limite jeunes",
				passedit: "Veuillez taper votre mot de passe admin",
				noprestation: "Veuillez d'abord créer des prestations",
				websitenoprestation: "Aucune prestation",
				websitenopack: "Aucun forfait correspondant disponible",
				servonapp: "(Réservables sur l'Application)",
				scope: "Distance max",
			},
			bills: {
				idsalon: "Identifiant Salon",
				infos: "Informations",
				codemulti: "Code Multi-Salon",
				sub: "Abonnement",
				apponline: "Sans rendez-vous en ligne",
				payment: "Changer la méthode de paiement",
				bank: "Banque",
				levy: "Prélèvement",
				card: "Carte Bleue",
				bills: "Factures",
				billof: "Facture du",
				cancelconfirm: "Êtes-vous sûr de vouloir annuler votre abonnement ? Cette action est irréversible.",
				cancelsuccess: "Votre abonnement est désormais clos, vous continuerez à avoir accès à votre Dashboard en lecture-seule pendant les 30 prochains jours, passé ce délai il ne vous sera plus possible de vous y connecter."
			},
			reviews: {
				dispute: {
					reason: "Raison de la contestation",
					offensive: "Propos injurieux, grossiers, vulgaires, menaçants, obscènes, diffamatoires, discriminatoires",
					commercial: "Contenu commercial",
					spam: "SPAM",
				},
				none: "Aucun avis"
			},
			salons: {
				addtitle: "Ajouter un Salon",
				add: "Ajouter un Salon",
			},
			notification: {
				none: 'Aucune notification',
			},
			infiniteloader: {
				noResult: 'Aucun résultat',
				error: 'Erreur lors de la recherche de résultats :(',
			},
			stocks: {
				stocks: "Stocks",
				stock: "Stock",
				barcode: "Code barre",
				cond: "État",
				range: "Gamme",
				ranges: "Gammes",
				color: "Couleur",
				used: "Utilisés",
				remain: "(restant)",
				nfil: "Créer un filtre",
				nnewfilter: "Nom du nouveau filtre",
				cancelfilt: "Supprimer le filtre",
				setfilter: "Réglages du filtre",
				whatev: "Peu importe",
				sout: "En rupture",
				fiveless: "5 et moins",
				tenless: "10 et moins",
				refcode: "Code référence",
				createfilter: "Nouveau filtre",
				createfiltername: "Nom du nouveau filtre",
				initstock: "Stocks initiaux",
				deletefilter: "Supprimer le filtre ?",
				type: "Type",
				usedquantity: "Quantité utilisée (% de la bouteille)",
				remaining: "Restant",
				empty: "Aucun stock",
				remainingstocks: "Stocks restants :",
			},
			statistics : {
				statistics: "statistiques",
				day: "Aujourd'hui",
				month: "Mois ",
				week: "Semaine",
				year: "Année",
				apps: `Total de ${appoints}`,
				appsperhours: `Total de ${appoints} par heure`,
				appsperhour: `${appoints} par heure`,
				onlineperhour: `${reservations} en ligne par heure`,
				rpart: "fréquentation",
				details: "Détails",
				tempo: "Temporalités",
				hdressorphone: "Hors site",
				online: "En ligne",
				rush: "Pic d'affluence",
				offpeak: "Heure creuse",
				total: "Total",
				comp: "Comparatif",
				general: "Général",
				resume: "Résumé",
				inshort: "En bref",
				today: "Aujourd'hui",
				thisWeek: "Cette semaine",
				thisMonth: "Ce mois-ci",
				thisYear: "Cette année",
				custom: "Personnalisé",
				periods: "Temporalités",
				compare: "Comparatif",
				appfinished: `${validates}`,
				appfinishcanceled: `${validatesorcancel}`,
				appcanceled: `${canceled}`,
				absences: "Absences",
				top100: "Top 100",
				top3: "Top 3",
				rangestart: "Début de la période",
			//	users: "Clients",
			//	newusers: "Nouveaux clients",
				users: "Utilisateurs",
				newusers: "Nouveaux utilisateurs",
				leftusers: "Utilisateurs désinscrits",
				newexit: "Nouveaux / désinscrits",
				topage: "Tranche d'âge majoritaire",
				topgender: "Genre majoritaire",
				totalused: "Total utilisé",
				toprange: "Gamme la plus utilisée",
				topranges: "Gammes les plus utilisées",
				toptypes: "Types les plus utilisés",
				topmanufacturer: "Marque la plus utilisée",
				topstock: "Plus forte quantité de stocks utilisés",
				attendance: "Présence",
				attendancerate: "Taux de présence",
				totalhours: "Total d'heures travaillées",
				totaldays: "Total de jours travaillés",
				topworkhours: "Plus grand nombre d'heures travaillées",
				bestworker: "Collaborateur le plus actif",
				topwork: {
					hour: "Heure la plus productive",
					day: "Journée la plus productive",
					month: "Mois le plus productif",
					year: "Année la plus productive",
				},
				totalsub: "Total clients",
				totalnewsub: "Total nouveaux clients",
				totalnewoldsub: "Total nouveaux / anciens clients",
				brand: "Marques les plus utilisées",
				globaluse: "Utilisation globale",
				bestuse: "Utilisation TOP 1", 
				hours: "Heures",
				days: "Jours",
			},
			schedules: {
				seeevent: "Voir évènement",
				specify:"Spécifier l'heure",
				seeon:"Voir dans Administration",
				holidaystat:"Statut : ",
				confirmaccept: "Voulez-vous vraiment accepter ce congé ?",
				confirmdeny: "Voulez-vous vraiment refuser ce congé ?",
				confirmcancel: "Voulez-vous vraiment annuler ce congé ?",
			},
			settings: {
				themes: "Thèmes",
				acctheme: "Thème compte",
				schtheme: "Thème planning",
				language: "Langue",
				pastapps: "Afficher l'historique des séances",
				specialhours: "Horaires personnalisés",
				specialmorning: "Avant ouverture",
				specialevening: "Après fermeture",
				specialhourstip: "Ces paramètres permettent d'effecturer des prestations en dehors des horaires indiqués, cela ne s'applique à la prise de séances en ligne",
				group: "Groupe",
				nogroup: "Aucun groupe",
				newgroup: "Nouveau groupe",
				groupname: "Nom du groupe",
			},
			customerlist: {
				customers: "clients",
				customb: "liste clients",
			},
			customer: {
				visits: "Visites",
				colref: "Références couleurs",
				delete: "Supprimer ce compte"
			},
			user: {
				confirmdelete: "Voulez-vous vraiment supprimer le compte de ce client ?",
				registered: "Date d'inscription",
				unregistered: "Date de désinscription",
				favorite_esthetics_hairdresser: "Vous êtes le salon de coiffure favori de ce client !",
				favorite_esthetics_barber: "Vous êtes le barbier favori de ce client !",
				favorite_esthetics_beauty: "Vous êtes le salon d'esthétique favori de ce client !",
				favorite_esthetics_spa: "Vous êtes le spa favori de ce client !",
				favorite_coach_lifestyle: "Vous êtes le coach Life-style favori de ce client !",
				favorite_coach_sport: "Vous êtes le coach Sportif favori de ce client !",
				favorite_coach_care: "Vous êtes le coach Bien-être favori de ce client !",
			},
			editstaff: {
				automatic: "Automatique",
				cannotdeleteself: "Impossible de supprimer votre propre compte.",
				cannotdeletemanager: "Impossible de supprimer le compte gérant.",
				mustbeadmin: "Vous devez être administrateur pour effectuer cette action.",
			},
			vacation: {
				confirmdelete: "Voulez-vous vraiment supprimer et annuler cette demande de congé ?",
				confirmredirect: "Voulez-vous vraiment modifier cette demande de congé(s) ? Attention, celle-ci sera supprimée et vous serez redirigé vers la page Plannings pour effectuer une nouvelle requête."
			},
			pack: {
				confirmdelete: "Voulez-vous supprimer ?",
			},
			sms: {
				confirmdelete: "Voulez-vous vraiment supprimer ce message de diffusion ?",
				charleft: "Restant: {count} / {max}",
				now: "Dans 30 minutes",
				scheduled: "Programmé"
			},
			emails: {
				forgotpwd: {
					subject: /* TEST */ "Réinitialisation de mot de passe",
					body: /* TEST (ATTENTION: laisser [[password]] tel quel, il sera remplacé par le mail généré au moment de l'envoi) */ `${mail}<br /><br />
					Vous avez demandé une réinitialisation de mot de passe, pensez à changer celui-ci dès votre prochaine connexion.<br /><br />
					Mot de passe : [[password]]<br /><br />
					Pour toutes questions, contactez nous à <a href="mailto:contact@${mail}">contact@${mail}</a><br /><br />
					Cordialement,<br />
					L'équipe ${name}<br /><br />&nbsp;`,
				},
				cancellation: {
					subject: /* TEST */`Résiliation ${name}`,
					body: /* TEST */ `${name}<br /><br />
					Vous avez demandé la résiliation de votre offre<br /><br />
					Celle-ci prend effet immédiatement, nous espérons malgré tout vous revoir très bientôt.<br /><br />
					Pour toutes questions, contactez nous à <a href="mailto:contact@${mail}">contact@${mail}</a><br /><br />
					Cordialement,<br />
					L'équipe ${name}<br /><br />&nbsp;`,
				}
			},
			modals: {
				finalize: {
					finalize: "Valider",
				},
				confirm: {
					title: "confirmation",
				},
				editservice: {
					hairlength: "Diffère selon la longeur de cheveux",
					alternates: "Durées alternatives",
					alternatessub: "exemple : Éclaircissement",
					onlyquote: "Seulement sur devis",
					hasdependency: "Cette prestation est utilisée dans un forfait, veuillez le supprimer d'abord.",
					description: "description (optionnel)",
					message: "message (optionnel)",
					questiontext: "Cette prestation requiert une réponse obligatoire de la part du client",
					question: "Votre question",
				},
				editstaff: {
					usernamemustbeunique: "Le nom d'utilisateur doit être unique.",
				},
				editstock: {
					addcolor: "Ajouter une couleur",
					addtype: "Ajouter un type"
				},
				questions: {
					searchbar: "Rechercher...",
					title: "Une question ?",
					contact: "Nous contacter",
					phone: "+33769565759",
					email: `contact@${mail}`,
					// prettier-ignore
					questions: [
						{
							question: `## Qu'est-ce que la solution **[${name}](${link})** ?`,
							response: `[${name}](${link}) PRO est une ***Solution de gestion de ${schedule}*** comportant un **agenda** de rendez-vous connecté, ${domain == "esthetics" ? "un **planning** d'équipe, une gestion des **stocks** produits" : ''}, un suivi général d'activité avec **notifications**, ainsi qu'un **suivi particulier et instantané des activités** (${next} ${appoint} et ${appoint} ${finished}).`,
							tips: [`\n\nAfin de renforcer ton identité, une **Page App** personnalisable y est associée..`]
						},
				{
					question:"## Depuis quels **[appareils]()** utiliser la solution ?",
					response: "La solution a été conçue de manière optimisée pour les **tablettes**, néanmoins nous avons développé une **version mobile** et **ordinateur** également ! Vous pouvez donc l'utiliser depuis n'importe quel appareil et travailler avec l'outil de votre choix.",
							tips: [`**Petite astuce** : Concernant la *version mobile*, le **[${name}](${link})** ramènera toujours à la page du **[Tableau de bord](#/dashboard)**.`],
				},
				{
					question:`## Comment gérer les **[${reservations}]()** ?`,
					response: salonDesc => `\
Les deux onglets principaux concernant les ${appoints} sont le [Tableau de bord](#/dashboard) \
(pour l'aspect temporel instantané : les ***cards*** (*${next} ${appoint}* et *dernières activités*)) \
et la page [${appoints}](#/appointments) (aspect temporel étendu : le calendrier).\n\
**Ces deux pages sont accessibles** directement depuis la **barre principale du menu** qui se trouve en \
**haut de l'écran**, afin de faciliter leur accès.\n\n\
- Pour *valider* ${aappoint}, cette action s'opère sur le \
[Tableau de bord](#/dashboard) : cliquez sur *Commencé* puis *Terminé*. \
${salonDesc.beauty || domain=="coachs" ? '' :
`Lorsqu'une **prestation associée aux stocks** (Abonnement **Premium** ou **Multi** uniquement) \
est *terminée*, une fenêtre apparaît et requiert la **sélection du produit utilisé**.\n\
Si **plusieurs produits** sont \
**ouverts**, cliquez sur **+**. Si **plusieurs produits** sont **utilisés**, cliquez sur *Ajouter*.`
}\n\
- Vous pouvez \
*annuler* ou *modifier*  ${aappoint} **depuis ces deux mêmes pages** en cliquant sur ${theappoint} sélectionné, \
les **informations client y sont** par ailleurs **affichées**.\n\n- Pour prendre ${pronom} **${newappoint}**, vous \
trouverez le **bouton fixe en bas de page** [${newappoint}](#/), qui apparaît aussi **sur toutes les pages**.`,
							tips: [`Vous pouvez **afficher** ou non **l'historique des ${appoints}** directement **sur le calendrier** en *activant*  le ***toggle*** *(voir légende)*  sur la page [Réglages](#/settings).`],
				},
				{
					question:`## Où trouver l'**[Historique]()** des ${appoints} ?`,
					response:`L'historique des rendez-vous apparaît depuis les ***cards*** (*[${appoints} de ce jour]()*, *[${appoints} de ce mois]()*, *[${appoints} de cette année]()*) du [Tableau de bord](#/dashboard).  \n*exemple :* Si vous cliquez sur [${appoints} de ce jour](), celle-ci affichera l'historique (prestations, clients et horaires) de vos ${appoints} d'aujourd'hui.`,
					tips: domain=="coachs" ? [] : ["Pour les abonnements ***Multi***, vous trouvez la même fonctionnalité dans l'onglet [Salons](#/salons)."],
				},
				{
					question:"## Comment gérer mes **[Congés](#/schedules)** ?",
					response:`Les congés s'administrent depuis l'onglet [Plannings](#/schedules) ainsi que depuis les [Réglages](#/settings).  ${domain=="coachs" ? '' : "*\n\n- Le **formulaire de demande** se situant en bas de page [Plannings](#/schedules), les demandes apparaîtront de façon transparente sur le calendrier tant qu'elles n'auront pas été validées. Une fois validée, la période demandée se retrouvera alors surlignée sur ce même calendrier."}\n\n- ${domain=="coachs" ? "Les congés peuvent être *modifiés* ou *supprimés* dans les [Réglages](#/settings) du menu déroulant " : "Les demandes de congés quant à elles peuvent être *modifiées* ou *supprimées* dans les [Réglages](#/settings) du menu déroulant, vous y trouverez ainsi la liste des demandes acceptées"}.\n Cliquez sur l'icône correspondante à l'action.\n\n- **Attention :** Si vous *modifiez* un congé, ceci entraînera **sa suppression** et vous ramènera au [Plannings](#/schedules) afin de formuler une nouvelle requête.`,
					tips: domain=="coachs" ? [] : ["Vous pouvez suivre la liste des congés de vos collaborateurs dans la section Congés de l'onglet Administration, et ainsi y accepter ou refuser les requêtes."],
				},
				{
					question:"## Comment gérer les **[Stocks](#/stocks)** produits ? (Abonnement **Premium** ou **Multi** uniquement)",
					response:"La section [Stocks](#/stocks) du menu déroulant vous donne la possibilité de *créer* ou de *modifier* les stocks de vos produits. Vous pouvez voir **le nombre** des stocks et surveiller **l'État** des **produits ouverts**.  \n - Accompagnée d'un outil ***Filtre*** de six caractéristiques (*Type*, *Couleur*, *Marque*, *Description*, *Code référence*, *Code barre*) ainsi que le *Nombre restant*, la **recherche** est instantanée et vous permet de *créer* (puis de *modifier* ou *supprimer*) **par vous-même** un filtre afin de vous **organiser** davantage quant à une **marque**, un **code référence** ou même un **type** de produits.\n \t- Entrez une donnée dans l'un des 6 champs et cliquez sur *Créer un filtre* . Celui-ci **apparaîtra** ainsi **au-dessus de la section de recherche**. \n\n \t- Pour le *supprimer*, sélectionnez le filtre créé et vous trouverez par la suite en **bas de page** un bouton *Supprimer le filtre* à cet effet. \n\n - Pour *ajouter* et *paramétrer* un nouveau produit, cliquez sur **+** en bas de page. Pour les  *modifier*, cliquez sur l'icône associé à cet effet (*Voir Légende* ).\n\n \t-  Pour *ajouter* ou *supprimer* une **Couleur** ou un **Type**, entrez directement la donnée désirée et cliquez sur *Ajouter un Type / une Couleur*. \n\n \t- Une **croix** vous permettra de le *retirer*   (*Voir Légende*). \n\n - Le nombre **total** de **stocks** et de **stocks utilisés** se trouve en bas de page.",
					tips: [
						"Vous pouvez ausssi suivre les statistiques d'utilisation dans l'onglet Statistiques."
					],
					hide: salonDesc => salonDesc.beauty || domain=="coachs",
				},
				{
					question:"## Comment créer ou modifier une **[Fiche client](#/userslist)** ?",
							response: salonDesc => `\
Il y a deux façons de créer une fiche client, que ce soit dans l'onglet [Clientèle](#/userslist) du menu \
déroulant ou sur le formulaire [${newappoint}](), cliquez sur *Créer une nouvelle fiche client*.\n\
*Cliquez* de nouveau si vous souhaitez revenir à votre recherche.\n\n\n- Vous pouvez **rechercher** un client \
dans cette même section [Clientèle](#/userslist) ou directement dans **la barre de recherche** en haut de l'écran.\n\
\n\
- Vous y trouverez les **Informations** client${salonDesc.beauty || domain=="coachs" ? '' : ', mais aussi **Références couleurs**'} \
et **Historiques de ${visits}** \
(notamment la **${next} ${appoint}**). Un raccourci **téléphone** vous permet d'appeler directement le client en \
cliquant sur l'icône associé à cet effet.\n\
\n\
- Pour *modifier* une fiche client, pour pouvez le faire directement sur la **fiche client** concernée, en modifiant \
les sections nécessaires puis en appuyant sur *Enregistrer*.\n\
\n\
- Les **clients inscrits via le site ${name}app** possèdent une fiche client ***non modifiable***, pour *modifier* \
les informations, celles-ci pourront l'être uniquement par le client via ce même site.`,
							tips: [ "Vous pouvez ***empêcher un client de réserver une séance*** et supprimer une fiche client, vous trouvez ces réglages en bas de la fiche du client concerné."],
				},
				{
					question:"## Comment gérer les **[Notifications](#/settings)** ou les **[Informations personnelles](#/settings)**"/* ou même le **[Langage](#/settings)** ?"*/,
					response:"Vous retrouverez **tous les réglages nécessaires** dans l'onglet [Réglages](#/settings) du menu déroulant.\n  - Vous y trouverez aussi les outils de réglages de couleurs, allant du **thème** de [Planning](#/schedules) à celui du **Compte utilisateur**.",
							tips: ["Vous pouvez également y *modifier* ou *supprimer* vos **congés** (voir *Comment gérer mes congés*)."],
				}, 
				],
				// prettier-ignore
				questionsadmin: [
				{
					question:`## **[${account}](#/salons)**`,
					response:`Est-il possible de **partir en vacances** et de **suivre l'activité** de mon ${schedule} ? \n - Vous pouvez suivre l'activité de votre ${schedule} **depuis n'importe quel endroit**, **même à l'étranger**, sous la condition d'une connexion internet **(4G/WIFI)**.\n\n ${domain=="coachs" ? '' : "Est-il possible d'avoir **plusieurs salons** connectés ?\n -  Vous pouvez suivre l'activité principale d'un ou plusieurs salons depuis [Salons](#/salons). Celui-ci est un équivalent du [Tableau de bord](#/dashboard), comportant les **dernières activités** et **historiques** respectivement associés à vos salons, et ce afin de **garder un oeil** sur les multiples activités **où que vous soyez**.\n(Voir *Comment gérer les Rendez-vous*)\n\nComment **connecter** un ou **plusieurs salons** à mon salon ? \n - Vous pouvez ***connecter*** les salons avec vos identifiants se trouvant dans l'[Administration](#/bills), section **Abonnement**.\n - Envoyez le code *multi-salon* **au salon principal** qui l'enregistrera sur la Page [Salons](#/salons) grâce au bouton *Ajouter un Salon* ."}`,
							tips:  domain=="coachs" ? [] : ["*Pour **suivre l'activité de vos salons**, un *Abonnement* **Multi** est nécessaire. (Voir *Abonnements* dans **Administration**)*"],
							//response2:`${domain=="coachs" ? "Comment *parrainer* un **coach** et gagner 1 mois **gratuit** ? Puis-je **cumuler** plusieurs mois ?\n- Vous trouverez votre **code de parrainage** sur l'accueil du Tableau de Bord sous la forme suivante : ***#5f6e17*** par exemple. **Partagez-le** sans réserve ! \nÀ chaque **inscription d'un nouveau coach**, vous gagnerez mutuellement **1 mois d'abonnement** ! Partagez le donc à 6 coachs par exemple, et vous aurez 6 mois gratuits !" : ""}\n\nComment suivre **l'activité totale** de mon ${schedule} ?\n - Vous avez les  [Statistiques](#/stats) afin de suivre les progressions et évolutions de votre activité sur **${two} axes** principaux : Les **${appoints}**,  ${domain=="coachs" ? "" : "Les **Produits,**"} La **Clientèle** ${domain=="coachs" ? "" : "et l'**Équipe**"}. Chacun possède une temporalité réglable.\n(Voir *Les Statistiques*)\n\nComment accéder à mes **factures**, **changer mon abonnement** ou **mon mode de paiement** *${name}* ? \n - Vous trouverez toutes les factures, mais aussi ces sections *modifiables* relatives à votre offre **depuis l'onglet  [Administration - Factures]( #/administration/bills)**.`, //et un outil de comparaison//
							response2:"Comment suivre **l'activité totale** de mon planning ?\n - Vous avez les  [Statistiques](#/stats) afin de suivre les progressions et évolutions de votre activité sur **trois axes** principaux : Les **Réservation**, **votre Activité** et votre **Clientèle**. Chacun possède une temporalité réglable.\n(Voir *Les Statistiques*).",
				},
				{
					question:"## **[Administration : Prestations](#/administration)**",
					response: salonDesc => `\
Comment ***créer*** ou ***modifier*** une **prestation** ?\n\
- Depuis la page [Administration](#/administration), vous avez la possibilité de *créer* \
ou de *modifer* les prestations dans la section [Prestations](#/administration/services).\n\
- Pour *créer* ou *ajouter* une prestation, en bas de page cliquez sur **+**.\n- Pour *modifier* \
une prestation, vous trouverez en bout de chaque ligne une **icône** vous permettant de le faire (voir *légende*).\n\n\
${salonDesc.beauty || domain=="coachs" ? '' :
`À quoi correspond l'**Utilisation des stocks** ? (Abonnement **Premium** ou **Multi** uniquement)\n\
- Ce petit *toggle (voir légende)* permet d'**associer** une **prestation** aux **[Stocks](#/stocks)**.\n\
Lors de la **validation d'un rendez-vous** (*Terminé*) comportant cette prestation, une fenêtre s'ouvrira \
et requerra les **références et dosages des produits utilisés** pour ce service (*Voir Comment gérer les Rendez-vous*).\n\n`
}\
${domain=="esthetics" ?`Que sont **les Étapes** ?\n\
- Nous avons découpé le temps en ${!salonDesc.beauty ? `trois` : `cinq`} parties ordonnées : \
${!salonDesc.beauty && domain=="esthetics" ?
`la **Préparation** d'abord, la **Coupe** ensuite puis le **Bac** pour finir.` :
`**Épilation**, **Mise en beauté**, **Soin visage** et **Soin corps**, puis **Onglerie**.`
}\n\
Cela permet **d'organiser vos prestations visuellement** dans \
un premier temps, dans l'ordre que vous souhaitez.\n\
${!salonDesc.beauty && domain=="esthetics" ? `*exemple : La Coloration est associée à la **Préparation**, \
les coupes à **Coupe**,  quant au Soin ou au Brushing, ils se retrouvent au **Bac***.\n` : ''}\
${!salonDesc.beauty && domain=="esthetics" ? `- Vous pourrez véritablement **ordonner vos prestations dans la section Ordre** \
(*Voir la page [Ordre](#/administration/order) pour de plus amples de détails*).\n` : ''}\n\
À quoi correspond la section **Type** des [Prestations](#/administration/services) ?\n\
* **Seulement en forfait** : la prestation ne peut être prise à la carte, et sera uniquement \
disponible dans les forfaits qui l'incluent. Le prix est calculé en fonction des entrées des prestations sélectionnées.\n\
Néanmoins, vous pouvez toujours modifier les tarifications en cliquant sur l'icône associé à cet effet.\n\
* **À la carte et en forfait** : la prestation est disponible à la carte, et a également la possibilité d'être \
introduite par vos soins dans un ou plusieurs forfaits.\n\
* **Technique** : les offres techniques concernent des prestations \
complexes, nécessitant parfois un devis, elles ne peuvent alors être prises en ligne ou sur l'application client.\n\
Néanmoins, elles peuvent **apparaître sur votre Page Espace-Temps** si vous le souhaitez.\n\
(Voir *[App](#/administration/website)*)\n\n` : ''}

${salonDesc.beauty || domain=="coachs" ? '' :
`Comment paramétrer ***les temporalités*** des prestations et forfaits ?\n\
- L'Optimisation du temps étant la clé, nous \
avons décidé de **découper** le fil du temps **à plusieurs reprises**, ce qui vous permet de configurer un temps de travail, \
suivi d'un temps de pause, puis de nouveau d'un temps de travail etc... pour une même prestation !\n\
(*exemple : pour une Couleur : temps de pose couleur - **temps de pause** - temps de shampooing*).\n\
- Les forfaits **additionnent automatiquement** les temps paramétrés des prestations.\n\
Vous en retrouverez \
un autre exemple lors de la création d'une prestation dans [Administration](#/administration).\n\n`
}\
${salonDesc.beauty || domain=="coachs" ? '' :
`À quoi correspond \
alors le réglage **Durées alternatives** lors de la **création d'une prestation** ?\n\
- Ce réglage vous permet \
**d'associer à une prestation un temps alternatif** suivant un temps plus long (associé à **l'éclaircissement** ).\n\
*exemple : il y a deux temporalités possibles pour une couleur ou des mèches, si la demande est d'éclaircir plutôt \
que de foncer, le temps requit n'étant pas le même, vous pouvez ainsi paramétrer ce temps plus long (éclaircir), \
celui par défaut étant associé au temps le plus court (foncer).*\n\n`
}\
${salonDesc.beauty || domain=="coachs" ? '' :
`À quoi correspondent les **Dépendances** ?\n\
- Lorsque les clients ont la possibilité de **choisir leurs prestations à la carte**, il est possible de leur en \
***imposer*** certaines en fonction de leurs choix.\n\
*exemple : il est toujours nécessaire d 'effectuer un **Shampooing** \
avant une **Coupe** ou après une **Couleur**, pour simplifier, automatiser les prises de rendez-vous et éviter \
les erreurs il est donc fortement recommandé d 'ajouter le **Shampooing** à la liste des dépendances de **Coupe** \
et *Couleur*.*\n\n`
}\
${salonDesc.beauty || domain=="coachs" ? '' :
`À quoi correspond la section **[Ordre](#/administration/sorting)** dans [Administration](#/administration) ?\n\
- Le système de planning à trous nécessite qu'un **ordre de passage** entre les prestations soit établi, une **hiérarchie de \
priorités** pour le calcul des temporalités, concernant principalement les prestations requérant des temps de pauses \
(coloration, mèches ...).\n\
*exemple : un Shampooing est effectué **avant** une Coupe, mais **après** une Coloration qui elle-même se réalise avant \
des mèches (lorsque le client souhaite les deux). Alors la Coloration se comptera parmi les premières de l 'ordre avec les \
Mèches, suivie de la Coupe, quant au Brushing, lui se retrouvera parmi les derniers.*
`}\
	`
				},
				{
					question: `${domain=="coachs" ? '' : "## **[Mon équipe](#/administration/staff)**"}`,
					response: `${domain=="coachs" ? '' : "Comment **gérer mon équipe**, les **droits d'administrateur** ou même les **horaires** ?\n - C'est la section [Équipe](#/administration/staff) dans [Administration](#/administration) qui vous propose les réglages suivants : noms, email/téléphone, horaires, prestations non réalisées par le collaborateur, ainsi que la fonction administrateur. \n - Ces réglages seront automatiquement synchronisés avec l'onglet [Rendez-vous](#/appointments) et le [Planning](#/schedules).\n - Pour ajouter un collaborateur en bas de la page, cliquez sur **+**.\n - Pour *modifier* une fiche collaborateur, vous trouverez en bout de chaque ligne une **icône** vous permettant de le faire (voir *légende*)"}`,
				},
				{
					question:`## **[Page App : les ${reservations} en ligne](#/administration/website)**`,
							response: salonDesc => `\
Comment le client ${domain=="coachs" ? "réserve-t-il une **séance en ligne** ?" : "prend-t-il **rendez-vous dans mon salon** ?"}\n\
- Pour effectuer une réservation en ligne, le client peut le faire **depuis le lien qui vous a été envoyé - votre page ${coach}**. \n\
\n\
Qu'est ce que la page **${name}** ?\n\
- La page ${name} correspond à **votre vitrine sur le site Mentorapp**, ce qui vous permet ainsi d'être **référencé** \
parmis d'autres ${name}s, avec la ${reservations} en ligne, accompagné d'une galerie personnalisable et des avis clients \
que vous pouvez modérer depuis le [Tableau de bord](#/dashboard). Vous pouvez *modifier et administrer* votre **page ${name}** \
directement depuis [Administration](#/administration), dans [Page App](#/administration/website).\n\
\n\
\t- **Informations** : Vous pouvez afficher **jusqu'à trois photos de votre ${activitie}** pour l'entête de votre page. \
Pour les *Modifier* ou en *Ajouter*, *cliquez* sur l'icone *Éditer* prévue à cet effet. (*Voir légende*)\n\
\n\
\t- **Prestations** : ***Cochez les prestations*** que vous voulez **afficher** sur votre page ${name}, \
*sélectionnez* un des **quatre modes d'affichage** (*À partir de ... , Entre ... et ... , Jusqu'à ...* et \
*Ne pas afficher de prix*).\n\
\n\
\t- **Avis et Mise en Avant** : Vous pouvez ***modérer*** vos avis (***Voir Comment modérer ou contester des avis ?***) \
et *Mettre en Avant* chaque jour un **produit** ou bien **Une promotion** sur le [Tableau de bord](#/dashboard).\n\
Celle-ci **apparaîtra** aussi dans la section ***En ce moment*** avec la **Galerie** sur votre page ${name}.`,
					tips: [`*N'oubliez jamais de **Sauvegarder** vos *modifications* effectuées sur votre page ${name} afin de syncroniser avec votre page ${name}app*.`],
					//Et **un Site Web** ?\n - Le Site Web quant à lui correspond à **votre vitrine sur Internet**. En souscrivant, vous pouvez ainsi être **référencé** parmis d'autres salons sur Internet, avec également une galerie photos personnalisable et des avis clients que vous pouvez modérer.\nVous pouvez **faire la demande d'un Site Web** directement depuis [Administration](#/administration), dans la section [App](#/administration/siteweb) (*Voir mon Site Web* / *Créer mon Site Web*). Dans ce cas présent, une personne vous **contactera** afin de déterminer un rendez-vous qui fixera l'esthétique de celui-ci.\n\n //
				},
				{
					question: `## **[Les rappels ${appoints}, commençons par les mails !](#/administration/messages)**`,
					response:`Comment fonctionnent les **rappels par mail** ?\n - **Les rappels** sont pour le moment **automatiques**, envoyés respectivement **24H avant ${pronoml} ${appoint}**.`,
					tips: [`*Pour la **prochaine version** : **notifications PUSH** et synchronisation des séances avec **Google Agenda** seront disponibles !*`],
					//Comment fonctionnent les **Notifications mobiles** ? \n - Même système que les SMS, les notifications sont en revanche **illimitées, gratuites et surtout liées à l'Application !** Celles-ci **remplacent les SMS** dès lors que le client a **installé l'Application** sur son téléphone, et ce autant pour **les rappels** que pour **les campagnes marketing** !,
							//tips: [`*Plus il y aura de clients utilisateurs de l'Application ${name}, moins il y aura de facturation SMS !*\n***N'hésitez pas à en parler ${target} !***`],
							//response2:"\n\nComment activer les **Campagnes Marketing** ?\n - Vous pouvez également **programmer des campagnes marketings SMS/Notifications**, vous trouverez les réglages dans [Administration](#/administration), section [Campagne Marketing](#/administration/sms).\n Un petit **compteur** vous donnera une idée du **coût de l'envoi** et de **l'économie** réalisée également **grâce aux Notifications** ! \nPour *ajouter* et *paramétrer* un **nouveau message**, *cliquez* sur **+**.",
							//Comment fonctionnent **les rappels** rendez-vous ?\nIl y a deux moyens de rappels proposés, les **rappels SMS** dans [Administration - Diffusion](#/administration/sms) et les **Notifications** liées à la page App, qui elles sont **gratuites**. \nCeux-ci sont modifiables, paramétrées par défaut en *Automatique* et programmées **24h avant le rendez-vous**. Comment fonctionnent les **rappels SMS** alors ?\n - De la même manière, pour les rappels ou campagnes sms, vous trouverez les réglages dans [Administration](#/administration), section [Diffusion](#/administration/sms). \nLes rappels SMS sont aussi modifiables, paramétrés aussi par défaut en *Automatique* et programmés **48h avant le rendez-vous**.//
				},
						{
				question: domain == "coachs" ? '' : "## **[Les Statistiques](#/stats)** (Abonnement **Premium** ou **Multi** uniquement)",
				response: salonDesc => `\
Quelles sont les fonctionnalités **des Statistiques** ?\n\
- La page [Statistiques](#/stats) se divise en 4 sections : les **Rendez-vous**, les **Clients**, l'**Équipe** et les **Stocks**. \n\
Pour **chacune** d'entre-elles se trouve un ***Résumé*** qui affiche les **informations les plus importantes** et un petit \
**récapitulatif des chiffres** importants, configuré par défaut sur l'*année en cours*. \n\
Des **réglages** sont paramétrables dans \
l'encadré de chaque sections avec **à gauche la temporalité** que vous souhaitez observer.\n\n\
\t- Les **Rendez-vous** indiquent dans \
le ***Résumé*** le nombre de *rendez-vous pris*, les *plages horaires* (avec les plus fortes influences) et ceux pris *par \
les collaborateurs et clients*.\n\
Deux sections se divisent alors en *Détail* et *Général* afin d'observer de plus amples informations.\n\n\
\t- Les **Clients** présentent le nombre de *clients inscrits*, les *tranches d'âges* et les *nouveaux inscrits* \
dans l'onglet ***Résumé***\n\
Les deux onglets suivant présentent pour le premier, la *liste des 100 meilleurs clients*, et pour le second, un *Détail* \
portant sur les *nouveaux et anciens clients*, le nombre de clients *avec/sans compte ou encore* *par genre*.\n\n\
\t- L'**Équipe** possède une **section généralisée** directement au sein du ***Résumé***. **Chaque onglet** correspond ensuite à \
**chacun des collaborateurs**.\n\
Vous y trouverez les *taux de présence*, mais aussi les *heures et journées de travail effectuées*.\n\n\ 
${salonDesc.beauty || domain=="coachs" ? '' :
`\t- Pour finir, les **Stocks** possèdent un ***Résumé*** concernant les *utilisations globales* des produits, mais aussi les *plus utilisés \
par type et par marque* par exemple.\n\
Le détail se scinde sous la forme de deux onglets : les **Gammes** et les **Marques** qui \
suivent les mêmes caractérisques.`
}\
`,
				//et **à droite le comparatif qui s'y superposera**//
			},
						{
							question:"## **[Avis](#/reviews)**",
							response:"Comment ***modérer*** ou ***contester*** des **avis** ?\n - Vous avez accès à tous vos avis dans la page [Avis](#/administration/reviews), vous pouvez choisir de **les contester** depuis cette même page mais aussi depuis le [Tableau de Bord](#/administration/dashboard) (***card*** *Avis*), ou sur le **compte même d'un client** ayant posté un ou plusieurs avis. \n - Pour *contester*   **plusieurs avis**, vous trouverez un bouton formulant une requête à cet effet sur la page **[Avis](#/reviews)** en haut à droite de la section. **Cochez** ceux que vous désirez contester et **cliquez** sur ***contester***.\n - **Motifs de contestation** : propos injurieux, grossiers, vulgaires, menaçants, obscènes, diffamatoires, discriminatoires, mais aussi si l'avis est un spam, ou s'il a été laissé dans un but commercial.",
							tips: [`*Cette action est irréversible*.*Si vous rencontrez une difficulté ou pour toute autre demande, veuillez nous contacter par mail à contact@${mail}*`],
						},
						/*{
							question:"## **[Annulations](#/appointments)**",
							response: salonDesc => `\
	Comment **annuler** plusieurs rendez-vous ?`\n\
	- Si vous deviez **annuler tous les rendez-vous**, il est possible de le faire depuis l'onglet [Rendez-Vous](#/appointments). Choisissez le collaborateur concerné puis *cliquez* sur **Annuler les rendez-vous** en bas de page.\n\
	Il vous sera alors demandé de choisir la date qui conviendra pour l'annulation.`\n\
	- Si vous devez annuler **tous les rendez-vous** du salon, sélectionnez **Général** puis *cliquez* sur **Annuler les rendez-vous** aussi en bas de page.`,
							tips: ["*Une liste avec les informations nécessaires vous sera présentée lors de l'annulation afin de prévenir chaque client et/ou de modifier les rendez-vous annulés.*"],
							// \n\
							// Il vous sera alors demandé de remplir un **formulaire avec message** pour l'envoi SMS et de définir la date à laquelle cela fera effet. L'**envoi automatique** du SMS se fera **le jour et l'heure programmés**.
						},*/
				{
					question:"## **[Fermeture](#/administration/bills)**",
					response:`Comment **clôturer temporairement mon ${account}** ?\n - Vous pouvez **fermer votre ${schedule}** (y compris les ${reservations} en ligne) depuis la page [Plannings](#/schedules). Vous y trouverez un petit ***toggle (voir légende)*** qui vous permettra ainsi de **clôturer le planning du ${account}**. Vous y trouverez également de quoi définir la date de clôture temporaire qui se fera au moment que vous aurez indiqué.`,
				},
					//	{
					//question: "## **[Abonnement](#/administration/bills)**",
					//response:"Comment **changer** d'abonnement ? \n - Afin de changer, il suffit de se rendre sur la page **[Abonnement](#/administration/bills)**, vous trouverez un ***slider*** (*Voir légende*) **à déplacer vers l'offre** qui vous intéresse puis **d'enregistrer**. Le changement sera **immédiat**, le prélèvement demeurera **inchangé** (**en dehors du montant**).\n\n Comment **changer mon mode de règlement** ou **mes informations bancaires** ? \n - Au **même endroit**, vous trouverez un **champs à remplir** (Prélèvement SEPA / Carte Bleue), afin de *modifier* vos informations bancaires, puis ***enregistrez***.\n - Pour **changer le mode de règlement**, un petit ***toggle*** permet de passer d'un mode de règlement à un autre (*Voir légende*).\n\n Où trouver **mes factures** ? \n - Vous trouverez **vos factures** sur la même page  *[Abonnement](#/administration/bills)*, une icône ***fichier*** (*Voir légende*) **est associé** à chaque facture afin d'en **avoir un aperçu**, ainsi que celle correspondant à ***l'impression*** (*Voir légende*).\n\n Comment **résilier mon abonnement** ? \n - L'Abonnement est *Sans engagement*, vous pouvez résilier celui-ci **à tout instant**.\n Vous serez ainsi **prélevé** au ***prorata***, **à partir du dernier prélèvement**, les *frais SMS* seront également à votre charge et au ***prorata***.",
				//		}
					]
				}
			}
			/*prestation : ainsi une couleur (10 min temps de pose/travail, 40min temps de pause, 15min temps de rinçage/travail) + un shampooing (10min temps de travail) + une coupe (15min, temps de travail) donneront : 90min dont 40min de pause ou de travail (2 autres coupes ou 4 shampooings par exemple). */
		},
	}
}
