<template>
	<div id="app-container" :class="$store.state.domain">
		<div :class="'viewport-filter ' + opacityFilterClass"></div>
		<vue-topprogress ref="topProgress" :height="5" :trickle="progressTrickle" :speed="progressSpeed" :color="progressColor"/>
<!--		<vue-topprogress ref="topProgress" :height="5" :trickle="true" :speed="350"/> -->
		<Navbar
			v-if="$router.currentRoute.path != '/login' && !$router.currentRoute.meta.printable"
			@dropdown-open="opacityFilter = true"
			@dropdown-close="opacityFilter = false"
		/>
		<div ref="gear" id="gear" class="fas fa-sync-alt"></div>
		<!--div ref="reloadZone" id="reload-zone">
			<div id="band1"></div>
			<div ref="gear" id="gear" class="fas fa-cog"></div>
			<div id="band2"></div>
		</div-->
		<div ref="mainRouterSlider" class="main-router">
		<transition :name="transitionName">
			<keep-alive>
				<router-view ref="mainRouter"></router-view>
			</keep-alive>
		</transition>
		</div>
		<template v-if="$router.currentRoute.path != '/login' && !$router.currentRoute.meta.printable">
			<div id="bottom-mask">
				<div @click="showQuestionsModal = true">
					<button id="contact-btn" class="fas fa-comments btn btn-primary btn-overlay" onclick="" type="button"></button>
					<div id = "contact-text">{{$t("common.question")}}</div>
				</div>
				<button id="appointment-btn" class="btn btn-primary btn-overlay" @click="showNewAppointmentModal = true" type="button"> {{$t("common.newapp")}}</button>
				<div v-if="env.VUE_APP_MODE == 'development'" class="build-infos desktop">
					<span><b>Build</b> #{{ env.VUE_APP_BUILDHASH }}</span>
					<span>{{ $d(new Date(env.VUE_APP_BUILDDATE), 'short') }}</span>
					<div class="details">
						<span><b>Author</b> : {{ env.VUE_APP_BUILDAUTHOR }}</span>
						<span><b>Message</b> : {{ env.VUE_APP_BUILDMESSAGE }}</span>
					</div>
				</div>
				<a v-if="demo" id="btn-demo-subscribe" class="btn btn-primary" href="https://espacetemps.io/#part-subscribe">Souscrire !</a>
			</div>
		</template>
		<Modal name="networkErrorModal" type="small" v-if="errorModalMessage != ''">
			<template v-slot:content>
				<div>{{ errorModalMessage }}</div>
			</template>
			<template v-slot:buttons>
				<button class="btn btn-primary" onclick="document.location.reload(false)">{{ $t('common.reload') }}</button>
			</template>
		</Modal>
		<Modal name="demo-welcome" type="small" v-if="demoWelcome">
			<template v-slot:header>
				<h1 class="title-search">Démo</h1>
			</template>
			<template v-slot:content>
				<div>Bienvenue sur Espace-temps PRO !<br/><br/>
				Nous offrons dix minutes pour découvrir gratuitement quelques fonctionnalités disponibles dans notre solution afin d'en donner un bref aperçu. <br/>
				Certaines fonctionnalités sont donc disponibles seulement après abonnement, si vous souhaitez une rapide présentation, n'hésitez pas à nous contacter sur notre site !</div>
			</template>
			<template v-slot:buttons>
				<button class="btn btn-primary" @click="demoWelcome=false">Démarrer</button>
			</template>
		</Modal>
		<Modal name="demo-final" type="small" :above="true" v-if="demoFinal">
			<template v-slot:header>
				<h1 class="title-search">Fin de la démo !</h1>
			</template>
			<template v-slot:content>
				<div>Alors ? Qu'en dîtes-vous ?!</div>
			</template>
			<template v-slot:buttons>
				<button class="btn btn-gray" @click="demoRetry"><i class="fas fa-redo"/>Je réessaye !</button>
				<button class="btn btn-primary" onclick="document.location = 'https://espacetemps.io/#part-subscribe'"><i class="fas fa-check"/>Je souscris !</button>
			</template>
		</Modal>
		<NewAppointment v-if="showNewAppointmentModal" @confirm="showNewAppointmentModal = false; newAppointmentSummaryData = $event" @close="showNewAppointmentModal = false" name="newAppointment"/>
		<NewAppointmentSummary v-if="newAppointmentSummaryData" @close="newAppointmentSummaryData = null" :data="newAppointmentSummaryData" name="newAppointmentSummary"/>
		<Questions v-if="showQuestionsModal" @close="showQuestionsModal = false" name="questions"/>
	</div>
</template>

<style lang="scss">
	@import 'css/globals.scss';
	.fade-enter-active, .fade-leave-active {
		transition: opacity .25s;
	}
	.fade-enter, .fade-leave-to {
		opacity: 0;
	}

	.fade-in-enter-active {
		transition: opacity .5s;
	}
	.fade-in-enter {
		opacity: 0;
	}
	.fade-in-enter-to {
		opacity: 1;
	}
	.main-router:not(.moving) {
		&.reload-with-margin {
			transition: margin-top 0.2s ease-out;
		}
		&:not(.reload-with-margin) {
			transition: transform 0.2s ease-out;
		}
	}
	#gear {
		position: absolute;
		top: -4.25rem;
		left: calc( 50% - 1.5rem - 0.25rem );
		font-size: 3rem;
		color: $color-primary-light-10;
		transform: rotate( 0deg );
		z-index: 1000;
		background: white;
		border-radius: 50%;
		border: 1px solid $color-primary-light-50;
		padding: 0.5rem;
		&:not(.moving) {
			transition: transform 0.35s ease-out;
		}
	}
	#reload-zone {
		background: $color-primary-dark-10;
	/*	background-image: linear-gradient(360deg, white, $color-primary);*/
	/*
		width: 100vw;
		position: fixed;
		left: 0;
		top: $navbar-height;
		z-index: 1000;
		overflow: hidden;
		&:after {
			content: '';
			box-shadow: inset 0 7px 30px -7px rgba(0,0,0,0.4);
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
		#band1 {
			background: $color-primary;
			position: absolute;
			top: 0;
			left: 20px;
			width: 20px;
			height: 100%;
		}
		#band2 {
			background: $color-primary;
			position: absolute;
			top: 0;
			right: 20px;
			width: 20px;
			height: 100%;
		}
		#gear {
			position: absolute;
			top: calc( 50% - 2.5rem );
			left: calc( 50% - 2.5rem );
			font-size: 5rem;
			color: $color-primary-light-10;
			transform: rotate( 0deg );
			&:not(.moving) {
				transition: transform 0.2s ease-out;
			}
		}
		@include mobile {
			top: $navbar-height-mobile;
		}
		&:not(.moving) {
			transition: height 0.2s ease-out;
		}
	*/
	}
</style>

<script>
	import dayjs from 'dayjs'
	import jwt_decode from "jwt-decode"
	import { vueTopprogress } from 'vue-top-progress'
	import Navbar from './components/Navbar.vue'
	import Modal from './components/Modal.vue'
	import NewAppointment from './modals/NewAppointment.vue'
	import NewAppointmentSummary from './modals/NewAppointmentSummary.vue'
	import Questions from './modals/Questions.vue'
	import './espace-theme/css/theme.scss'
	import './css/site.scss'

	export default {
		name: 'App',
		components: {
			vueTopprogress,
			Navbar,
			Questions,
			NewAppointment,
			NewAppointmentSummary,
			Modal
		},
		data() {
			return {
				env: process.env,
				errorModalMessage: "",
				opacityFilter: false,
				opacityFilterClass: "",
				showQuestionsModal: false,
				showNewAppointmentModal: false,
				newAppointmentSummaryData: null, // TEST : { "date": "2020-04-15 11:20", "userid": 1, "gender_hair": "male_long", "services": [ 2, 3 ], "workers": [ 2 ], "message": "faire une couleur bio", "timeline": [ 35 ], "title": "Shampooing + Coupe" },
				transitionName: "",
				touchX: null,
				touchY: null,
				lastTouchX: null,
				lastTouchY: null,
				progressColor: "29d",
				progressTrickle: false,
				progressSpeed: 0,
				preventScrolling: false,
				cannotReload: false,
				swiping: false,
				reloadAnimationTimer: null,
				demoWelcome: false,
				demoFinal: false
			}
		},
		watch: {
			'$route'( to, from ) {
				if( from.path.indexOf( "/login" ) >= 0 ) {
					this.transitionName = "fade-in"
				} else {
					this.transitionName = ( to.path.indexOf( "/login" ) >= 0 ? "fade" : "" )
				}
				if ( to.meta.printable ) {
					document.documentElement.classList.add( "print" )
				} else {
					document.documentElement.classList.remove( "print" )
				}
			},
			opacityFilter( newVal ) {
				if (newVal) {
					this.opacityFilterClass = 'on not-completely-off'
				} else {
					this.opacityFilterClass = 'not-completely-off'
					setTimeout(() => this.opacityFilterClass = '', 100)
				}
			}
		},
		computed: {
			demo() {
				return process.env.VUE_APP_DEMO
			}
		},
		methods: {
			hi() {
				console.log(arguments[0])
			},
			demoGo() {
				const endTime = dayjs.unix( jwt_decode( this.$api.headers["X-Access-Token"] ).exp )
				let interval = setInterval( () => {
					if ( endTime.diff( dayjs(), "second" ) <= 0 ) {
						this.demoFinal = true
						clearTimeout( interval )
					}
				}, 2000 )
			},
			demoRetry() {
				if ( process.env.VUE_APP_DEMO ) {
					this.$api.post( "/demo/esthetics/retry" ).then( response => {
						this.demoFinal = false
						this.demoGo()
					}).catch( error => {
						console.log( error )
					})
				}
			},
			progressBarColor( color ) {
				this.progressColor = color
			},
			progressBarStart( trickle, speed ) {
				this.$app.$refs.topProgress.$forceUpdate()
				if ( trickle !== undefined && trickle !== null ) {
					this.$refs.topProgress.trickle = trickle
				}
				if ( speed !== undefined && speed !== null ) {
					this.$refs.topProgress.speed = speed
				}
				this.$refs.topProgress.start()
			},
			progressBarDone() {
				this.$refs.topProgress.done();
				this.$app.$refs.topProgress.$forceUpdate()
			//	this.$refs.topProgress.trickle = true
			//	this.$refs.topProgress.speed = 350
				this.progressTrickle = true
				this.progressSpeed = 350
				this.$app.$refs.topProgress.$forceUpdate()
			},
			progressBarSet( val ) {
				this.$refs.topProgress.set( val )
			},
			showError( message ) {
				this.errorModalMessage = message
			},
			showFilter( val ) {
				this.opacityFilter = val
			},
			setPreventScrolling( p ) {
				this.preventScrolling = p
			},
			handleStart( event ) {
				if ( this.preventScrolling ) {
					return
				}
				if (this.$refs.mainRouter.$el.classList.contains('no-reload'))
					return
				let canswipe = true
				let elem = event.target
				while ( !!elem.parentNode ) {
					if ( elem.classList.contains( "ui-sortable-handle" ) ) {
						this.cannotReload = true
						canswipe = false
					}
					if ( elem.scrollTop > 0 ) {
						canswipe = false
					}
					elem = elem.parentNode
				}
				if ( canswipe ) {
				//	let scroll = document.documentElement.scrollTop || document.body.scrollTop || document.scrollY || 0
				//	if ( scroll <= 0 ) {
						this.touchX = event.touches[0].clientX
						this.touchY = event.touches[0].clientY
						/*
						this.$refs.reloadZone.classList.add( "moving" )
						this.$refs.mainRouterSlider.classList.add( "moving" )
						*/
						this.$refs.gear.classList.add( "moving" )
						let ids = this.$refs.mainRouter.$el.getAttribute("data-reload-elem")
						if ( ids ) {
							ids.split(",").forEach( id => {
								document.getElementById( id ).classList.add( "moving" )
							})
						}
				//	}
				}
				this.lastTouchX = event.touches[0].clientX
				this.lastTouchY = event.touches[0].clientY
			},
			handleEnd( event ) {
				if (this.$refs.mainRouter.$el.classList.contains('no-reload'))
					return
				this.cannotReload = false
				if ( this.swiping ) {
					this.$allowScrolling()
					this.swiping = false
				}
				if ( this.preventScrolling ) {
					return
				}
				let canswipe = true
				let elem = event.target
				while ( !!elem.parentNode ) {
					if ( elem.classList.contains( "ui-sortable-handle" ) ) {
						this.cannotReload = true
						canswipe = false
					}
					if ( elem.scrollTop > 0 ) {
						canswipe = false
					}
					elem = elem.parentNode
				}
				let hasReloaded = false
				if ( canswipe && Math.max( 0, this.lastTouchY - this.touchY ) > 250 ) {
			//	let scroll = document.documentElement.scrollTop || document.body.scrollTop || document.scrollY || 0
			//	if ( scroll <= 0 && Math.max( 0, this.lastTouchY - this.touchY ) > 200 ) {
					this.progressBarStart()
					this.progressBarSet(20)
					this.$store.dispatch( "reload" ).then( async () => {
						this.progressBarSet(50)
						let match = null
						for ( let i = 0; i < this.$route.matched.length; i++ ) {
							if ( this.$route.path == this.$route.matched[i].path ) {
								match = this.$route.matched[i]
								break
							}
						}
						if ( match ) {
							let fct = match.instances.default.update
							if ( fct ) {
								fct()
							}
						}
						this.progressBarDone()
					})
					hasReloaded = true
				}
				let diff = Math.max( 0, this.lastTouchY - this.touchY )
				this.lastTouchX = null
				this.lastTouchY = null
				this.touchX = null
				this.touchY = null
				/*
				this.$refs.reloadZone.classList.remove( "moving" )
				this.$refs.reloadZone.style.height = "0px"
				this.$refs.mainRouterSlider.classList.remove( "moving" )
				if ( this.$refs.mainRouter.$el.classList.contains( "reload-with-margin" ) ) {
					this.$refs.mainRouterSlider.style["margin-top"] = "0px"
				} else if ( this.$refs.mainRouterSlider.style.transform ) {
					this.$refs.mainRouterSlider.style.transform = "translateY(0px)"
					this.reloadAnimationTimer = setTimeout( () => {
						this.$refs.mainRouterSlider.style.transform = ""
					}, 200 )
				}
				*/
				let ids = this.$refs.mainRouter.$el.getAttribute("data-reload-elem")
				if ( ids ) {
					ids.split(",").forEach( id => {
						let elem = document.getElementById( id )
						elem.classList.remove( "moving" )
						if ( this.$refs.mainRouter.$el.classList.contains( "reload-with-margin" ) ) {
							elem.style["margin-top"] = "0px"
						}
					})
				}
				if ( hasReloaded ) {
					this.$refs.gear.classList.remove( "moving" )
					this.$refs.gear.style.transform = "translateY( " + Math.min( diff, 300 ) / 2 + "px ) rotate(0deg)"
					setTimeout( () => {
						this.$refs.gear.style.transform = "translateY(0px) rotate(0deg)"
					}, 350 )
				} else {
					this.$refs.gear.style.transform = "translateY(0px) rotate(0deg)"
					this.$refs.gear.classList.remove( "moving" )
				}
			},
			handleMove( event ) {
				if (this.$refs.mainRouter.$el.classList.contains('no-reload'))
					return
				if ( this.preventScrolling || this.cannotReload ) {
					return
				}
				let canswipe = true
				let elem = event.target
				while ( !!elem.parentNode ) {
					if ( elem.classList.contains( "ui-sortable-handle" ) ) {
						this.cannotReload = true
						canswipe = false
					}
					if ( elem.scrollTop > 0 ) {
						canswipe = false
					}
					elem = elem.parentNode
				}
			//	let scroll = document.documentElement.scrollTop || document.body.scrollTop || document.scrollY || 0
				this.lastTouchX = event.touches[0].clientX
				this.lastTouchY = event.touches[0].clientY
				if ( this.touchX === null && this.touchY === null ) {
			//		if ( scroll <= 0 ) {
					if ( canswipe ) {
						this.touchX = event.touches[0].clientX
						this.touchY = event.touches[0].clientY
					}
				} else {
					let diff = Math.max( 0, event.touches[0].clientY - this.touchY )
					if ( diff > 1 ) {
						if ( !this.swiping ) {
							this.$preventScrolling() // FIXME
							this.preventScrolling = false
							document.documentElement.scrollTop = 0
							this.swiping = true
						}
						if ( this.reloadAnimationTimer ) {
							clearTimeout( this.reloadAnimationTimer )
							this.reloadAnimationTimer = null
						}
					} else if ( this.swiping ) {
						this.$allowScrolling()
						this.swiping = false
					}
				/*
					this.$refs.reloadZone.style.height = Math.min( diff, 200 ) + "px"
					if ( this.$refs.mainRouter.$el.classList.contains( "reload-with-margin" ) ) {
						this.$refs.mainRouterSlider.style["margin-top"] = parseInt( this.$refs.mainRouter.$el.getAttribute("data-reload-margin") || "0" ) + Math.min( diff, 200 ) + "px"
						let ids = this.$refs.mainRouter.$el.getAttribute("data-reload-elem")
						if ( ids ) {
							ids.split(",").forEach( id => {
								document.getElementById( id ).style["margin-top"] = Math.min( diff, 200 ) + "px"
							})
						}
					} else {
						this.$refs.mainRouterSlider.style.transform = "translateY(" + Math.min( diff, 200 ) + "px)"
					}
				*/
					this.$refs.gear.style.transform = "translateY( " + Math.min( diff, 300 ) / 2 + "px ) rotate(-" + ( 100 + Math.min( diff, 300 ) * 180 / 300 ) + "deg)"
				}
			}
		},
		updated() {
			this.$refs.topProgress.done()
			/*
			if ( this.$router.currentRoute.meta.printable ) {
				window.print()
				window.close()
			}
			*/
		},
		mounted() {
			this.$refs.topProgress.start()
			window.addEventListener( "touchstart", this.handleStart, true )
			window.addEventListener( "touchend", this.handleEnd, true )
			window.addEventListener( "touchmove", this.handleMove, true )
			if ( this.$router.currentRoute.meta.printable ) {
				document.documentElement.classList.add( "print" )
			}
			if ( process.env.VUE_APP_DEMO ) {
				const endTime = dayjs.unix( jwt_decode( this.$api.headers["X-Access-Token"] ).exp )
				if ( Math.abs( dayjs.unix( jwt_decode( this.$api.headers["X-Access-Token"] ).iat ).diff( dayjs(), "second" ) ) < 20 ) {
					this.demoWelcome = true
				}
				this.demoGo()
			}
		}
	}
</script>
