Date.prototype.addYears = function( years ) {
	var date = new Date( this.valueOf() );
	date.setYear( 1900 + date.getYear() + years );
	return date;
}

Date.prototype.addMonths = function( months ) {
	var date = new Date( this.valueOf() );
	date.setMonth( date.getMonth() + months );
	return date;
}

Date.prototype.addDays = function( days ) {
	var date = new Date( this.valueOf() );
	date.setDate( date.getDate() + days );
	return date;
}

Date.prototype.addMinutes = function( minutes ) {
	var date = new Date( this.valueOf() );
	date.setMinutes( date.getMinutes() + minutes );
	return date;
}

Array.prototype.isSame = function( other ) {
	if ( other === null || other === undefined || this.length !== other.length ) {
		return false
	}
	for ( let i = 0; i < this.length; i++ ) {
		if ( Array.isArray( this[i] ) ) {
			if ( !other[i].isSame( this[i] ) ) {
				return false
			}
		} else if ( this[i] !== null && typeof this[i] === "object" ) {
			// TODO
		} else {
			if ( other[i] !== this[i] && !( ( other[i] === undefined && this[i] === null ) || ( other[i] === null && this[i] === undefined ) ) ) {
				return false
			}
		}
	}
	return true
}

if ( typeof jQuery !== "undefined" ) {
	jQuery.fn.datetimepicker.Constructor.prototype._notifyEvent = function _notifyEvent(e) {
		if (e.type === jQuery.fn.datetimepicker.Constructor.Event.CHANGE && (e.date && e.date.isSame(e.oldDate) || !e.date && !e.oldDate))
		{
			return;
		}
		this._element.trigger(e);
	}
}

