import axios from 'axios'


// This ID is sent among every API calls to avoid redundancy
// It will prevent API to send back the same data to WebSocket watcher
const sessionId = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace( /[xy]/g, function(c) {
	var r = Math.random() * 16 | 0, v = c == 'x' ? r : ( r & 0x3 | 0x8 )
	return v.toString( 16 )
})


let ws = null
let watchers = {}


function dispatch( data ) {
	console.log( "dispatch", data )
	var watcher = watchers[data.endpoint]
	if ( watcher ) {
		for ( var i = 0; i < watcher.length; i++ ) {
			watcher[i]( data )
		}
	}
}


function setupWebSocket( apiRoot, apiClient ) {
	console.log( "setupWebSocket()" )
	ws = null

	var onopen = function() {
		ws.send( JSON.stringify( { sessionId: sessionId, token: apiClient.headers["X-Access-Token"] } ) )
	}

	var onclose = function() {
		var fct = function() {
			console.log( "in fct()" )
			if ( !apiClient.headers["X-Access-Token"] ) {
				console.log( "no token, waiting 1s" )
				setTimeout( fct, 1000 )
				return
			}
			ws = new WebSocket( `${apiRoot.replace( "http", "ws" )}/ws` )
			ws.onopen = onopen
			ws.onclose = onclose
			ws.onmessage = onmessage
		}
		if ( ws ) {
			setTimeout( fct, 1000 )
		} else {
			console.log( "go fct()" )
			fct()
		}
	}

	var onmessage = function( event ) {
		dispatch( JSON.parse( event.data ) )
	}

	// Connect now
	console.log( "go onclose()" )
	onclose()


	apiClient.socketClose = function() {
		ws.close()
	}
/*
	Object.keys( apiClient ).forEach( key => {
		if ( key != "headers" ) {
			apiClient[key].watch = function( callback ) {
				watchers[key] = watchers[key] || []
				if ( watchers[key].indexOf( callback ) < 0 ) {
					watchers[key].push( callback )
				}
				console.log( "watchers['" + key + "'].length is now " + watchers[key].length )
			}
		}
	})
*/
}


function handleTokenUpdate( apiClient, response ) {
	if ( response && response.headers ) {
		const token = response.headers["x-access-token"]
		if ( token ) {
			console.log( "token updated" )
			apiClient.headers["X-Access-Token"] = token
			window.localStorage.setItem( "token", token )
		}
	}
}

function recurseSet( parent, url, endpoint ) {
	if ( url.length == 1 ) {
		parent[url[0]] = endpoint
	} else {
		parent[url[0]] = parent[url[0]] || {}
		parent = parent[url[0]]
		url.shift(1)
		recurseSet( parent, url, endpoint )
	}
}


export default ( apiRoot, apiErrorHandler, firebase ) => {
	var api = null
	const apiClient = {
		headers: {
			"X-Session-ID": sessionId
		},
		public: {},
		setCredentials( workerid, token, domain, salonid ) {
			if ( domain ) {
				this.headers["X-Domain"] = domain
				window.localStorage.setItem( "domain", domain )
			} else if ( domain === null ) {
				delete this.headers["X-Domain"]
				window.localStorage.removeItem( "domain" )
			}
			if ( salonid ) {
				this.headers["X-Salon-ID"] = salonid
				window.localStorage.setItem( "salonid", salonid )
			} else if ( salonid === null ) {
				delete this.headers["X-Salon-ID"]
				window.localStorage.removeItem( "salonid" )
			}
			if ( workerid ) {
				this.headers["X-Client-ID"] = "worker/" + workerid
				window.localStorage.setItem( "workerid", workerid )
			} else if ( workerid === null ) {
				delete this.headers["X-Client-ID"]
				window.localStorage.removeItem( "workerid" )
			}
			if ( token ) {
				this.headers["X-Access-Token"] = token
				window.localStorage.setItem( "token", token )
				setupWebSocket( apiRoot, apiClient )
			} else if ( token === null ) {
				delete this.headers["X-Access-Token"]
				window.localStorage.removeItem( "token" )
			}

			if ( token ) {
				document.addEventListener( 'deviceready', () => { firebase.init( api, workerid, "worker" ) }, false )
			}
		},
		login( gaiaid, username, password, rememberMe ) {
			return new Promise( ( resolve, fail ) => {
				axios.post( `${apiRoot}/workers/login`, { gaiaid, username, password }, { headers: api.headers, timeout: 10000 } ).then( response => {
					this.headers["X-Domain"] = response.data.domain
					this.headers["X-Salon-ID"] = response.data.salonid
					this.headers["X-Client-ID"] = "worker/" + response.data.workerid
					this.headers["X-Access-Token"] = response.data.token
					if ( rememberMe ) {
						window.localStorage.setItem( "domain", response.data.domain )
						window.localStorage.setItem( "salonid", response.data.salonid )
						window.localStorage.setItem( "workerid", response.data.workerid )
						window.localStorage.setItem( "token", response.data.token )
					}
					firebase.init( api, response.data.userid, "worker" )
					setupWebSocket( apiRoot, apiClient )
					resolve( response )
				}).catch( error => {
					apiErrorHandler( error )
					fail( error )
				})
			})
		},
		/*
		userLogin( phone, password ) {
			return new Promise( ( resolve, fail ) => {
				axios.post( `${apiRoot}/users/login`, { phone, password }, { headers: api.headers, timeout: 10000 } ).then( response => {
					this.headers["X-Client-ID"] = "user/" + response.data.userid
					this.headers["X-Access-Token"] = response.data.token
					window.localStorage.setItem( "userid", response.data.userid )
					window.localStorage.setItem( "token", response.data.token )
					firebase.init( api, response.data.userid )
					resolve( response )
				}).catch( error => {
					apiErrorHandler( error )
					fail( error )
				})
			})
		},
		userFacebookLogin() {
			const api = this
			return new Promise( ( resolve, fail ) => {
				if ( typeof cordova === "undefined" ) {
					console.log( "Must be run with 'npm run cordova-serve-browser'" )
					return
				}
				facebookConnectPlugin.login( ["public_profile", "email", "user_birthday"], function( result ) {
					facebookConnectPlugin.api( "/me?fields=email,name,birthday" /*picture* /, [ "public_profile", "email" ],
						function( userData ) {
							api.post( "/users/fbauth", {
								token: result.authResponse.accessToken,
								userID: result.authResponse.userID,
								...userData
							}).then( response => {
								api.headers["X-Client-ID"] = "user/" + response.data.userid
								api.headers["X-Access-Token"] = response.data.token
								window.localStorage.setItem( "userid", response.data.userid )
								window.localStorage.setItem( "token", response.data.token )
								resolve( response )
							}).catch( error => {
								fail( error )
							})
						},
						function(error) {
							fail( error )
						}
					)
				}, function(error) {
					fail( error )
				})
			})
		},
		*/
		addEndpoint( route, hasId, isPublic ) {
			let endpoint = {
				get( queries ) {
					return new Promise( ( resolve, fail ) => {
						axios.get( `${apiRoot}${isPublic ? '/public' : ''}/${route}`, { headers: api.headers, params: queries } ).then( response => {
							handleTokenUpdate( apiClient, response )
							resolve( response )
						}).catch( error => {
							apiErrorHandler( error )
							fail( error )
						})
					})
				},
				count( queries ) {
					return new Promise( ( resolve, fail ) => {
						axios.get( `${apiRoot}${isPublic ? '/public' : ''}/${route}/count`, { headers: api.headers, params: queries } ).then( response => {
							handleTokenUpdate( apiClient, response )
							resolve( response )
						}).catch( error => {
							apiErrorHandler( error )
							fail( error )
						})
					})
				},
				post( data ) {
					return new Promise( ( resolve, fail ) => {
						axios.post( `${apiRoot}${isPublic ? '/public' : ''}/${route}`, data, { headers: api.headers, timeout: 10000 } ).then( response => {
							handleTokenUpdate( apiClient, response )
							resolve( response )
							dispatch({
								endpoint: route,
								method: "POST",
								data: response.data
							})
						}).catch( error => {
							apiErrorHandler( error )
							fail( error )
						})
					})
				},
				patch( data, queries ) {
					return new Promise( ( resolve, fail ) => {
						axios.patch( `${apiRoot}${isPublic ? '/public' : ''}/${route}`, data, { headers: api.headers, params: queries, timeout: 10000 } ).then( response => {
							handleTokenUpdate( apiClient, response )
							resolve( response )
							dispatch({
								endpoint: route,
								method: "PATCH",
								data: response.data
							})
						}).catch( error => {
							apiErrorHandler( error )
							fail( error )
						});
					});
				},
				delete( data ) {
					return new Promise( ( resolve, fail ) => {
						axios.delete( `${apiRoot}${isPublic ? '/public' : ''}/${route}`, { params: data, headers: api.headers, timeout: 10000 } ).then( response => {
							handleTokenUpdate( apiClient, response )
							resolve( response );
							dispatch({
								endpoint: route,
								method: "DELETE",
								data: response.data
							});
						}).catch( error => {
							apiErrorHandler( error )
							fail( error );
						});
					});
				},
				watch( callback ) {
					watchers[route] = watchers[route] || []
					if ( watchers[route].indexOf( callback ) < 0 ) {
						watchers[route].push( callback )
					}
					console.log( "watchers['" + route + "'].length is now " + watchers[route].length )
				},
				unwatch( callback ) {
					watchers[route] = watchers[route] || []
					while ( watchers[route].indexOf( callback ) >= 0 ) {
						watchers[route].splice( watchers[route].indexOf( callback ), 1 )
					}
					console.log( "watchers['" + route + "'].length is now " + watchers[route].length )
				}
			}
			if ( isPublic ) {
				recurseSet( api.public, route.split("/"), endpoint )
			} else {
				recurseSet( api, route.split("/"), endpoint )
			}
			if ( hasId && route[route.length - 1] == "s" ) {
				let endpoint = {
					get( id, queries ) {
						return axios.get( `${apiRoot}${isPublic ? '/public' : ''}/${route}/${id}`, { headers: api.headers, params: queries } );
					},
					patch( id, data, queries ) {
						return new Promise( ( resolve, fail ) => {
							axios.patch( `${apiRoot}${isPublic ? '/public' : ''}/${route}/${id}`, data, { headers: api.headers, params: queries, timeout: 10000 } ).then( response => {
								handleTokenUpdate( apiClient, response )
								resolve( response )
								dispatch({
									endpoint: route,
									method: "PATCH",
									data: [{
										id: id,
										...data
									}]
								})
							}).catch( error => {
								apiErrorHandler( error )
								fail( error );
							})
						});
					},
					delete( id ) {
						return new Promise( ( resolve, fail ) => {
							axios.delete( `${apiRoot}${isPublic ? '/public' : ''}/${route}/${id}`, { headers: api.headers, timeout: 10000 } ).then( response => {
								handleTokenUpdate( apiClient, response )
								resolve( response )
								dispatch({
									endpoint: route,
									method: "DELETE",
									data: [{
										id: id
									}]
								})
							}).catch( error => {
								apiErrorHandler( error )
								fail( error )
							})
						})
					}
				}
				if ( isPublic ) {
					recurseSet( api.public, route.substring( 0, route.length - 1 ).split("/"), endpoint )
				} else {
					recurseSet( api, route.substring( 0, route.length - 1 ).split("/"), endpoint )
				}
			}
		},
		addSwitch( route, hasId, isPublic ) {
			const parent = ( isPublic ? api.public : api )
			let endpoint = {
				async get( queries ) {
					console.log( "GET Routing", route, queries )
					if ( !api.headers["X-Domain"] || !parent[api.headers["X-Domain"]][route] ) {
						throw { data: { error: { code: 400, message: "" } } }
					}
					return parent[api.headers["X-Domain"]][route].get(queries)
				},
				async count( queries ) {
					console.log( "COUNT Routing", route )
					return parent[api.headers["X-Domain"]][route].count(queries)
				},
				async post( data ) {
					console.log( "POST Routing", route )
					return parent[api.headers["X-Domain"]][route].post(data)
				},
				async patch( data, queries ) {
					console.log( "PATCH Routing", route )
					return parent[api.headers["X-Domain"]][route].patch(data, queries)
				},
				async delete( data ) {
					console.log( "DELETE Routing", route )
					return parent[api.headers["X-Domain"]][route].delete(data)
				},
				watch( callback ) {
					if ( parent.esthetics[route] ) {
						parent.esthetics[route].watch(callback)
					}
					if ( parent.coachs[route] ) {
						parent.coachs[route].watch(callback)
					}
				},
				unwatch( callback ) {
					if ( parent.esthetics[route] ) {
						parent.esthetics[route].unwatch(callback)
					}
					if ( parent.coachs[route] ) {
						parent.coachs[route].unwatch(callback)
					}
				}
			}
			if ( isPublic ) {
				recurseSet( api.public, route.split("/"), endpoint )
			} else {
				recurseSet( api, route.split("/"), endpoint )
			}
			if ( hasId && route[route.length - 1] == "s" ) {
				const sroute = route.substring( 0, route.length - 1 )
				let endpoint = {
					async get( id, queries ) {
						console.log( "GET Routing", route, id, queries )
						if ( !api.headers["X-Domain"] || !parent[api.headers["X-Domain"]][sroute] ) {
							throw { data: { error: { code: 400, message: "" } } }
						}
						return parent[api.headers["X-Domain"]][sroute].get(id, queries)
					},
					async patch( id, data, queries ) {
						return parent[api.headers["X-Domain"]][sroute].patch(id, data, queries)
					},
					async delete( id ) {
						return parent[api.headers["X-Domain"]][sroute].delete(id)
					}
				}
				if ( isPublic ) {
					recurseSet( api.public, sroute.split("/"), endpoint )
				} else {
					recurseSet( api, sroute.split("/"), endpoint )
				}
			}
		}
	}
	api = apiClient

	api.get = function( route, queries ) {
		return new Promise( ( resolve, fail ) => {
			axios.get( `${apiRoot}${route}`, { headers: api.headers, params: queries } ).then( response => {
				handleTokenUpdate( apiClient, response )
				resolve( response )
			}).catch( error => {
				apiErrorHandler( error )
				fail( error )
			})
		})
	}
	api.post = function( route, data ) {
		return new Promise( ( resolve, fail ) => {
			axios.post( `${apiRoot}${route}`, data, { headers: api.headers, timeout: 10000 } ).then( response => {
				handleTokenUpdate( apiClient, response )
				resolve( response )
			}).catch( error => {
				apiErrorHandler( error )
				fail( error )
			})
		})
	}
	api.watch = function( route, callback ) {
		watchers[route] = watchers[route] || []
		if ( watchers[route].indexOf( callback ) < 0 ) {
			watchers[route].push( callback )
		}
		console.log( "watchers['" + route + "'].length is now " + watchers[route].length )
	}
	api.unwatch = function( route, callback ) {
		watchers[route] = watchers[route] || []
		while ( watchers[route].indexOf( callback ) >= 0 ) {
			watchers[route].splice( watchers[route].indexOf( callback ), 1 )
		}
		console.log( "watchers['" + route + "'].length is now " + watchers[route].length )
	}

	api.addEndpoint( "bills", true )
	api.addEndpoint( "reviews", true )
	api.addEndpoint( "users", true )
	api.addEndpoint( "esthetics/absences", true )
	api.addEndpoint( "esthetics/appointments", true )
	api.addEndpoint( "esthetics/appointments", true, true )
	api.addEndpoint( "esthetics/formulas", true )
	api.addEndpoint( "esthetics/messages", true )
	api.addEndpoint( "esthetics/notifications", true )
	api.addEndpoint( "esthetics/products", true )
	api.addEndpoint( "esthetics/schedules", true )
	api.addEndpoint( "esthetics/salons", true )
	api.addEndpoint( "esthetics/salons", true, true )
	api.addEndpoint( "esthetics/salonsusers", true )
	api.addEndpoint( "esthetics/services", true )
	api.addEndpoint( "esthetics/stocks", true )
	api.addEndpoint( "esthetics/stocksfilters", true )
	api.addEndpoint( "esthetics/stockstypes", true )
	api.addEndpoint( "esthetics/stockscolors", true )
	api.addEndpoint( "esthetics/stockshistory", true )
	api.addEndpoint( "esthetics/workers", true )
	api.addEndpoint( "esthetics/groups", true )
	api.addEndpoint( "esthetics/campaigns", true )
	api.addEndpoint( "coachs/absences", true )
	api.addEndpoint( "coachs/appointments", true )
	api.addEndpoint( "coachs/appointments", true, true )
	api.addEndpoint( "coachs/formulas", true )
	api.addEndpoint( "coachs/messages", true )
	api.addEndpoint( "coachs/notifications", true )
	api.addEndpoint( "coachs/products", true )
	api.addEndpoint( "coachs/schedules", true )
	api.addEndpoint( "coachs/salons", true )
	api.addEndpoint( "coachs/salons", true, true )
	api.addEndpoint( "coachs/salonsusers", true )
	api.addEndpoint( "coachs/services", true )
	api.addEndpoint( "coachs/workers", true )
	api.addEndpoint( "coachs/groups", true )
	api.addEndpoint( "coachs/campaigns", true )
	api.addSwitch( "absences", true )
	api.addSwitch( "appointments", true )
	api.addSwitch( "appointments", true, true )
	api.addSwitch( "formulas", true )
	api.addSwitch( "messages", true )
	api.addSwitch( "notifications", true )
	api.addSwitch( "products", true )
	api.addSwitch( "schedules", true )
	api.addSwitch( "salons", true )
	api.addSwitch( "salons", true, true )
	api.addSwitch( "salonsusers", true )
	api.addSwitch( "services", true )
	api.addSwitch( "stocks", true )
	api.addSwitch( "stocksfilters", true )
	api.addSwitch( "stockstypes", true )
	api.addSwitch( "stockscolors", true )
	api.addSwitch( "stockshistory", true )
	api.addSwitch( "workers", true )
	api.addSwitch( "groups", true )
	api.addSwitch( "campaigns", true )

	return apiClient
}

