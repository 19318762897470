import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)


function deepCopy( obj ) {
	return JSON.parse( JSON.stringify( obj ) );
}

// TODO : move me
const variantsSettings = {
	esthetics: {
		hairdresser: {
		},
		barber: {
		},
		beauty: {
			hasStocks: false,
			acceptsChildren: false,
			prestationsHavePauses: false,
			prestationsHaveLength: false,
			prestationsHaveAlt: false,
			prestationsHaveDependencies: false
		},
		default: {
			uniqueAccount: false,
			fixedAccounts: false,
			hasStocks: true,
			acceptsChildren: true,
			prestationsHavePauses: true,
			prestationsHaveLength: true,
			prestationsHaveAlt: true,
			prestationsHaveDependencies: true,
			steps: true
		}
	},
	coachs: {
		sport: {
		},
		naturopath: {
		},
		life: {
		},
		default: {
			uniqueAccount: true,
			fixedAccounts: true,
			hasStocks: false,
			acceptsChildren: false,
			prestationsHavePauses: false,
			prestationsHaveLength: false,
			prestationsHaveAlt: false,
			prestationsHaveDependencies: false,
			steps: false
		}
	}
}

function getVariantSetting( domain, type, setting ) {
	const res = ( ( variantsSettings[domain] || {} )[type] || {} )[setting]
	if ( res === undefined || res === null ) {
		return ( variantsSettings[domain] || { default: {} } ).default[setting]
	}
	return res
}
/*
const queues = {
	schedules: []
}
*/

let sysLocale = ( navigator.language || navigator.userLanguage || "en" ).toLowerCase().substr( 0, 2 )

const store = new Vuex.Store({
	state: {
		initialized: false,
		config: {
			block_size: 15, // 10 minutes per block - TODO : make it changeable
		},
		domain: process.env.VUE_APP_DOMAIN || "esthetics",
		salon: {},
		worker: {},
		workers: [],
		groups: [],
		schedules: [],
		services: [],
		formulas: [],
		stocks: [],
		stockstypes: [],
		stockscolors: [],
	},
	mutations: {
		setInitialized( state, ok ) {
			state.initialized = ok
		},
		setCurrentDomain( state, domain ) {
			console.log( "setCurrentDomain", domain )
			state.domain = domain
			document.getElementsByTagName( "html" )[0].setAttribute( "data-domain", domain )
		},
		setCurrentSalon( state, salon ) {
			console.log( "setCurrentSalon", salon )
			state.salon = salon
			this.$app.$i18n.locale = ( state.salon || {} ).locale || sysLocale
			this.$app.$dayjs.locale( this.$app.$i18n.locale )
			if ( salon && salon.clientid ) {
				window.localStorage.setItem( "gaiaid", salon.clientid )
			}
		},
		updateSalon( state, salon ) {
			state.salon = Object.assign( state.salon, salon )
			this.$app.$i18n.locale = state.salon.locale || sysLocale
			this.$app.$dayjs.locale( this.$app.$i18n.locale )
		},
		setCurrentWorker( state, worker ) {
			console.log( "setCurrentWorker", state.domain, this.domain )
			state.domain = state.domain || this.domain || "esthetics"
			document.getElementsByTagName( "html" )[0].setAttribute( "data-domain", state.domain )
			console.log( "setCurrentWorker", worker, state.domain, this.domain )
			state.worker = worker
			if ( worker ) {
				this.$app.$i18n.locale = state.worker.locale || state.salon.locale || sysLocale
				document.getElementsByTagName( "html" )[0].setAttribute( "data-theme", worker.theme )
				this.$app.$cookies.set( "workerid", worker.id, "4h" )
			} else {
				this.$app.$i18n.locale = state.salon.locale || sysLocale
				document.getElementsByTagName( "html" )[0].setAttribute( "data-theme", undefined )
				this.$app.$cookies.remove( "workerid" )
				Vue.prototype.$api.setCredentials( null, null )
			}
			this.$app.$i18n.setLocaleMessage( this.$app.$i18n.locale, this.$app.$i18n.translations( state.domain )[this.$app.$i18n.locale] )
			this.$app.$dayjs.locale( this.$app.$i18n.locale )
		},
		updateWorker( state, worker ) {
			var dest = null
			for ( var i = 0; i < state.workers.length; i++ ) {
				if ( state.workers[i].id == worker.id ) {
					dest = state.workers[i]
					break
				}
			}
			if ( dest ) {
				Object.assign( dest, worker )
				dest.name = [ dest.fname ].filter( el => el != null && el.trim().length > 0 ).join( " " )
			} else {
				worker.name = [ worker.fname, worker.lname ].filter( el => el != null && el.trim().length > 0 ).join( " " )
				state.workers.push( worker )
			}
			if ( worker.id === store.state.worker.id ) {
				if ( dest !== store.state.worker ) {
					Object.assign( store.state.worker, worker )
				}
				if ( worker.locale ) {
					this.$app.$i18n.locale = worker.locale || sysLocale
					this.$app.$dayjs.locale( this.$app.$i18n.locale )
				}
				if ( worker.theme ) {
					document.getElementsByTagName( "body" )[0].setAttribute( "data-theme", worker.theme )
				}
			}
		},
		deleteWorker( state, worker ) {
			var dest = -1
			for ( var i = 0; i < state.workers.length; i++ ) {
				if ( state.workers[i].id == worker.id ) {
					dest = i
					break
				}
			}
			if ( dest >= 0 ) {
				state.workers.splice( dest, 1 )
			}
		},
		reloadWorkers( state, workers ) {
			state.workers = workers
			state.workers.forEach( worker => {
				worker.name = [ worker.fname, worker.lname ].filter( el => el != null && el.trim().length > 0 ).join( " " )
				if ( worker.schedule ) {
					worker.schedule.forEach( sched => {
						for ( let i = 0; i <= 4; i++ ) {
							if ( sched[i] !== null && parseInt(sched[i]) == sched[i] ) {
								sched[i] = parseInt( sched[i] )
							}
						}
					})
				}
			})
		},
		reloadGroups( state, groups ) {
			state.groups = groups
		},
		reloadSchedules( state, schedules ) {
			state.schedules = schedules
			/*
			for ( var i = 0; i < queues.schedules.length; i++ ) {
				queues.schedules[i]( deepCopy( schedules ) )
			}
			*/
		},
		reloadServices( state, services ) {
			state.services = services.sort( ( a, b ) => {
				let aType = a.type < 0 ? ( 100 - a.type ) : a.type
				let bType = b.type < 0 ? ( 100 - b.type ) : b.type
				return ( aType * 1000 + a.priority ) - ( bType * 1000 + b.priority )
			})
		},
		reloadFormulas( state, formulas ) {
			state.formulas = formulas.sort( ( a, b ) => a.id - b.id )
		},
		reloadStocks( state, stocks ) {
			state.stocks = stocks
		},
		reloadStocksTypes( state, stockstypes ) {
			state.stockstypes = stockstypes
		},
		reloadStocksColors( state, stockscolors ) {
			state.stockscolors = stockscolors
		},
		updateGroup( state, group ) {
			var dest = null
			for ( var i = 0; i < state.groups.length; i++ ) {
				if ( state.groups[i].id == group.id ) {
					dest = state.groups[i]
					break
				}
			}
			if ( dest ) {
				Object.assign( dest, group )
			} else {
				state.groups.push( group )
			}
		},
		deleteGroup( state, group ) {
			var dest = -1
			for ( var i = 0; i < state.groups.length; i++ ) {
				if ( state.groups[i].id == group.id ) {
					dest = i
					break
				}
			}
			if ( dest >= 0 ) {
				state.groups.splice( dest, 1 )
			}
		},
		updateStock( state, stock ) {
			var dest = null
			for ( var i = 0; i < state.stocks.length; i++ ) {
				if ( state.stocks[i].id == stock.id ) {
					dest = state.stocks[i]
					break
				}
			}
			if ( dest ) {
				Object.assign( dest, stock )
			} else {
				state.stocks.push( stock )
			}
		},
		deleteStock( state, stock ) {
			var dest = -1
			for ( var i = 0; i < state.stocks.length; i++ ) {
				if ( state.stocks[i].id == stock.id ) {
					dest = i
					break
				}
			}
			if ( dest >= 0 ) {
				state.stocks.splice( dest, 1 )
			}
		},
		updateStockType( state, stocktype ) {
			var dest = null
			for ( var i = 0; i < state.stockstypes.length; i++ ) {
				if ( state.stockstypes[i].id == stocktype.id ) {
					dest = state.stockstypes[i]
					break
				}
			}
			if ( dest ) {
				Object.assign( dest, stocktype )
			} else {
				state.stockstypes.push( stocktype )
			}
		},
		deleteStockType( state, stocktype ) {
			var dest = -1
			for ( var i = 0; i < state.stockstypes.length; i++ ) {
				if ( state.stockstypes[i].id == stocktype.id ) {
					dest = i
					break
				}
			}
			if ( dest >= 0 ) {
				state.stockstypes.splice( dest, 1 )
			}
		},
		updateStockColor( state, stockcolor ) {
			var dest = null
			for ( var i = 0; i < state.stockscolors.length; i++ ) {
				if ( state.stockscolors[i].id == stockcolor.id ) {
					dest = state.stockscolors[i]
					break
				}
			}
			if ( dest ) {
				Object.assign( dest, stockcolor )
			} else {
				state.stockscolors.push( stockcolor )
			}
		},
		deleteStockColor( state, stockcolor ) {
			var dest = -1
			for ( var i = 0; i < state.stockscolors.length; i++ ) {
				if ( state.stockscolors[i].id == stockcolor.id ) {
					dest = i
					break
				}
			}
			if ( dest >= 0 ) {
				state.stockscolors.splice( dest, 1 )
			}
		},
		updateFormula( state, formula ) {
			var dest = null
			for ( var i = 0; i < state.formulas.length; i++ ) {
				if ( state.formulas[i].id == formula.id ) {
					dest = state.formulas[i]
					break
				}
			}
			if ( dest ) {
				Object.assign( dest, formula )
			} else {
				state.formulas.push( formula )
			}
		},
		deleteService( state, service ) {
			var dest = -1
			for ( var i = 0; i < state.services.length; i++ ) {
				if ( state.services[i].id == service.id ) {
					dest = i
					break
				}
			}
			if ( dest >= 0 ) {
				state.services.splice( dest, 1 )
			}
		},
		updateService( state, service ) {
			var dest = null
			for ( var i = 0; i < state.services.length; i++ ) {
				if ( state.services[i].id == service.id ) {
					dest = state.services[i]
					break
				}
			}
			if ( dest ) {
				Object.assign( dest, service )
			} else {
				state.services.push( service )
			}
			state.services = state.services.sort( ( a, b ) => {
				let aType = a.type < 0 ? ( 100 - a.type ) : a.type
				let bType = b.type < 0 ? ( 100 - b.type ) : b.type
				return ( aType * 1000 + a.priority ) - ( bType * 1000 + b.priority )
			})
		},
		deleteFormula( state, formula ) {
			var dest = -1
			for ( var i = 0; i < state.formulas.length; i++ ) {
				if ( state.formulas[i].id == formula.id ) {
					dest = i
					break
				}
			}
			if ( dest >= 0 ) {
				state.formulas.splice( dest, 1 )
			}
		}
	},
	actions: {
		disconnect( context ) {
			try {
				context.commit( "setCurrentWorker", null )
			//	window.localStorage.removeItem( "salonid" )
				window.localStorage.removeItem( "workerid" )
				window.localStorage.removeItem( "token" )
				Vue.prototype.$api.socketClose()
			//	context.$app.$forceReload()
			} catch ( error ) {
				console.log( "disconnect error", error )
			}
			if ( window.location.hash == "#/login" ) {
				console.log( "reload" )
				window.location.reload()
			} else {
				console.log( "redirect & reload" )
				window.location = "/#/login"
				window.location.reload()
			}
		},
		setCurrentSalon( context, id ) {
			return new Promise( (resolve) => {
				Vue.prototype.$api.salon.get( id ).then(response => {
					context.commit( "setCurrentSalon", response.data[0] )
					resolve()
				}).catch(error => console.log(error))
			})
		},
		setCurrentWorker( context, id ) {
			console.log( "action setCurrentWorker", id )
			if ( id ) {
				console.log( context.state.workers )
				for ( var i = 0; i < context.state.workers.length; i++ ) {
					console.log( "compare", context.state.workers[i].id, id )
					if ( context.state.workers[i].id == id ) {
						console.log( "action setCurrentWorker found worker" )
						context.commit( "setCurrentWorker", context.state.workers[i] )
						break
					}
				}
			} else {
				context.commit( "setCurrentWorker", null )
			}
		},
		reload( context, [domain, salonid, workerid] = [] ) {
			console.log( "store reload", domain, salonid, workerid )
			domain = domain || window.localStorage.getItem( "domain" )
			return new Promise( async ( resolve ) => {
				await context.commit( "setCurrentDomain", domain )
				await context.dispatch( "reloadWorkers" )
				await context.dispatch( "reloadGroups" )
				await context.dispatch( "reloadServices" )
				await context.dispatch( "reloadFormulas" )
				await context.dispatch( "reloadSchedules" )
				if ( domain == "esthetics" ) {
					await context.dispatch( "reloadStocks" )
					await context.dispatch( "reloadStocksTypes" )
					await context.dispatch( "reloadStocksColors" )
				}
				await context.dispatch( "setCurrentSalon", salonid || window.localStorage.getItem( "salonid" ) )
				await context.dispatch( "setCurrentWorker", workerid || window.localStorage.getItem( "workerid" ) )
				resolve()
			})
		},
		reloadWorkers( context, resolve ) {
			console.log( "reloadWorkers" )
			return new Promise( (resolve) => {
				Vue.prototype.$api.workers.get().then(response => {
					console.log( "reloaded workers" )
					context.commit( "reloadWorkers", response.data )
					resolve()
				}).catch(error => console.log(error))
			})
		},
		reloadGroups( context, resolve ) {
			console.log( "reloadGroups" )
			return new Promise( (resolve) => {
				Vue.prototype.$api.groups.get().then(response => {
					console.log( "reloaded groups" )
					context.commit( "reloadGroups", response.data )
					resolve()
				}).catch(error => console.log(error))
			})
		},
		reloadSchedules( context ) {
			return new Promise( (resolve) => {
				Vue.prototype.$api.schedules.get().then(response => {
					console.log( "reloaded schedules" )
					context.commit( "reloadSchedules", response.data.sort( ( a, b ) => a.id < b.id ) )
					resolve()
				}).catch(error => console.log(error))
			})
		},
		reloadServices( context ) {
			return new Promise( (resolve) => {
				Vue.prototype.$api.services.get().then(response => {
					context.commit( "reloadServices", response.data )
					resolve()
				}).catch(error => console.log(error))
			})
		},
		reloadFormulas( context ) {
			return new Promise( (resolve) => {
				Vue.prototype.$api.formulas.get().then(response => {
					context.commit( "reloadFormulas", response.data )
					resolve()
				}).catch(error => console.log(error))
			})
		},
		reloadStocks( context ) {
			return new Promise( (resolve) => {
				Vue.prototype.$api.stocks.get().then(response => {
					context.commit( "reloadStocks", response.data )
					resolve()
				}).catch(error => console.log(error))
			})
		},
		reloadStocksTypes( context ) {
			return new Promise( (resolve) => {
				Vue.prototype.$api.stockstypes.get().then(response => {
					context.commit( "reloadStocksTypes", response.data )
					resolve()
				}).catch(error => console.log(error))
			})
		},
		reloadStocksColors( context ) {
			return new Promise( (resolve) => {
				Vue.prototype.$api.stockscolors.get().then(response => {
					context.commit( "reloadStocksColors", response.data )
					resolve()
				}).catch(error => console.log(error))
			})
		}
	},
	getters: {
		getUser: (state) => async (id) => {
			if ( !id || id <= 0 ) {
				return null
			}
			/*
			const coalesce = [ "fname", "lname", "phone", "email", "gender", "birthdate" ]
			const fields = [ "salonsusers.id", "salonsusers.userid", "prevent_online", "salonsusers.registration", "salonsusers.unregistration", "loyalty" ]
			return Vue.prototype.$api.salonsusers.get( { "{join:left}": { users: { "{users.id}": "salonsusers.userid" } }, "{coalesce}": coalesce, "{fields}": fields, "salonsusers.id": id } )
			*/
			const ret = Vue.prototype.$api.salonsusers.get( { id } )
			ret.then( r => {
				console.debug( "getUser", id, r )
			})
			return ret
		},
		getOnlineUser: (state) => async (id) => {
			if ( !id || id <= 0 ) {
				return null
			}
			/*
			const coalesce = [ "fname", "lname", "phone", "email", "gender", "birthdate" ]
			const fields = [ "salonsusers.id", "salonsusers.userid", "prevent_online", "salonsusers.registration", "salonsusers.unregistration", "loyalty" ]
			return Vue.prototype.$api.salonsusers.get( { "{join:left}": { users: { "{users.id}": "salonsusers.userid" } }, "{coalesce}": coalesce, "{fields}": fields, "salonsusers.userid": id } )
			*/
			const ret = Vue.prototype.$api.salonsusers.get( { userid: id } )
			ret.then( r => {
				console.debug( "getOnlineUser", id, r )
			})
			return ret
		},
		getWorkers: (state) => () => {
			return deepCopy( state.workers )
		},
		getGroups: (state) => () => {
			return deepCopy( state.groups )
		},
		getWorker: (state) => (id, ref) => {
			for ( var i = 0; i < state.workers.length; i++ ) {
				if ( state.workers[i].id == id ) {
					return state.workers[i]
				}
			}
			let i18n = Vue.prototype.$_i18n
			return {
				name: i18n._t.apply(i18n, [ "common.accdel", i18n.locale, i18n._getMessages(), this ].concat( [] )),
				deleted: true,
			}
		},
		getGeneralWorker: (state) => () => {
			for ( var i = 0; i < state.workers.length; i++ ) {
				if ( state.workers[i].username == "general" ) {
					return state.workers[i]
				}
			}
			for ( var i = 0; i < state.workers.length; i++ ) {
				if ( state.workers[i].manager ) {
					return state.workers[i]
				}
			}
			return {}
		},
		getSchedules: (state) => () => {
			return deepCopy( state.schedules )
		},
		getServices: (state) => () => {
			return deepCopy( state.services )
		},
		getFormulas: (state) => () => {
			return deepCopy( state.formulas )
		},
		getService: (state) => (id) => {
			for ( var i = 0; i < state.services.length; i++ ) {
				if ( state.services[i].id == id ) {
					return state.services[i]
				}
			}
			return {}
		},
		getStocks: (state) => () => {
			return deepCopy( state.stocks )
		},
		getStock: (state) => (id) => {
			for ( var i = 0; i < state.stocks.length; i++ ) {
				if ( state.stocks[i].id == id ) {
					return state.stocks[i]
				}
			}
			return {}
		},
		salonHasUniqueAccount: (state) => () => {
			return getVariantSetting( state.domain, state.salon.type, "uniqueAccount" )
		},
		salonHasFixedAccounts: (state) => () => {
			return getVariantSetting( state.domain, state.salon.type, "fixedAccounts" )
		},
		salonHasSteps: (state) => () => {
			return getVariantSetting( state.domain, state.salon.type, "steps" )
		},
		salonAcceptsChildren: (state) => () => {
			return getVariantSetting( state.domain, state.salon.type, "acceptsChildren" )
		},
		salonPrestationsHavePauses: (state) => () => {
			return getVariantSetting( state.domain, state.salon.type, "prestationsHavePauses" )
		},
		salonPrestationsHasLength: (state) => () => {
			return getVariantSetting( state.domain, state.salon.type, "prestationsHaveLength" )
		},
		salonPrestationsHasAlt: (state) => () => {
			return getVariantSetting( state.domain, state.salon.type, "prestationsHaveAlt" )
		},
		salonHasStocks: (state) => () => {
			return getVariantSetting( state.domain, state.salon.type, "hasStocks" ) && ['multi', 'premium'].includes(state.salon.subscription)
		},
		salonPrestationsHasDependencies: (state) => () => {
			return getVariantSetting( state.domain, state.salon.type, "prestationsHaveDependencies" )
		},
		salonPrestationsHasReviews: (state) => () => {
			return ['multi', 'premium'].includes(state.salon.subscription)
		},
		salonTypes: (state) => () => {
			let ext = []
			if ( state.salon.exttypes ) {
				ext = state.salon.exttypes.match( /^{(.*)}$/i )[1].split(",")
			}
			return [ state.salon.type, ...ext ]
		}
	}
})


export default function( api ) {

	api.salons.watch( event => {
		if ( event.method === "PATCH" ) {
			store.commit( "updateSalon", event.data[0] )
		}
	})

	api.schedules.watch( event => {
		if ( event.method === "PATCH" ) {
			store.commit( "reloadSchedules", event.data.sort( ( a, b ) => a.id < b.id ) )
		}
	})

	api.workers.watch( event => {
		if ( event.method === "POST" || event.method === "PATCH" ) {
			for ( var i = 0; i < event.data.length; i++ ) {
				store.commit( "updateWorker", event.data[i] )
			}
		} else if ( event.method === "DELETE" ) {
			for ( var i = 0; i < event.data.length; i++ ) {
				store.commit( "deleteWorker", event.data[i] )
			}
		}
	})

	api.groups.watch( event => {
		if ( event.method === "POST" || event.method === "PATCH" ) {
			for ( var i = 0; i < event.data.length; i++ ) {
				store.commit( "updateGroup", event.data[i] )
			}
		} else if ( event.method === "DELETE" ) {
			for ( var i = 0; i < event.data.length; i++ ) {
				store.commit( "deleteGroup", event.data[i] )
			}
		}
	})

	api.stocks.watch( event => {
		if ( event.method === "POST" || event.method === "PATCH" ) {
			for ( var i = 0; i < event.data.length; i++ ) {
				store.commit( "updateStock", event.data[i] )
			}
		} else if ( event.method === "DELETE" ) {
			for ( var i = 0; i < event.data.length; i++ ) {
				store.commit( "deleteStock", event.data[i] )
			}
		}
	})

	api.stockstypes.watch( event => {
		if ( event.method === "POST" || event.method === "PATCH" ) {
			for ( var i = 0; i < event.data.length; i++ ) {
				store.commit( "updateStockType", event.data[i] )
			}
		} else if ( event.method === "DELETE" ) {
			for ( var i = 0; i < event.data.length; i++ ) {
				store.commit( "deleteStockType", event.data[i] )
			}
		}
	})

	api.stockscolors.watch( event => {
		if ( event.method === "POST" || event.method === "PATCH" ) {
			for ( var i = 0; i < event.data.length; i++ ) {
				store.commit( "updateStockColor", event.data[i] )
			}
		} else if ( event.method === "DELETE" ) {
			for ( var i = 0; i < event.data.length; i++ ) {
				store.commit( "deleteStockColor", event.data[i] )
			}
		}
	})

	api.services.watch( event => {
		if ( event.method === "POST" || event.method === "PATCH" ) {
			for ( var i = 0; i < event.data.length; i++ ) {
				store.commit( "updateService", event.data[i] )
			}
		} else if ( event.method === "DELETE" ) {
			for ( var i = 0; i < event.data.length; i++ ) {
				store.commit( "deleteService", event.data[i] )
			}
		}
		setTimeout( () => { console.log( store.state.services ) }, 1000 )
	})

	api.formulas.watch( event => {
		if ( event.method === "POST" || event.method === "PATCH" ) {
			for ( var i = 0; i < event.data.length; i++ ) {
				store.commit( "updateFormula", event.data[i] )
			}
		} else if ( event.method === "DELETE" ) {
			for ( var i = 0; i < event.data.length; i++ ) {
				store.commit( "deleteFormula", event.data[i] )
			}
		}
	})

	return {
		store,
		install( Vue, options ) {
			Vue.prototype.$store = store
		}
	}
}
