<template>
	<div>
		<div :class="'modal-mask' + ( above ? ' above' : '' )"/>
		<div ref="modal" :class="'modal visible' + ( type !== '' ? ( ' modal-' + type ) : '' ) + ( above ? ' above' : '' )" :id="name">
			<div class="modal-page">
				<div class="modal-header">
					<slot name="header"></slot>
				</div>
				<div class="modal-content">
					<slot name="content"></slot>
				</div>
				<div class="modal-buttons">
					<slot name="buttons"></slot>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: [
			"name",
			"type",
			"above"
		],
		data() {
			return {
				service: {}
			}
		},
		mounted() {
			console.log('mounted')
			this.$preventScrolling()
		},
		beforeDestroy() {
			console.log('unmounted')
			this.$allowScrolling()
		}
	}
</script>

