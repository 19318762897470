<template>
	<Popper
		class="dropdown d-block workers-dropdown"
		trigger="clickToToggle"
		:options="{ placement: 'bottom-end', modifiers: [{ name: 'preventOverflow', options: { mainAxis: false } }] }"
		:visible-arrow="false"
		ref="workerPopper"
		v-show="!$store.getters.salonHasUniqueAccount()"
	>
		<div :class="'dropdown-menu ' + ( placement || '' )" @click="$refs.workerPopper.doClose()">
			<div class="dropdown-choices">
				<button v-if="!$store.getters.salonHasUniqueAccount() && (general === undefined || general === true)" class="dropdown-item" :class="{ 'worker-selected': workerid == -1 }" @click="$refs.mainSelector.textContent=genText; workerid=-1; groupid=-1; $emit( 'change', -1 )" type="button">{{genText}}</button>
				<button v-if="!$store.getters.salonHasUniqueAccount() && showSalon" class="dropdown-item" :class="{ 'worker-selected': workerid == salonid }" @click="$refs.mainSelector.textContent=$t('common.salon'); workerid=salonid; groupid=-1; $emit( 'change', salonid )" type="button">{{$t("common.salon")}}</button>
				<div v-if="general === undefined || general === true || showSalon" class="dropdown-separator"/>
				<template v-for="group in $store.state.groups" v-if="$store.state.workers.some( w => w.groupid == group.id )">
					<component :is="groupsSelectable ? 'button' : 'div'" class="dropdown-item" :class="{ disabled: !groupsSelectable }" @click="groupChange(group)">{{ group.name }}</component>
					<button v-for="worker in $store.state.workers.filter( w => w.groupid == group.id )" v-if="!worker.hidden" class="dropdown-item dropdown-item-sub" :class="{ 'worker-selected': workerid == worker.id }" @click="$refs.mainSelector.textContent=worker.fname + ' ' + (worker.lname || '').substr(0, 1).toUpperCase(); workerid=worker.id; $emit( 'change', worker.id )">{{ worker.fname + ' ' + (worker.lname || '').substr(0, 1).toUpperCase() }}</button>
					<div class="dropdown-separator"/>
				</template>
				<button v-for="worker in $store.state.workers.filter( w => w.groupid <= 0 )" v-if="!worker.hidden" class="dropdown-item" :class="{ 'worker-selected': workerid == worker.id }" @click="$refs.mainSelector.textContent=worker.fname + ' ' + (worker.lname || '').substr(0, 1).toUpperCase(); workerid=worker.id; groupid=-1; $emit( 'change', worker.id )">{{ worker.fname + ' ' + (worker.lname || '').substr(0, 1).toUpperCase() }}</button>
			</div>
		</div>
		<button
			slot="reference"
			ref="mainSelector"
			aria-expanded="false"
			aria-haspopup="true"
			class="btn btn-secondary dropdown-toggle"
			data-flip="false"
			data-toggle="dropdown"
			:style="{ background: color, 'border-color': color, color: ( Color(color).negate().grayscale().toString() ) }"
			@mouseover="$refs.mainSelector.style['box-shadow'] = ( color ? `0 0 10px ${color}` : '' )"
			@mouseleave="$refs.mainSelector.style['box-shadow'] = ''"
		>{{ initial ? ( initial.fname + " " + ( initial.lname || "" ).substr( 0, 1 ).toUpperCase() ) : genText }}</button>
	</Popper>
</template>



<script>
	import Color from "color"
	import Popper from "vue-popperjs"

	export default {
		props: [
			"initialid",
			"colors",
			"general",
			"general-text",
			"show-salon",
			"placement",
			"groups-selectable"
		],
		components: {
			Popper
		},
		data() {
			return {
				Color: Color,
				workerid: -1,
				groupid: -1,
				initial: false
			}
		},
		computed: {
			salonid() {
				return this.$store.state.workers.find( w => w.username == "general" ).id
			},
			genText() {
				if ( this.general === undefined || this.general === true ) {
					return this.generalText || this.$t( "common.general" )
				}
				return ""
			},
			color() {
				if ( this.workerid <= 0 ) {
					return undefined
				}
				return this.$store.getters.getWorker( this.workerid ).color
			}
		},
		methods: {
			groupChange( group ) {
				if ( this.groupsSelectable ) {
					this.$refs.mainSelector.textContent = group.name
					this.groupid = group.id
					this.workerid = -1
					this.$emit( 'group-change', group.id )
				}
			},
			setWorker( id ) {
				this.workerid = id
				const worker = this.$store.state.workers.find( w => w.id == id )
				if ( worker ) {
					this.$refs.mainSelector.textContent = worker.fname + ' ' + (worker.lname || '').substr(0, 1).toUpperCase()
				} else {
					this.$refs.mainSelector.textContent = this.initial ? ( this.initial.fname + " " + ( this.initial.lname || "" ).substr( 0, 1 ).toUpperCase() ) : this.genText
				}
			}
		},
		mounted() {
			if ( this.$store.getters.salonHasUniqueAccount() ) {
				this.workerid = this.$store.state.worker.id
				this.initial = this.$store.getters.getWorker( this.workerid )
			}
			if ( this.initialid && ( this.general || this.initialid != this.$store.getters.getGeneralWorker().id ) ) {
				this.workerid = this.initialid
				this.initial = this.$store.getters.getWorker( this.initialid )
			}
		//	( ( this.initialid && ( this.general || this.initialid != this.$store.getters.getGeneralWorker().id ) ) ? this.$store.getters.getWorker( this.initialid ) : false )
		}
	}
</script>

<style scoped lang="scss" src="../css/site/workers-dropdown.scss"/>
